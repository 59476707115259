import React, { useEffect, useState } from "react";
import { Alert, Form, Spinner } from "react-bootstrap";
import { useParams } from "react-router";
import { studyOnboardingService } from "../../../../../../../Services/studyServices/studySubjectsServices";

const AddUpdateSiteSubjectRows = ({
  subjectId,
  setSubjectId,
  subjectGender,
  setSubjectGender,
  handleSubmit2,
  handleSubmit1,
  studyUniqueId,
  studyDynamicColumnData,
  UpdateHistoryOnChange,
  updateHandleChange,
  addHandleChange,
  studyAddDynamicColumnData,
  loadSpan,
  statusCode,
  customMessage,
  siteUniqueId
}) => {
  const { studyId } = useParams();
  let filterAddFormUniqueData = studyAddDynamicColumnData.filter((item) => !item.formUniqueId);
  let checkAddDunamicValue = filterAddFormUniqueData.map((item) => item.valueData ? true : false);
  let checkAddFilledData = checkAddDunamicValue?.filter((item) => item === true);
  let dynamicAddConditionData = filterAddFormUniqueData?.length === checkAddFilledData?.length;

  let filterUpdateFormUniqueData = studyDynamicColumnData.filter((item) => !item.formUniqueId);
  let checkUpdateDunamicValue = filterUpdateFormUniqueData.map((item) => item.valueData ? true : false);
  let checkUpdateFilledData = checkUpdateDunamicValue?.filter((item) => item === true);
  let dynamicUpdateConditionData = filterUpdateFormUniqueData?.length === checkUpdateFilledData?.length;
  let [removeSubjectColumn, setRemoveSubjectColumn] = useState([])
  const getAllSubjectPreDefineColumnOption = async () => {
    let data = await studyOnboardingService.getAllSubjectPreDefineColumnOption(studyId)
    setRemoveSubjectColumn(data?.data?.map(item => item.option) || [])
  }
  const [singleSite, setSingleSite] = useState("");
  const getSingleStudySite = async () => {
    let data = await studyOnboardingService.getSingleStudySite(studyId, siteUniqueId)
    setSingleSite(data?.data || [])
  }

  useEffect(() => {
    getSingleStudySite(siteUniqueId);
  }, [siteUniqueId]);

  useEffect(() => {
    getAllSubjectPreDefineColumnOption();
  }, [studyId]);
  return (
    <div className="p-2">
      {singleSite?.subjectSettings === "manual" &&
        <Form.Group className="mb-2">
          <div className="hstack justify-content-between">
            <Form.Label className="fw-bold mb-1">
              Subject Id <span className="text-danger">*</span>
            </Form.Label>
          </div>
          <Form.Control
            type="text"
            className="inputboxborder"
            placeholder="Enter Column Label"
            value={subjectId}
            onChange={(e) => setSubjectId(e.target.value)}
          />
        </Form.Group>
      }
      {removeSubjectColumn?.length > 0 &&
        <Form.Group className="mb-2">
          <Form.Label className="fw-bold mb-1">
            Subject Gender <span className="text-danger">*</span>
          </Form.Label>
          <Form.Select
            onChange={(e) => setSubjectGender(e.target.value)}
          >
            <option value="">Select Subject Gender</option>
            {removeSubjectColumn?.map((item, index) => (
              <option key={index} selected={subjectGender === item?.toLowerCase()} value={item?.toLowerCase()}>{item}</option>
            ))}
          </Form.Select>
        </Form.Group>
      }
      {studyUniqueId
        ? studyDynamicColumnData?.length > 0 &&
        studyDynamicColumnData?.map(
          (
            { name, types, valueData, history, formUniqueId, fieldUniqueId },
            i
          ) =>
            formUniqueId && fieldUniqueId ? (
              ""
            ) : (
              types === "dropdown" ? "" :
                <>
                  <div key={i}>
                    <Form.Group className="mb-2">
                      <Form.Label className="fw-bold mb-1">
                        {name} <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        key={i}
                        type={types}
                        className="inputboxborder"
                        name="valueData"
                        value={valueData ? valueData : ""}
                        onChange={(e) => updateHandleChange(e, i)}
                        placeholder={`Enter ${name}`}
                        required
                      />
                    </Form.Group>
                  </div>
                  <div key={i}>
                    <Form.Group className="mb-2">
                      <Form.Label className="fw-bold mb-1">Comment</Form.Label>
                      <input
                        key={i}
                        type={"text"}
                        className="form-control inputboxborder mb-3 w-100"
                        name="history"
                        value={history}
                        onChange={(e) => UpdateHistoryOnChange(e, i)}
                        placeholder={"Enter Comment"}
                        required
                      />
                    </Form.Group>
                  </div>
                </>
            )
        )
        : studyAddDynamicColumnData?.length > 0 &&
        studyAddDynamicColumnData?.map(
          ({ name, types, valueData, formUniqueId, fieldUniqueId }, i) =>
            formUniqueId && fieldUniqueId ? (
              ""
            ) : (
              types === "dropdown" ? "" :
                <div key={i}>
                  <Form.Group className="mb-2">
                    <Form.Label className="fw-bold mb-1">
                      {name} <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      key={i}
                      type={types}
                      className="inputboxborder"
                      name="valueData"
                      value={valueData ? valueData : ""}
                      onChange={(e) => addHandleChange(e, i)}
                      placeholder={`Enter ${name}`}
                      required
                    />
                  </Form.Group>
                </div>
            )
        )}
      <div className="text-end mb-2">
        {studyUniqueId ? (
          <button
            className="submitbutton"
            disabled={loadSpan || !subjectId || !dynamicUpdateConditionData}
            title={
              !subjectId || !dynamicUpdateConditionData
                ? "Fill all mandatory fields"
                : ""
            }
            onClick={handleSubmit2}>
            {loadSpan ? (
              <Spinner
                className="me-1"
                style={{ width: 15, height: 15 }}
                animation="border"
              />
            ) : (
              <i className="fa-solid fa-pen-to-square me-1"></i>
            )}
            Update Site Subject
          </button>
        ) : (
          <button
            onClick={handleSubmit1}
            className="submitbutton"
            disabled={loadSpan || !subjectId || !subjectGender}
            title={
              !subjectId || !subjectGender || !dynamicAddConditionData
                ? "Fill all mandatory fields"
                : ""
            }
          >
            {loadSpan ? (
              <Spinner
                className="me-1"
                style={{ width: 15, height: 15 }}
                animation="border"
              />
            ) : (
              <i className="fa-solid fa-square-plus me-1"></i>
            )}
            Add Site Subject
          </button>
        )}
      </div>
      {customMessage && (
        <Alert variant={statusCode === 200 ? "success" : "danger"}>
          {customMessage}
        </Alert>
      )}
    </div>
  );
};

export default AddUpdateSiteSubjectRows;
