import React, { useState } from "react";
import StudySubjectAttributeTable from "./StudySubjectAttributeTable";
import UpdatedFieldHistory from "./UpdatedFieldHistory";
import SiteSubjectSpecialForm from "./SiteSubjectSpecialForm";
import SiteSubjectEvent from "./SiteSubjectEvent";
import SiteSubjectAuditLogs from "./SiteSubjectAuditLogs";
import StudySubjectQueryListComponent from "./QueryManagement/StudySubjectQueryListComponent";
import SiteSubjectReviewHistory from "./SiteSubjectReviewHistory";
import SubjectReport from "./Report/SubjectReport";
import SiteSubjectStatusHistory from "./SiteSubjectStatusHistory";
const SubjectTabProperties = ({
  subjectUniqueId,
  tableRef,
  onMouseDown,
  setIsSectionVisible,
  getStudySubjectRowData,
  handleAddNewTab,
  selectedStudySiteId,
  selectedStudySubjectId,
  studySubjectState,
  siteIdData,
  subjectStudyUniqueId,
}) => {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [activeTabs, setActiveTabs] = useState("properties");
  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  const StudyMode = JSON.parse(localStorage.getItem("StudyMode"));

  return (
    <>
      <div
        className={`pt-1 border-bottom bg-light ${isFullScreen ? "full-screen-component" : ""
          }`}
      >
        <div className="hstack justify-content-between">
          <div className="hstack me-1 gap-1">
            {[
              "properties",
              "history",
              "review History",
              "subject Special Form",
              "subject Events",
              "report",
              "query",
              "activities",
              "status Activities"
            ].map((tab) => (
              (StudyMode === "DBP MODE" || StudyMode === "DV Mode") && tab === "query" ? "" :
                (StudyMode === "DBP MODE" || StudyMode === "DV Mode") && tab === "review History" ? "" :
                  <button
                    key={tab}
                    className={`buttonForTabs ${activeTabs === tab ? "activee" : ""
                      }`}
                    onClick={() => setActiveTabs(tab)}
                    title={tab.charAt(0).toUpperCase() + tab.slice(1)}
                  >
                    {tab.charAt(0).toUpperCase() + tab.slice(1)}
                  </button>
            ))}
          </div>
          <div className="d-flex gap-1">
            <div className="toolbar">
              <i
                className="fa-solid fa-angle-down"
                onClick={() => setIsSectionVisible(false)}
              >
              </i>
            </div>
            <div className="toolbar">
              <i
                className={`fa-solid me-2 ${!isFullScreen ? "fa-maximize" : "fa-minimize"
                  }`}
                title={!isFullScreen ? "Full Mode" : "Original Mode"}
                onClick={toggleFullScreen}
              ></i>
            </div>
          </div>
        </div>
        {activeTabs === "properties" ? (
          <StudySubjectAttributeTable
            subjectUniqueId={subjectUniqueId}
            tableRef={tableRef}
            onMouseDown={onMouseDown}
          />
        ) : activeTabs === "history" ? (
          <UpdatedFieldHistory
            subjectUniqueId={subjectUniqueId}
            tableRef={tableRef}
            onMouseDown={onMouseDown}
          />
        ) : activeTabs === "review History" ? (
          <SiteSubjectReviewHistory
            tableRef={tableRef}
            onMouseDown={onMouseDown}
            studyUniqueId={subjectUniqueId}
          />
        ) : activeTabs === "subject Special Form" ? (
          <SiteSubjectSpecialForm
            subjectUniqueId={subjectUniqueId}
            handleAddNewTab={handleAddNewTab}
            selectedStudySiteId={selectedStudySiteId}
            selectedStudySubjectId={selectedStudySubjectId}
            studySubjectState={studySubjectState}
            siteIdData={siteIdData}
            subjectStudyUniqueId={subjectStudyUniqueId}
          />
        ) : activeTabs === "subject Events" ? (
          <SiteSubjectEvent
            subjectUniqueId={subjectUniqueId}
            handleAddNewTab={handleAddNewTab}
            tableRef={tableRef}
            onMouseDown={onMouseDown}
            selectedStudySiteId={selectedStudySiteId}
            selectedStudySubjectId={selectedStudySubjectId}
            studySubjectState={studySubjectState}
            siteIdData={siteIdData}
            subjectStudyUniqueId={subjectStudyUniqueId}
          />
        ) : activeTabs === "report" ? (
          <SubjectReport subjectUniqueId={subjectUniqueId}/>
        ) : activeTabs === "query" ? (
          <StudySubjectQueryListComponent
            tableRef={tableRef}
            onMouseDown={onMouseDown}
            subjectUniqueId={subjectUniqueId}
            isFullScreen={isFullScreen}
            getStudySubjectRowData={getStudySubjectRowData}
          />
        ) : activeTabs === "activities" ? (
          <SiteSubjectAuditLogs
            tableRef={tableRef}
            onMouseDown={onMouseDown}
            subjectUniqueId={subjectUniqueId}
          />
        ) : activeTabs === "status Activities" ? (
          <SiteSubjectStatusHistory
            tableRef={tableRef}
            onMouseDown={onMouseDown}
            subjectUniqueId={subjectUniqueId}
            StudyMode={StudyMode}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default SubjectTabProperties;
