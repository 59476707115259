import React, { useRef, useState } from "react";
import { Table } from "react-bootstrap";
import Swal from "sweetalert2";
import Pagination from "../../../../ETMF/Pagination/Pagination";
import { cdiscServices } from "../../../../../Services/CDISC/cdiscServices";

const SDTAVariableTableData = ({
  stdmDataSets,
  tableRef,
  onMouseDown,
  getCDISCDatasets,
  setIsOpenProperty,
  studyId,
  handleAddNewTab,
  setDomainId,
  setDomain,
}) => {
  const [highlightedRow, setHighlightedRow] = useState(null);

  const ref = useRef(null);
  const deleteStudyDataByDomainId = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete Datasets!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let results = await cdiscServices.deleteStudyDataByDomainId(
          id,
          studyId
        );
        if (results?.statusCode === 200) {
          getCDISCDatasets();
        }
      }
    });
  };

  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(15);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = stdmDataSets?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(stdmDataSets?.length / itemsPerPage);
  return (
    <>
      <div className="overflow-auto">
        <Table
           id="resizable-table"
           striped
           hover
           className="m-0 custom-table"
           ref={tableRef}
        >
          <thead className="thead-sticky z-1" style={{ top: -1 }}>
            <tr>
              <th onMouseDown={(e) => onMouseDown(e, 0)} style={{ width: 40 }}>
                Sr.
              </th>
              <th onMouseDown={(e) => onMouseDown(e, 1)}>Dataset</th>
              <th onMouseDown={(e) => onMouseDown(e, 2)}>Description</th>
              <th onMouseDown={(e) => onMouseDown(e, 3)}>Class</th>
              <th onMouseDown={(e) => onMouseDown(e, 4)}>Structure</th>
              <th onMouseDown={(e) => onMouseDown(e, 5)}>Purpose</th>
              <th onMouseDown={(e) => onMouseDown(e, 6)}>Key Variables</th>
              <th onMouseDown={(e) => onMouseDown(e, 7)}>Repeating</th>
              <th onMouseDown={(e) => onMouseDown(e, 8)}>Reference Data</th>
              <th onMouseDown={(e) => onMouseDown(e, 9)}>Comment</th>
              <th
                onMouseDown={(e) => onMouseDown(e, 10)}
                style={{ width: 60, right: 50 }}
                className="position-sticky text-center"
              >
                Derivation
              </th>
              <th
                onMouseDown={(e) => onMouseDown(e, 11)}
                style={{ width: 60, right: -1 }}
                className="position-sticky text-center"
              >
                Delete
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody ref={ref}>
            {result?.map((item, index) => (
              <tr
                key={index}
                className={`position-relative cursor-pointer ${highlightedRow === index && "activeMenu"
                  }`}
                onClick={() => {
                  setHighlightedRow(index);
                  setIsOpenProperty(true);
                  setDomainId(item?._id);
                  setDomain(item?.dataset);
                }}
              >
                <td>{(pageNumber - 1) * itemsPerPage + index + 1}</td>
                <td>{item?.dataset}</td>
                <td>{item?.description}</td>
                <td>{item?.class}</td>
                <td>{item?.structure}</td>
                <td className="text-capitalize">{item?.purpose}</td>
                <td>{item?.keyVariables?.join(", ")}</td>
                <td className="text-capitalize">{item?.repeating}</td>
                <td className="text-capitalize">{item?.reference}</td>
                <td>{item?.comments}</td>
                <td
                  style={{ right: 50 }}
                  className="position-sticky text-center bg-light"
                >
                  {item?.trailDesign === "yes" ?
                    <button
                      className="text-white border-success bg-success border-0"
                      style={{ fontSize: 10, borderRadius: 3 }}
                      title="Entry"
                      onClick={() => {
                        handleAddNewTab(
                          `Entry in ${item?.dataset}`,
                          "fa-solid fa-circle-nodes",
                          `entryDataId_${item?._id}`,
                          {
                            id: item?._id,
                          }
                        );
                      }}
                    >
                      Entry
                    </button>
                    :
                    <button
                      className="text-white border-primary bg-primary border-0"
                      style={{ fontSize: 10, borderRadius: 3 }}
                      title="View Derivation"
                      onClick={() => {
                        handleAddNewTab(
                          `Derivation in ${item?.dataset}`,
                          "fa-solid fa-circle-nodes",
                          `derivationDataId_${item?._id}`,
                          {
                            id: item?._id,
                          }
                        );
                      }}
                    >
                      View
                    </button>
                  }
                </td>
                <td style={{ right: -1 }} className="position-sticky bg-light">
                  <button
                    className="text-white border-danger bg-danger border-0"
                    style={{ fontSize: 10, borderRadius: 3 }}
                    title="Delete Supplementary"
                    onClick={() => deleteStudyDataByDomainId(item?._id)}
                  >
                    Delete
                  </button>
                </td>
                <td></td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <div className="position-sticky bottom-0">
        <Pagination
          totalPage={totalPage}
          pageNumber={pageNumber}
          itemsPerPage={itemsPerPage}
          totalItems={stdmDataSets?.length}
          setPageNumber={setPageNumber}
          setItemsPerPage={setItemsPerPage}
        />
      </div>
    </>
  );
};

export default SDTAVariableTableData;
