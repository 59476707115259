import React, { useState } from "react";
import { Table } from "react-bootstrap";
import Swal from "sweetalert2";
import { cdiscServices } from "../../../../../Services/CDISC/cdiscServices";
import Pagination from "../../../../ETMF/Pagination/Pagination";

const STDMCommentTableData = ({
  stdmComment,
  tableRef,
  onMouseDown,
  getCDISCComments,
  setIsFormOpen,
  studyId,
}) => {
  const [highlightedRow, setHighlightedRow] = useState(null);
  const deleteStudyDataByCommentId = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete Comment!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let results = await cdiscServices.deleteStudyDataByCommentId(
          id,
          studyId
        );
        if (results?.statusCode === 200) {
          getCDISCComments();
        }
      }
    });
  };

  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(15);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = stdmComment?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(stdmComment?.length / itemsPerPage);
  return (
    <>
      <div className="overflow-auto">
        <Table
          ref={tableRef}
          id="resizable-table"
          className="text-nowrap mb-0 custom-table"
          hover
          striped
        >
          <thead className="thead-sticky z-1" style={{ top: -1 }}>
            <tr>
              <th
                onMouseDown={(e) => onMouseDown(e, 0)}
                style={{ width: 40 }}
                className="text-center"
              >
                Sr.
              </th>
              <th onMouseDown={(e) => onMouseDown(e, 1)}>ID</th>
              <th onMouseDown={(e) => onMouseDown(e, 2)}>Description</th>
              <th onMouseDown={(e) => onMouseDown(e, 3)}>Document</th>
              <th onMouseDown={(e) => onMouseDown(e, 4)}>Pages</th>
              <th
                onMouseDown={(e) => onMouseDown(e, 5)}
                style={{ width: 50, right: 50 }}
                className="text-center position-sticky"
              >
                <div className="text-center">Update</div>
              </th>
              <th
                onMouseDown={(e) => onMouseDown(e, 6)}
                style={{ width: 50, right: -1 }}
                className="text-center position-sticky"
              >
                <div className="text-center">Delete</div>
              </th>
            </tr>
          </thead>
          <tbody>
            {result.map((item, index) => (
              <tr
                key={index}
                className={`position-relative CP ${
                  highlightedRow === index && "activeMenu"
                }`}
                onClick={() => {
                  setHighlightedRow(index);
                }}
              >
                <td className="text-center">
                  {(pageNumber - 1) * itemsPerPage + index + 1}
                </td>
                <td>{item.commentId}</td>
                <td>{item.description}</td>
                <td>{item.document}</td>
                <td>{item.pages}</td>
                <td style={{ right: 50 }} className="position-sticky bg-light">
                  <button
                    onClick={() => setIsFormOpen(item)}
                    title="Update"
                    className="text-white border-success bg-success border-0"
                    style={{ fontSize: 10, borderRadius: 3 }}
                  >
                    Update
                  </button>
                </td>
                <td
                  style={{ right: -1 }}
                  className="position-sticky bg-light text-center"
                >
                  <button
                    className="text-white border-danger bg-danger border-0"
                    style={{ fontSize: 10, borderRadius: 3 }}
                    title="Delete Supplementary"
                    onClick={() => deleteStudyDataByCommentId(item?._id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <div className="position-sticky bottom-0">
        <Pagination
          totalPage={totalPage}
          pageNumber={pageNumber}
          itemsPerPage={itemsPerPage}
          totalItems={stdmComment?.length}
          setPageNumber={setPageNumber}
          setItemsPerPage={setItemsPerPage}
        />
      </div>
    </>
  );
};

export default STDMCommentTableData;
