import React, { useEffect, useRef, useState } from "react";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import CtmsSidebar from "./CtmsSidebar";
import { useParams } from "react-router";
import EDCMainPanel from "../EDC/EDCMainPanel";
import UseSidebarReducer from "../../Hooks/UseSidebarReducer";
import ResizerXManager from "../ResizerXManager/ResizerXManager";
import CtmsDashboardData from "./CtmsDashbaord/CtmsDashboardData";
import { ResizableX, ResizableY} from "../Layouts/Layouts";
import CTMSTreatmentManagement from "./TreatmentData/CTMSTreatmentmanagements";
import ELearningManagement from "../EDC/ELearningManagement/ELearningManagement";
import StudyIDStudySchedule from "../CommonComponents/DashboardBody/StudyIdData/EventManagement/StudyIDStudySchedule";
import StudyIDStudySite from "../CommonComponents/DashboardBody/StudyIdData/SiteManagement/StudySite/StudyIDStudySite";
import StudyIDStudyRandomized from "../CommonComponents/DashboardBody/StudyIdData/SiteManagement/SiteSubjectManagement/StudyIDStudyRandomized";
import ProtocolTrialSummeryMetadata from "../CommonComponents/DashboardBody/StudyMetadata/ProtocolTrialSummeryMetadata ";
import TrainingDataManagement from "../EDC/ELearningManagement/TrainingDataManagement/TrainingDataManagement";
import StudyConfiguration from "./StudyConfiguration";
import CtmsCollpaseSidebar from "./CtmsCollpaseSidebar";

const CTMSManagement = () => {
  const { showSidebar } = UseSidebarReducer();
  const [studyTabs, setStudyTabs] = useState([]);
  const { studyId } = useParams();
  const [resizingColumn, setResizingColumn] = useState(null);
  const startX = useRef(0);
  const startWidth = useRef(0);
  const tableRef = useRef(null);
  const [collapseSidebar, setCollpaseSidebar] = useState(false);

  useEffect(() => {
    const onMouseMove = (e) => {
      if (resizingColumn !== null && !isNaN(resizingColumn)) {
        const width = startWidth.current + (e.pageX - startX.current);
        const table = tableRef.current;
  
        // Ensure tableRef and resizingColumn are valid
        if (table) {
          const th = table.querySelector(`th:nth-child(${resizingColumn + 1})`);
          if (th) {
            th.style.width = `${width}px`;
            table.classList.add("resizing");
          }
        }
      }
    };
  
    const onMouseUp = () => {
      setResizingColumn(null);
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };
  
    if (resizingColumn !== null) {
      document.addEventListener("mousemove", onMouseMove);
      document.addEventListener("mouseup", onMouseUp);
    }
  
    return () => {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };
  }, [resizingColumn, startWidth, startX, tableRef]);
  
  const onMouseDown = (e, columnIndex) => {
    setResizingColumn(columnIndex);
    startX.current = e.pageX;
    startWidth.current = e.target.offsetWidth;
    document.body.style.userSelect = "none";
    tableRef.current?.querySelectorAll("th").forEach((column, index) => {
      if (index !== columnIndex) {
        column.style.width = `${column.offsetWidth}px`;
      }
    });
  };

  const handleAddNewTab = (studyTabName, icon, studyTabId, idObject) => {
    let newTab = {
      studyTabId: studyTabId,
      studyTabName: studyTabName,
      icon: icon,
      openDT: new Date(),
      status: "active",
      idObject: idObject,
    };
    const updatedTabs = studyTabs?.map((studyTab) => ({
      ...studyTab,
      status: "inactive",
    }));
    const existTab = studyTabs?.some(
      (studyTab) => studyTab.studyTabId === studyTabId
    );
    if (existTab) {
      updatedTabs.forEach((element) => {
        if (element.studyTabId === studyTabId) {
          element.status = "active";
          element.studyTabName = studyTabName;
        }
      });
      setStudyTabs(updatedTabs || []);
    } else {
      setStudyTabs([...updatedTabs, newTab] || []);
    }
  };

  const checkActiveTab = (studyTabId) => {
    return studyTabs?.some(
      (item) => item.studyTabId === studyTabId && item.status === "active"
    );
  };

  const checkHomeActiveTab = () => {
    return studyTabs?.some((item) => item.status === "active");
  };

  const checkDynamicActiveTab = (studyTabId) => {
    return studyTabs?.some(
      (item) => item.studyTabId.includes(studyTabId) && item.status === "active"
    );
  };

  const [objectId, setObjectId] = useState(null);

  useEffect(() => {
    let data = studyTabs?.find((item) => item.status === "active");
    setObjectId(data?.idObject);
  }, [studyTabs]);

  const activeStudyTab = studyTabs?.some((item) => item.status === "active");

  useEffect(() => {
    const savedStudyTabs = JSON.parse(localStorage.getItem("studyTabs"));
    if (savedStudyTabs) {
      setStudyTabs(savedStudyTabs || []);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("studyTabs", JSON.stringify(studyTabs));
  }, [studyTabs]);

  return (
    <div>
      <section>
        <Navbar Profiler1 handleAddNewTab={handleAddNewTab}/>
        <ResizableX>
          {!showSidebar && (
            <div id="div0" style={{ width: collapseSidebar ? 40 : 250 }}>
              <section className="middle-content">
                <div className="sidebar" id="myGroup">
                  <div className="menus">
                    <div className="sidebar-body">
                      {collapseSidebar ? (
                        <CtmsCollpaseSidebar
                          handleAddNewTab={handleAddNewTab}
                          checkActiveTab={checkActiveTab}
                          studyId={studyId}
                        />
                      ) : (
                        <CtmsSidebar
                          handleAddNewTab={handleAddNewTab}
                          checkActiveTab={checkActiveTab}
                          studyId={studyId}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </section>
            </div>
          )}
          <ResizerXManager collapseSidebar={collapseSidebar} />
          <ResizableY>
            <section className="middle-content" id="dashboard-section">
              <div className="inner-cont">
                <EDCMainPanel
                  setStudyTabs={setStudyTabs}
                  studyTabs={studyTabs}
                  activeStudyTab={activeStudyTab}
                  collapseSidebar={collapseSidebar}
                  setCollpaseSidebar={setCollpaseSidebar}
                />
                {!checkHomeActiveTab() && <CtmsDashboardData />}
                {checkActiveTab("siteList") && (
                  <StudyIDStudySite
                    viewTypeData="ctms"
                    tableRef={tableRef}
                    handleAddNewTab={handleAddNewTab}
                    onMouseDown={onMouseDown}
                  />
                )}

                {checkActiveTab("studyConfiguration") && (
                  <StudyConfiguration viewTypeData="ctms" />
                )}

                {checkActiveTab("studyEvent") && (
                  <StudyIDStudySchedule
                    viewTypeData="ctms"
                    tableRef={tableRef}
                    onMouseDown={onMouseDown}
                  />
                )}

                {checkActiveTab("subjectMetaData") && (
                  <StudyIDStudyRandomized
                    tableRef={tableRef}
                    viewTypeData="ctms"
                    onMouseDown={onMouseDown}
                    subjectManage="subjectManage"
                  />
                )}

                {checkActiveTab("protocolMetadata") && (
                  <ProtocolTrialSummeryMetadata
                    viewTypeData="ctms"
                    tableRef={tableRef}
                    onMouseDown={onMouseDown}
                  />
                )}

                {checkActiveTab("treatmentDesign") && (
                  <CTMSTreatmentManagement
                    tableRef={tableRef}
                    onMouseDown={onMouseDown}
                    viewTypeData="ctms"
                  />
                )}

                {checkActiveTab("trainingManagement") && (
                  <ELearningManagement
                    viewTypeData="ctms"
                    handleAddNewTab={handleAddNewTab}
                    tableRef={tableRef}
                    onMouseDown={onMouseDown}
                  />
                )}

                {checkDynamicActiveTab("trainingDataManagement") && (
                  <TrainingDataManagement
                    viewTypeData="ctms"
                    traningId={objectId?.traningId}
                    handleAddNewTab={handleAddNewTab}
                  />
                )}
              </div>
            </section>
          </ResizableY>
        </ResizableX>
        <Footer />
      </section>
    </div>
  );
};

export default CTMSManagement;
