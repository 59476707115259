import React, { useRef, useState } from "react";
import { Table } from "react-bootstrap";
import Moment from "react-moment";
import Swal from "sweetalert2";
import { cdiscServices } from "../../../../../Services/CDISC/cdiscServices";
import Pagination from "../../../../ETMF/Pagination/Pagination";

const SDATableData = ({
  sourceData,
  tableRef,
  onMouseDown,
  setIsFormOpen,
  setSourceDataId,
  getCDISCSDA,
  studyId,
}) => {
  const [highlightedRow, setHighlightedRow] = useState(null);

  const ref = useRef(null);
  const deleteCDISCSDA = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete SDA!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let results = await cdiscServices.deleteCDISCSDA(id, studyId);
        if (results?.statusCode === 200) {
          getCDISCSDA();
        }
      }
    });
  };

  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(15);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = sourceData?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(sourceData?.length / itemsPerPage);
  return (
    <>
      <div className="overflow-auto">
        <Table
          ref={tableRef}
          id="resizable-table"
          className="text-nowrap custom-table"
          hover
          striped
        >
          <thead className="thead-sticky z-1" style={{ top: -1 }}>
            <tr>
              <th onMouseDown={(e) => onMouseDown(e, 0)} style={{ width: 40 }}>
                Sr.
              </th>
              <th onMouseDown={(e) => onMouseDown(e, 1)}>
                Source Dataset Name
              </th>
              <th onMouseDown={(e) => onMouseDown(e, 2)}>
                Source Dataset Label
              </th>
              <th onMouseDown={(e) => onMouseDown(e, 3)}>Target Domain Name</th>
              <th onMouseDown={(e) => onMouseDown(e, 4)}>
                Target Domain Label
              </th>
              <th onMouseDown={(e) => onMouseDown(e, 5)}>Target Domain Type</th>
              <th onMouseDown={(e) => onMouseDown(e, 6)}>
                Modified Date | Time
              </th>
              <th onMouseDown={(e) => onMouseDown(e, 7)} style={{ width: 80 }}>
                <div className="text-center">Update</div>
              </th>
              <th onMouseDown={(e) => onMouseDown(e, 8)} style={{ width: 50 }}>
                <div className="text-center">Delete</div>
              </th>
            </tr>
          </thead>
          <tbody ref={ref}>
            {result?.length > 0 &&
              result?.map((item, index) => (
                <tr
                  key={index}
                  className={`position-relative ${
                    highlightedRow === index && "activeMenu"
                  }`}
                  onClick={() => setHighlightedRow(index)}
                >
                  <td>{(pageNumber - 1) * itemsPerPage + index + 1}</td>
                  <td>{item.sourceDatasetName}</td>
                  <td>{item.sourceDatasetLabel}</td>
                  <td>{item.targetDomain}</td>
                  <td>{item.targetDomainLabel}</td>
                  <td>{item.targetDomainType}</td>
                  <td>
                    <Moment format="DD MMM YYYY | HH:mm:ss">
                      {item.updatedAt}
                    </Moment>
                  </td>
                  <td className="text-center">
                    <button
                      onClick={() => {
                        setSourceDataId(item);
                        setIsFormOpen(true);
                      }}
                      title="Update"
                      className="text-white border-success bg-success border-0"
                      style={{ fontSize: 10, borderRadius: 3 }}
                    >
                      Update
                    </button>
                  </td>
                  <td>
                    <button
                      className="text-white border-danger bg-danger border-0"
                      style={{ fontSize: 10, borderRadius: 3 }}
                      title="Delete SDA"
                      onClick={() => deleteCDISCSDA(item?._id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
      <div className="position-sticky bottom-0">
        <Pagination
          totalPage={totalPage}
          pageNumber={pageNumber}
          itemsPerPage={itemsPerPage}
          totalItems={sourceData?.length}
          setPageNumber={setPageNumber}
          setItemsPerPage={setItemsPerPage}
        />
      </div>
    </>
  );
};

export default SDATableData;
