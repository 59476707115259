import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import SplitPane from "react-split-pane";
import { SpinerDataLoader } from "../../NoDataFoundTable/NoDataFoundTable";
import RandomizeTabData from "./RandomizeTabData";
import UseToggle from "../../../Hooks/UseToggle";
import { Form, Table } from "react-bootstrap";
import { randomizerServices } from "../../../Services/IWRS/randomizerServices";
import Moment from "react-moment";
import Pagination from "../../ETMF/Pagination/Pagination";

const Randomization = ({ onMouseDown, tableRef, type, setRandomizeId, setSeedBock }) => {
  const { toggle, setToggle } = UseToggle()
  const [updateType, setUpdateType] = useState("");
  const [updateTypes, setUpdateTypes] = useState("");
  const [loader, setLoader] = useState(false);
  const [title, setTitle] = useState("");
  const [randomizationData, setRandomizationData] = useState("");
  const [description, setDescription] = useState("");
  const [edc, setEDC] = useState("");
  const [randomizerMatadata, setRandomizerMatadata] = useState([]);
  const [isSectionVisible, setIsSectionVisible] = useState("");
  const [requestedData, setRequestedData] = useState("");
  const { studyId } = useParams();
  let studyMode = JSON.parse(localStorage.getItem("StudyMode"));
  const handleUpdate = (data) => {
    setRandomizationData(data)
    setDescription(data?.description)
    setTitle(data?.name)
    setUpdateType("update");
  };

  const fullModeTable = () => {
    setRandomizationData("")
    setDescription("")
    setTitle("")
    setUpdateType("");
  };

  const getRandomizerMetadataData = async () => {
    setLoader(true)
    let data = await randomizerServices.getRandomizerMetadataData(studyId, studyMode)
    setRandomizerMatadata(data?.data || [])
    setLoader(false)
  }

  const addRandomizerMetadata = async () => {
    let userData = {}
    userData.studyId = studyId
    userData.name = title
    userData.description = description
    userData.edc = edc
    let data = await randomizerServices.addRandomizerMetadata(userData)
    if (data?.statusCode === 200) {
      getRandomizerMetadataData()
      setDescription("")
      setEDC("")
      setTitle("")
    }
  }

  const updateRandomizerMetadataData = async () => {
    let userData = {}
    userData.id = randomizationData?._id
    userData.studyId = studyId
    userData.name = title
    userData.description = description
    userData.edc = edc
    let data = await randomizerServices.updateRandomizerMetadataData(userData)
    if (data?.statusCode === 200) {
      getRandomizerMetadataData()
    }
  }

  const deleteRandomizerMetadataData = async (id) => {
    let data = await randomizerServices.deleteRandomizerMetadataData(studyId, id)
    if (data?.statusCode === 200) {
      getRandomizerMetadataData()
    }
  }

  const iwrsPermissionData = JSON.parse(
    localStorage.getItem("iwrsPermissionData")
  );

  let generateRandomizationIWRS = iwrsPermissionData?.generateRandomizationIWRSQC || iwrsPermissionData?.generateRandomizationIWRSLive

  useEffect(() => {
    getRandomizerMetadataData();
  }, [studyId]);

  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(15);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = randomizerMatadata?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(randomizerMatadata?.length / itemsPerPage);

  return (
    <>
      <div className="position-relative">
        {type === "trialDesign" &&
          <div className="p-1 border-bottom">
            <div className="hstack gap-2 toolbar">
              {studyMode === "DBP MODE" &&
                <>
                  {updateType ? (
                    <i
                      onClick={fullModeTable}
                      title="Table Full Mode"
                      className="fa-solid fa-table-list CP"
                    ></i>
                  ) : (
                    <i
                      onClick={() => setUpdateType("add")}
                      title="Add New"
                      className="fa-solid fa-plus CP"
                    />
                  )}
                  <div className="vr"></div>
                </>
              }
              <i
                onClick={setToggle}
                title="Filter"
                className="fa-solid fa-filter CP"
              ></i>
            </div>
          </div>
        }
        <SplitPane
          split="horizontal"
          style={{ height: "calc(100vh - 134px)" }}
          className="position-relative"
          defaultSize={(type !== "trialDesign" &&
            isSectionVisible) ? "40%" : "100%"}
        >
          <SplitPane
            split="vertical"
            minSize={20}
            defaultSize={updateType ? "25%" : "100%"}
          >
            {loader ? (
              <SpinerDataLoader />
            ) : (
              updateType && (
                <div className="p-2">
                  <Form.Group className="mb-2">
                    <Form.Label className="mb-1 fw-bold">
                      Title <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      onChange={(e) => setTitle(e.target.value)}
                      value={title}
                      type="text"
                      placeholder="Write Title Here"
                    />
                  </Form.Group>
                  <Form.Group className="mb-2">
                    <Form.Label className="mb-1 fw-bold">
                      Description <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      onChange={(e) => setDescription(e.target.value)}
                      value={description}
                      as="textarea"
                      placeholder="Write Description Here"
                    />
                  </Form.Group>
                  <Form.Group className="mb-2">
                    <Form.Label className="mb-1 fw-bold">
                      Use for EDC <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Select
                      onChange={(e) => setEDC(e.target.value)}
                    >
                      <option value={""}>Select yes or no</option>
                      <option selected={edc === "yes"} value={"yes"}>Yes</option>
                      <option selected={edc === "no"} value={"no"}>No</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-2 text-end">
                    <button disabled={!title || !description} onClick={updateType === "add" ? addRandomizerMetadata : updateRandomizerMetadataData} className="updateProfile text-capitalize">{updateType}</button>
                  </Form.Group>
                </div>
              )
            )}
            {loader ? (
              <SpinerDataLoader />
            ) : (
              <>
                <Table
                  id="resizable-table"
                  striped
                  hover
                  className="m-0 custom-table"
                  ref={tableRef}>
                  <thead>
                    <tr>
                      <th style={{ width: 50 }} onMouseDown={(e) => onMouseDown(e, 0)}>Sr No.</th>
                      <th onMouseDown={(e) => onMouseDown(e, 1)}>Title</th>
                      <th onMouseDown={(e) => onMouseDown(e, 2)}>Description</th>
                      <th onMouseDown={(e) => onMouseDown(e, 3)}>EDC</th>
                      <th onMouseDown={(e) => onMouseDown(e, 4)}>Seed Number</th>
                      {type !== "trialDesign" &&
                        <th onMouseDown={(e) => onMouseDown(e, 5)}>Total Randomization</th>
                      }
                      <th onMouseDown={(e) => onMouseDown(e, 6)}>Randomization Date | Time</th>
                      {studyMode === "DBP MODE" && type === "trialDesign" &&
                        <>
                          <th style={{ width: 70 }} className="text-center" onMouseDown={(e) => onMouseDown(e, 7)}>
                            Update
                          </th>
                          <th style={{ width: 70 }} className="text-center" onMouseDown={(e) => onMouseDown(e, 8)}>
                            Delete
                          </th>
                        </>
                      }
                    </tr>
                  </thead>
                  <tbody>
                    {result?.map((item, index) => (
                      <tr onClick={() => setUpdateTypes("")} className={isSectionVisible?._id === item?._id ? "selectedMenu CP" : "CP"} key={index}>
                        <td onClick={() => { setIsSectionVisible(item); setSeedBock(item?.seedBlock); setRequestedData(item); setRandomizeId(item?._id) }}>{index + 1 + startIndex}</td>
                        <td onClick={() => { setIsSectionVisible(item); setSeedBock(item?.seedBlock); setRequestedData(item); setRandomizeId(item?._id) }}>
                          {item.name}
                        </td>
                        <td onClick={() => { setIsSectionVisible(item); setSeedBock(item?.seedBlock); setRequestedData(item); setRandomizeId(item?._id) }}>
                          {item.description}
                        </td>
                        <td className="text-capitalize" onClick={() => { setIsSectionVisible(item); setSeedBock(item?.seedBlock); setRequestedData(item); setRandomizeId(item?._id) }}>
                          {item.edc}
                        </td>
                        <td style={{ filter: (studyMode === "DBP MODE" || generateRandomizationIWRS) ? 'blur(0px)' : 'blur(5px)' }} onClick={() => { setIsSectionVisible(item); setSeedBock(item?.seedBlock); setRequestedData(item); setRandomizeId(item?._id) }}>
                          {item.seedNumber}
                        </td>
                        {type !== "trialDesign" &&
                          <td onClick={() => { setIsSectionVisible(item); setSeedBock(item?.seedBlock); setRequestedData(item); setRandomizeId(item?._id) }}>{item?.totalRandomized}</td>
                        }
                        <td onClick={() => { setIsSectionVisible(item); setSeedBock(item?.seedBlock); setRequestedData(item); setRandomizeId(item?._id) }}>
                          <Moment format="DD MMM YYYY | HH:mm:ss">{item.updatedAt}</Moment>
                        </td>
                        {studyMode === "DBP MODE" && type === "trialDesign" &&
                          <>
                            <td className="text-center">
                              <button
                                onClick={() => handleUpdate(item)}
                                className="text-white border-success bg-success border  "
                                style={{ fontSize: 10 }}
                              >
                                Update
                              </button>
                            </td>
                            <td className="text-center">
                              <button
                                onClick={() => deleteRandomizerMetadataData(item._id)}
                                className="text-white border-danger bg-danger border  "
                                style={{ fontSize: 10 }}
                              >
                                Delete
                              </button>
                            </td>
                          </>
                        }
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <div className="position-sticky bottom-0">
                  <Pagination
                    totalPage={totalPage}
                    pageNumber={pageNumber}
                    itemsPerPage={itemsPerPage}
                    totalItems={randomizerMatadata?.length}
                    setPageNumber={setPageNumber}
                    setItemsPerPage={setItemsPerPage}
                  />
                </div>
              </>
            )}
          </SplitPane>
          {type !== "trialDesign" && isSectionVisible && (
            <RandomizeTabData
              onMouseDown={onMouseDown}
              tableRef={tableRef}
              setUpdateType={setUpdateTypes}
              updateType={updateTypes}
              randomizerData={isSectionVisible}
              randomizerId={isSectionVisible?._id}
              requestedData={requestedData}
              setRequestedData={setRequestedData}
              setIsSectionVisible={setIsSectionVisible}
              getRandomizerMetadataData={getRandomizerMetadataData}
            />
          )}
        </SplitPane>
      </div>
    </>
  );
};

export default Randomization;
