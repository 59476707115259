import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import { Table } from "react-bootstrap";
import SplitPane from "react-split-pane";
import { useParams } from "react-router";
import Pagination from "../../ETMF/Pagination/Pagination";
import { FaCircleCheck, FaCircleXmark } from "react-icons/fa6";
import { SpinerDataLoader } from "../../NoDataFoundTable/NoDataFoundTable";
import { randomizerServices } from "../../../Services/IWRS/randomizerServices";

const RandomizeTabActivityData = ({ randomizedId, onMouseDown1, tableRef1 }) => {
    const [loader, setLoader] = useState("");
    let { studyId } = useParams()
    const [recentActivity, setRecentActivity] = useState([]);
    let studyMode = JSON.parse(localStorage.getItem("StudyMode"));
    const getRandomizerActivityByRandomized = async () => {
        setLoader(true);
        let data = await randomizerServices.getRandomizerActivityByRandomized(
            studyId,
            studyMode,
            randomizedId
        );
        setRecentActivity(data?.data || []);
        setLoader(false);
    };
    const [pageNumber, setPageNumber] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(15);
    const startIndex = (pageNumber - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const result = recentActivity?.slice(startIndex, endIndex);
    const totalPage = Math.ceil(recentActivity?.length / itemsPerPage);

    useEffect(() => {
        getRandomizerActivityByRandomized();
    }, [randomizedId]);
    return (
        <>
            <SplitPane
                split="vertical"
                minSize={20}
                defaultSize="100%"
                style={{ height: "83%" }}
            >
                {loader ? (
                    <SpinerDataLoader />
                ) : (
                    <div className="table-container tableLibrarySection">
                        <Table
                            id="resizable-table"
                            striped
                            hover
                            className="m-0 custom-table"
                            ref={tableRef1}
                        >
                            <thead className="thead-sticky top-0">
                                <tr>
                                    <th style={{ width: 40 }} className="text-center" onMouseDown1={(e) => onMouseDown1(e, 0)}>Sr.</th>
                                    <th className="text-center" onMouseDown1={(e) => onMouseDown1(e, 1)}>Status</th>
                                    <th onMouseDown1={(e) => onMouseDown1(e, 2)}>Type</th>
                                    <th onMouseDown1={(e) => onMouseDown1(e, 3)}>Activities</th>
                                    <th onMouseDown1={(e) => onMouseDown1(e, 4)}>Category</th>
                                    <th onMouseDown1={(e) => onMouseDown1(e, 5)}>Mode</th>
                                    <th onMouseDown1={(e) => onMouseDown1(e, 6)}>Activity By</th>
                                    <th onMouseDown1={(e) => onMouseDown1(e, 7)}>Activity Date</th>
                                    <th onMouseDown1={(e) => onMouseDown1(e, 8)}>Activity Time</th>
                                </tr>
                            </thead>
                            <tbody>
                                {result?.map((item, index) => (
                                    <tr className="relative">
                                        <td className="text-center">{index + 1}.</td>
                                        <td
                                            title={item.status === "200" ? "Success" : "Failed"}
                                            className="text-center"
                                        >
                                            {item.status === "200" ? (
                                                <FaCircleCheck className="text-success" />
                                            ) : (
                                                <FaCircleXmark className="text-danger" />
                                            )}
                                        </td>
                                        <td>
                                            {item.type}
                                        </td>
                                        <td>{item.activities}</td>
                                        <td className="text-capitalize">
                                            {item.category}
                                        </td>
                                        <td>{item.mode}</td>
                                        <td title={item.activityBy}>{item.activityBy}</td>
                                        <td>
                                            <Moment format="DD MMM YYYY">{item?.createdAt}</Moment>
                                        </td>
                                        <td>
                                            <Moment format="HH:mm:ss">{item?.createdAt}</Moment>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        <div className="position-sticky bottom-0">
                            <Pagination
                                itemsPerPage={itemsPerPage}
                                setItemsPerPage={setItemsPerPage}
                                pageNumber={pageNumber}
                                setPageNumber={setPageNumber}
                                totalPage={totalPage}
                                totalItems={recentActivity?.length}
                            />
                        </div>
                    </div>
                )}
            </SplitPane>
        </>
    );
};

export default RandomizeTabActivityData;
