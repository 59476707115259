import React from "react";
import { Form, Table } from "react-bootstrap";

const SwallonTenderJoints = ({
  swallonTenderJoint,
  item,
  valueData,
  handleSwallonTenderFormChange,
  joints,
  swallonTenderEntry,
  disabledData
}) => {
  let tenderCount = 0;
  let swallonCount = 0;

  if (swallonTenderEntry?.length > 0) {
    swallonTenderEntry?.forEach(entry => {
      // Count Tender
      if (entry?.LEFTTENDER?.QSORRES === "Present") tenderCount++;
      if (entry?.RIGHTTENDER?.QSORRES === "Present") tenderCount++;

      // Count Swallon
      if (entry?.LEFTSWALLON?.QSORRES === "Present") swallonCount++;
      if (entry?.RIGHTSWALLON?.QSORRES === "Present") swallonCount++;
    });
  } else {
    valueData?.forEach(entry => {
      // Count Tender
      if (entry?.LEFTTENDER?.QSORRES === "Present") tenderCount++;
      if (entry?.RIGHTTENDER?.QSORRES === "Present") tenderCount++;

      // Count Swallon
      if (entry?.LEFTSWALLON?.QSORRES === "Present") swallonCount++;
      if (entry?.RIGHTSWALLON?.QSORRES === "Present") swallonCount++;
    });
  }

  const renderRow = (joint, key) => {
    const isGroupStart =
      key === 0 || joints[key].group !== joints[key - 1].group;
    const isGroupEnd =
      key === joints.length - 1 ||
      joints[key].group !== joints[key + 1].group;

    return (
      swallonTenderJoint?.map(item => item.value)?.includes(joint?.left) && swallonTenderJoint?.map(item => item.value)?.includes(joint?.right) ? "" :
        <tr
          key={joint.name}
          className={`${isGroupStart ? "group-start" : ""} ${isGroupEnd ? "group-end" : ""
            }`}
        >
          {/* Left */}
          <td title={joint.leftTitle} className="p-0">
            <input
              disabled={disabledData || swallonTenderJoint?.map(item => item.value)?.includes(joint?.left)}
              type="radio"
              value={"Present"}
              onChange={(e) =>
                handleSwallonTenderFormChange(
                  joint.leftTitle,
                  "Tender",
                  e.target.value,
                  swallonTenderEntry?.[key]?.RIGHTTENDER?.QSTEST || valueData?.[key]?.RIGHTTENDER?.QSTEST,
                  swallonTenderEntry?.[key]?.RIGHTTENDER?.QSCAT || valueData?.[key]?.RIGHTTENDER?.QSCAT,
                  swallonTenderEntry?.[key]?.RIGHTTENDER?.QSORRES || valueData?.[key]?.RIGHTTENDER?.QSORRES,
                  swallonTenderEntry?.[key]?.LEFTSWALLON?.QSTEST || valueData?.[key]?.LEFTSWALLON?.QSTEST,
                  swallonTenderEntry?.[key]?.LEFTSWALLON?.QSCAT || valueData?.[key]?.LEFTSWALLON?.QSCAT,
                  swallonTenderEntry?.[key]?.LEFTSWALLON?.QSORRES || valueData?.[key]?.LEFTSWALLON?.QSORRES,
                  swallonTenderEntry?.[key]?.RIGHTSWALLON?.QSTEST || valueData?.[key]?.RIGHTSWALLON?.QSTEST,
                  swallonTenderEntry?.[key]?.RIGHTSWALLON?.QSCAT || valueData?.[key]?.RIGHTSWALLON?.QSCAT,
                  swallonTenderEntry?.[key]?.RIGHTSWALLON?.QSORRES || valueData?.[key]?.RIGHTSWALLON?.QSORRES,
                  key,
                  item,
                  valueData,
                  disabledData || swallonTenderJoint?.map(item => item.value)?.includes(joint?.left),
                )
              }
              checked={((swallonTenderEntry?.[key]?.LEFTTENDER?.QSCAT === "Tender" || valueData?.[key]?.LEFTTENDER?.QSCAT === "Tender") && (swallonTenderEntry?.[key]?.LEFTTENDER?.QSTEST === joint.leftTitle || valueData?.[key]?.LEFTTENDER?.QSTEST === joint.leftTitle) && (swallonTenderEntry?.[key]?.LEFTTENDER?.QSORRES || valueData?.[key]?.LEFTTENDER?.QSORRES)) === "Present"}
              name={`${joint.left}_${key}_Tender`}
            />
          </td>
          <td title={joint.leftTitle} className="p-0">
            <input
              disabled={disabledData || swallonTenderJoint?.map(item => item.value)?.includes(joint?.left)}
              type="radio"
              value={"Absent"}
              onChange={(e) =>
                handleSwallonTenderFormChange(
                  joint.leftTitle,
                  "Tender",
                  e.target.value,
                  swallonTenderEntry?.[key]?.RIGHTTENDER?.QSTEST || valueData?.[key]?.RIGHTTENDER?.QSTEST,
                  swallonTenderEntry?.[key]?.RIGHTTENDER?.QSCAT || valueData?.[key]?.RIGHTTENDER?.QSCAT,
                  swallonTenderEntry?.[key]?.RIGHTTENDER?.QSORRES || valueData?.[key]?.RIGHTTENDER?.QSORRES,
                  swallonTenderEntry?.[key]?.LEFTSWALLON?.QSTEST || valueData?.[key]?.LEFTSWALLON?.QSTEST,
                  swallonTenderEntry?.[key]?.LEFTSWALLON?.QSCAT || valueData?.[key]?.LEFTSWALLON?.QSCAT,
                  swallonTenderEntry?.[key]?.LEFTSWALLON?.QSORRES || valueData?.[key]?.LEFTSWALLON?.QSORRES,
                  swallonTenderEntry?.[key]?.RIGHTSWALLON?.QSTEST || valueData?.[key]?.RIGHTSWALLON?.QSTEST,
                  swallonTenderEntry?.[key]?.RIGHTSWALLON?.QSCAT || valueData?.[key]?.RIGHTSWALLON?.QSCAT,
                  swallonTenderEntry?.[key]?.RIGHTSWALLON?.QSORRES || valueData?.[key]?.RIGHTSWALLON?.QSORRES,
                  key,
                  item,
                  valueData,
                  disabledData || swallonTenderJoint?.map(item => item.value)?.includes(joint?.left),
                )
              }
              checked={((swallonTenderEntry?.[key]?.LEFTTENDER?.QSCAT === "Tender" || valueData?.[key]?.LEFTTENDER?.QSCAT === "Tender") && (swallonTenderEntry?.[key]?.LEFTTENDER?.QSTEST === joint.leftTitle || valueData?.[key]?.LEFTTENDER?.QSTEST === joint.leftTitle) && (swallonTenderEntry?.[key]?.LEFTTENDER?.QSORRES || valueData?.[key]?.LEFTTENDER?.QSORRES)) === "Absent"}
              name={`${joint.left}_${key}_Tender`}
            />
          </td>
          <td title={joint.leftTitle} className="p-0">
            <input
              disabled={disabledData || swallonTenderJoint?.map(item => item.value)?.includes(joint?.left)}
              type="radio"
              value={"ND"}
              onChange={(e) =>
                handleSwallonTenderFormChange(
                  joint.leftTitle,
                  "Tender",
                  e.target.value,
                  swallonTenderEntry?.[key]?.RIGHTTENDER?.QSTEST || valueData?.[key]?.RIGHTTENDER?.QSTEST,
                  swallonTenderEntry?.[key]?.RIGHTTENDER?.QSCAT || valueData?.[key]?.RIGHTTENDER?.QSCAT,
                  swallonTenderEntry?.[key]?.RIGHTTENDER?.QSORRES || valueData?.[key]?.RIGHTTENDER?.QSORRES,
                  swallonTenderEntry?.[key]?.LEFTSWALLON?.QSTEST || valueData?.[key]?.LEFTSWALLON?.QSTEST,
                  swallonTenderEntry?.[key]?.LEFTSWALLON?.QSCAT || valueData?.[key]?.LEFTSWALLON?.QSCAT,
                  swallonTenderEntry?.[key]?.LEFTSWALLON?.QSORRES || valueData?.[key]?.LEFTSWALLON?.QSORRES,
                  swallonTenderEntry?.[key]?.RIGHTSWALLON?.QSTEST || valueData?.[key]?.RIGHTSWALLON?.QSTEST,
                  swallonTenderEntry?.[key]?.RIGHTSWALLON?.QSCAT || valueData?.[key]?.RIGHTSWALLON?.QSCAT,
                  swallonTenderEntry?.[key]?.RIGHTSWALLON?.QSORRES || valueData?.[key]?.RIGHTSWALLON?.QSORRES,
                  key,
                  item,
                  valueData,
                  disabledData || swallonTenderJoint?.map(item => item.value)?.includes(joint?.left),
                )
              }
              checked={((swallonTenderEntry?.[key]?.LEFTTENDER?.QSCAT === "Tender" || valueData?.[key]?.LEFTTENDER?.QSCAT === "Tender") && (swallonTenderEntry?.[key]?.LEFTTENDER?.QSTEST === joint.leftTitle || valueData?.[key]?.LEFTTENDER?.QSTEST === joint.leftTitle) && (swallonTenderEntry?.[key]?.LEFTTENDER?.QSORRES || valueData?.[key]?.LEFTTENDER?.QSORRES)) === "ND"}
              name={`${joint.left}_${key}_Tender`}
            />
          </td>
          <td title={joint.leftTitle} className="p-0">
            {joint.group !== "Hip Extremity" &&
              <input
                disabled={disabledData || swallonTenderJoint?.map(item => item.value)?.includes(joint?.left)}
                type="radio"
                value={"Present"}
                onChange={(e) =>
                  handleSwallonTenderFormChange(
                    swallonTenderEntry?.[key]?.LEFTTENDER?.QSTEST || valueData?.[key]?.LEFTTENDER?.QSTEST,
                    swallonTenderEntry?.[key]?.LEFTTENDER?.QSCAT || valueData?.[key]?.LEFTTENDER?.QSCAT,
                    swallonTenderEntry?.[key]?.LEFTTENDER?.QSORRES || valueData?.[key]?.LEFTTENDER?.QSORRES,
                    swallonTenderEntry?.[key]?.RIGHTTENDER?.QSTEST || valueData?.[key]?.RIGHTTENDER?.QSTEST,
                    swallonTenderEntry?.[key]?.RIGHTTENDER?.QSCAT || valueData?.[key]?.RIGHTTENDER?.QSCAT,
                    swallonTenderEntry?.[key]?.RIGHTTENDER?.QSORRES || valueData?.[key]?.RIGHTTENDER?.QSORRES,
                    joint.leftTitle,
                    "Swallon",
                    e.target.value,
                    swallonTenderEntry?.[key]?.RIGHTSWALLON?.QSTEST || valueData?.[key]?.RIGHTSWALLON?.QSTEST,
                    swallonTenderEntry?.[key]?.RIGHTSWALLON?.QSCAT || valueData?.[key]?.RIGHTSWALLON?.QSCAT,
                    swallonTenderEntry?.[key]?.RIGHTSWALLON?.QSORRES || valueData?.[key]?.RIGHTSWALLON?.QSORRES,
                    key,
                    item,
                    valueData,
                    disabledData || swallonTenderJoint?.map(item => item.value)?.includes(joint?.left),
                  )
                }
                checked={((swallonTenderEntry?.[key]?.LEFTSWALLON?.QSCAT === "Swallon" || valueData?.[key]?.LEFTSWALLON?.QSCAT === "Swallon") && (swallonTenderEntry?.[key]?.LEFTSWALLON?.QSTEST === joint.leftTitle || valueData?.[key]?.LEFTSWALLON?.QSTEST === joint.leftTitle) && (swallonTenderEntry?.[key]?.LEFTSWALLON?.QSORRES || valueData?.[key]?.LEFTSWALLON?.QSORRES)) === "Present"}
                name={`${joint.left}_${key}_Swallon`}
              />
            }
          </td>
          <td title={joint.leftTitle} className="p-0">
            {joint.group !== "Hip Extremity" &&
              <input
                disabled={disabledData || swallonTenderJoint?.map(item => item.value)?.includes(joint?.left)}
                name={`${joint.left}_${key}_Swallon`}
                type="radio"
                value={"Absent"}
                onChange={(e) =>
                  handleSwallonTenderFormChange(
                    swallonTenderEntry?.[key]?.LEFTTENDER?.QSTEST || valueData?.[key]?.LEFTTENDER?.QSTEST,
                    swallonTenderEntry?.[key]?.LEFTTENDER?.QSCAT || valueData?.[key]?.LEFTTENDER?.QSCAT,
                    swallonTenderEntry?.[key]?.LEFTTENDER?.QSORRES || valueData?.[key]?.LEFTTENDER?.QSORRES,
                    swallonTenderEntry?.[key]?.RIGHTTENDER?.QSTEST || valueData?.[key]?.RIGHTTENDER?.QSTEST,
                    swallonTenderEntry?.[key]?.RIGHTTENDER?.QSCAT || valueData?.[key]?.RIGHTTENDER?.QSCAT,
                    swallonTenderEntry?.[key]?.RIGHTTENDER?.QSORRES || valueData?.[key]?.RIGHTTENDER?.QSORRES,
                    joint.leftTitle,
                    "Swallon",
                    e.target.value,
                    swallonTenderEntry?.[key]?.RIGHTSWALLON?.QSTEST || valueData?.[key]?.RIGHTSWALLON?.QSTEST,
                    swallonTenderEntry?.[key]?.RIGHTSWALLON?.QSCAT || valueData?.[key]?.RIGHTSWALLON?.QSCAT,
                    swallonTenderEntry?.[key]?.RIGHTSWALLON?.QSORRES || valueData?.[key]?.RIGHTSWALLON?.QSORRES,
                    key,
                    item,
                    valueData,
                    disabledData || swallonTenderJoint?.map(item => item.value)?.includes(joint?.left),
                  )
                }
                checked={((swallonTenderEntry?.[key]?.LEFTSWALLON?.QSCAT === "Swallon" || valueData?.[key]?.LEFTSWALLON?.QSCAT === "Swallon") && (swallonTenderEntry?.[key]?.LEFTSWALLON?.QSTEST === joint.leftTitle || valueData?.[key]?.LEFTSWALLON?.QSTEST === joint.leftTitle) && (swallonTenderEntry?.[key]?.LEFTSWALLON?.QSORRES || valueData?.[key]?.LEFTSWALLON?.QSORRES)) === "Absent"}
              />
            }
          </td>
          <td title={joint.leftTitle} className="p-0">
            {joint.group !== "Hip Extremity" &&
              <input
                disabled={disabledData || swallonTenderJoint?.map(item => item.value)?.includes(joint?.left)}
                name={`${joint.left}_${key}_Swallon`}
                type="radio"
                value={"ND"}
                onChange={(e) =>
                  handleSwallonTenderFormChange(
                    swallonTenderEntry?.[key]?.LEFTTENDER?.QSTEST || valueData?.[key]?.LEFTTENDER?.QSTEST,
                    swallonTenderEntry?.[key]?.LEFTTENDER?.QSCAT || valueData?.[key]?.LEFTTENDER?.QSCAT,
                    swallonTenderEntry?.[key]?.LEFTTENDER?.QSORRES || valueData?.[key]?.LEFTTENDER?.QSORRES,
                    swallonTenderEntry?.[key]?.RIGHTTENDER?.QSTEST || valueData?.[key]?.RIGHTTENDER?.QSTEST,
                    swallonTenderEntry?.[key]?.RIGHTTENDER?.QSCAT || valueData?.[key]?.RIGHTTENDER?.QSCAT,
                    swallonTenderEntry?.[key]?.RIGHTTENDER?.QSORRES || valueData?.[key]?.RIGHTTENDER?.QSORRES,
                    joint.leftTitle,
                    "Swallon",
                    e.target.value,
                    swallonTenderEntry?.[key]?.RIGHTSWALLON?.QSTEST || valueData?.[key]?.RIGHTSWALLON?.QSTEST,
                    swallonTenderEntry?.[key]?.RIGHTSWALLON?.QSCAT || valueData?.[key]?.RIGHTSWALLON?.QSCAT,
                    swallonTenderEntry?.[key]?.RIGHTSWALLON?.QSORRES || valueData?.[key]?.RIGHTSWALLON?.QSORRES,
                    key,
                    item,
                    valueData,
                    disabledData || swallonTenderJoint?.map(item => item.value)?.includes(joint?.left),
                  )
                }
                checked={((swallonTenderEntry?.[key]?.LEFTSWALLON?.QSCAT === "Swallon" || valueData?.[key]?.LEFTSWALLON?.QSCAT === "Swallon") && (swallonTenderEntry?.[key]?.LEFTSWALLON?.QSTEST === joint.leftTitle || valueData?.[key]?.LEFTSWALLON?.QSTEST === joint.leftTitle) && (swallonTenderEntry?.[key]?.LEFTSWALLON?.QSORRES || valueData?.[key]?.LEFTSWALLON?.QSORRES)) === "ND"}
              />
            }
          </td>
          <td title={joint.leftTitle} className="p-0">
            {joint.group !== "Hip Extremity" &&
              <input
                disabled={disabledData || swallonTenderJoint?.map(item => item.value)?.includes(joint?.left)}
                name={`${joint.left}_${key}_Swallon`}
                type="radio"
                value={"NA"}
                onChange={(e) =>
                  handleSwallonTenderFormChange(
                    swallonTenderEntry?.[key]?.LEFTTENDER?.QSTEST || valueData?.[key]?.LEFTTENDER?.QSTEST,
                    swallonTenderEntry?.[key]?.LEFTTENDER?.QSCAT || valueData?.[key]?.LEFTTENDER?.QSCAT,
                    swallonTenderEntry?.[key]?.LEFTTENDER?.QSORRES || valueData?.[key]?.LEFTTENDER?.QSORRES,
                    swallonTenderEntry?.[key]?.RIGHTTENDER?.QSTEST || valueData?.[key]?.RIGHTTENDER?.QSTEST,
                    swallonTenderEntry?.[key]?.RIGHTTENDER?.QSCAT || valueData?.[key]?.RIGHTTENDER?.QSCAT,
                    swallonTenderEntry?.[key]?.RIGHTTENDER?.QSORRES || valueData?.[key]?.RIGHTTENDER?.QSORRES,
                    joint.leftTitle,
                    "Swallon",
                    e.target.value,
                    swallonTenderEntry?.[key]?.RIGHTSWALLON?.QSTEST || valueData?.[key]?.RIGHTSWALLON?.QSTEST,
                    swallonTenderEntry?.[key]?.RIGHTSWALLON?.QSCAT || valueData?.[key]?.RIGHTSWALLON?.QSCAT,
                    swallonTenderEntry?.[key]?.RIGHTSWALLON?.QSORRES || valueData?.[key]?.RIGHTSWALLON?.QSORRES,
                    key,
                    item,
                    valueData,
                    disabledData || swallonTenderJoint?.map(item => item.value)?.includes(joint?.left),
                  )
                }
                checked={((swallonTenderEntry?.[key]?.LEFTSWALLON?.QSCAT === "Swallon" || valueData?.[key]?.LEFTSWALLON?.QSCAT === "Swallon") && (swallonTenderEntry?.[key]?.LEFTSWALLON?.QSTEST === joint.leftTitle || valueData?.[key]?.LEFTSWALLON?.QSTEST === joint.leftTitle) && (swallonTenderEntry?.[key]?.LEFTSWALLON?.QSORRES || valueData?.[key]?.LEFTSWALLON?.QSORRES)) === "NA"}
              />
            }
          </td>
          {/* Joint Name */}
          <td className="p-0">
            <Form.Label className="m-0">{joint.name}</Form.Label>
          </td>
          {/* Right */}
          <td title={joint.rightTitle} className="p-0">
            <input
              disabled={disabledData || swallonTenderJoint?.map(item => item.value)?.includes(joint?.right)}
              name={`${joint.right}_${key}_Tender`}
              type="radio"
              value={"Present"}
              onChange={(e) =>
                handleSwallonTenderFormChange(
                  swallonTenderEntry?.[key]?.LEFTTENDER?.QSTEST || valueData?.[key]?.LEFTTENDER?.QSTEST,
                  swallonTenderEntry?.[key]?.LEFTTENDER?.QSCAT || valueData?.[key]?.LEFTTENDER?.QSCAT,
                  swallonTenderEntry?.[key]?.LEFTTENDER?.QSORRES || valueData?.[key]?.LEFTTENDER?.QSORRES,
                  joint.rightTitle,
                  "Tender",
                  e.target.value,
                  swallonTenderEntry?.[key]?.LEFTSWALLON?.QSTEST || valueData?.[key]?.LEFTSWALLON?.QSTEST,
                  swallonTenderEntry?.[key]?.LEFTSWALLON?.QSCAT || valueData?.[key]?.LEFTSWALLON?.QSCAT,
                  swallonTenderEntry?.[key]?.LEFTSWALLON?.QSORRES || valueData?.[key]?.LEFTSWALLON?.QSORRES,
                  swallonTenderEntry?.[key]?.RIGHTSWALLON?.QSTEST || valueData?.[key]?.RIGHTSWALLON?.QSTEST,
                  swallonTenderEntry?.[key]?.RIGHTSWALLON?.QSCAT || valueData?.[key]?.RIGHTSWALLON?.QSCAT,
                  swallonTenderEntry?.[key]?.RIGHTSWALLON?.QSORRES || valueData?.[key]?.RIGHTSWALLON?.QSORRES,
                  key,
                  item,
                  valueData,
                  disabledData || swallonTenderJoint?.map(item => item.value)?.includes(joint?.right),
                )
              }
              checked={((swallonTenderEntry?.[key]?.RIGHTTENDER?.QSCAT === "Tender" || valueData?.[key]?.RIGHTTENDER?.QSCAT === "Tender") && (swallonTenderEntry?.[key]?.RIGHTTENDER?.QSTEST === joint.rightTitle || valueData?.[key]?.RIGHTTENDER?.QSTEST === joint.rightTitle) && (swallonTenderEntry?.[key]?.RIGHTTENDER?.QSORRES || valueData?.[key]?.RIGHTTENDER?.QSORRES)) === "Present"}
            />
          </td>
          <td title={joint.rightTitle} className="p-0">
            <input
              disabled={disabledData || swallonTenderJoint?.map(item => item.value)?.includes(joint?.right)}
              name={`${joint.right}_${key}_Tender`}
              type="radio"
              value={"Absent"}
              onChange={(e) =>
                handleSwallonTenderFormChange(
                  swallonTenderEntry?.[key]?.LEFTTENDER?.QSTEST || valueData?.[key]?.LEFTTENDER?.QSTEST,
                  swallonTenderEntry?.[key]?.LEFTTENDER?.QSCAT || valueData?.[key]?.LEFTTENDER?.QSCAT,
                  swallonTenderEntry?.[key]?.LEFTTENDER?.QSORRES || valueData?.[key]?.LEFTTENDER?.QSORRES,
                  joint.rightTitle,
                  "Tender",
                  e.target.value,
                  swallonTenderEntry?.[key]?.LEFTSWALLON?.QSTEST || valueData?.[key]?.LEFTSWALLON?.QSTEST,
                  swallonTenderEntry?.[key]?.LEFTSWALLON?.QSCAT || valueData?.[key]?.LEFTSWALLON?.QSCAT,
                  swallonTenderEntry?.[key]?.LEFTSWALLON?.QSORRES || valueData?.[key]?.LEFTSWALLON?.QSORRES,
                  swallonTenderEntry?.[key]?.RIGHTSWALLON?.QSTEST || valueData?.[key]?.RIGHTSWALLON?.QSTEST,
                  swallonTenderEntry?.[key]?.RIGHTSWALLON?.QSCAT || valueData?.[key]?.RIGHTSWALLON?.QSCAT,
                  swallonTenderEntry?.[key]?.RIGHTSWALLON?.QSORRES || valueData?.[key]?.RIGHTSWALLON?.QSORRES,
                  key,
                  item,
                  valueData,
                  disabledData || swallonTenderJoint?.map(item => item.value)?.includes(joint?.right),
                )
              }
              checked={((swallonTenderEntry?.[key]?.RIGHTTENDER?.QSCAT === "Tender" || valueData?.[key]?.RIGHTTENDER?.QSCAT === "Tender") && (swallonTenderEntry?.[key]?.RIGHTTENDER?.QSTEST === joint.rightTitle || valueData?.[key]?.RIGHTTENDER?.QSTEST === joint.rightTitle) && (swallonTenderEntry?.[key]?.RIGHTTENDER?.QSORRES || valueData?.[key]?.RIGHTTENDER?.QSORRES)) === "Absent"}
            />
          </td>
          <td title={joint.rightTitle} className="p-0">
            <input
              disabled={disabledData || swallonTenderJoint?.map(item => item.value)?.includes(joint?.right)}
              name={`${joint.right}_${key}_Tender`}
              type="radio"
              value={"ND"}
              onChange={(e) =>
                handleSwallonTenderFormChange(
                  swallonTenderEntry?.[key]?.LEFTTENDER?.QSTEST || valueData?.[key]?.LEFTTENDER?.QSTEST,
                  swallonTenderEntry?.[key]?.LEFTTENDER?.QSCAT || valueData?.[key]?.LEFTTENDER?.QSCAT,
                  swallonTenderEntry?.[key]?.LEFTTENDER?.QSORRES || valueData?.[key]?.LEFTTENDER?.QSORRES,
                  joint.rightTitle,
                  "Tender",
                  e.target.value,
                  swallonTenderEntry?.[key]?.LEFTSWALLON?.QSTEST || valueData?.[key]?.LEFTSWALLON?.QSTEST,
                  swallonTenderEntry?.[key]?.LEFTSWALLON?.QSCAT || valueData?.[key]?.LEFTSWALLON?.QSCAT,
                  swallonTenderEntry?.[key]?.LEFTSWALLON?.QSORRES || valueData?.[key]?.LEFTSWALLON?.QSORRES,
                  swallonTenderEntry?.[key]?.RIGHTSWALLON?.QSTEST || valueData?.[key]?.RIGHTSWALLON?.QSTEST,
                  swallonTenderEntry?.[key]?.RIGHTSWALLON?.QSCAT || valueData?.[key]?.RIGHTSWALLON?.QSCAT,
                  swallonTenderEntry?.[key]?.RIGHTSWALLON?.QSORRES || valueData?.[key]?.RIGHTSWALLON?.QSORRES,
                  key,
                  item,
                  valueData,
                  disabledData || swallonTenderJoint?.map(item => item.value)?.includes(joint?.right),
                )
              }
              checked={((swallonTenderEntry?.[key]?.RIGHTTENDER?.QSCAT === "Tender" || valueData?.[key]?.RIGHTTENDER?.QSCAT === "Tender") && (swallonTenderEntry?.[key]?.RIGHTTENDER?.QSTEST === joint.rightTitle || valueData?.[key]?.RIGHTTENDER?.QSTEST === joint.rightTitle) && (swallonTenderEntry?.[key]?.RIGHTTENDER?.QSORRES || valueData?.[key]?.RIGHTTENDER?.QSORRES)) === "ND"}
            />
          </td>
          <td title={joint.rightTitle} className="p-0">
            {joint.group !== "Hip Extremity" &&
              <input
                disabled={disabledData || swallonTenderJoint?.map(item => item.value)?.includes(joint?.right)}
                name={`${joint.right}_${key}_Swallon`}
                type="radio"
                value={"Present"}
                onChange={(e) =>
                  handleSwallonTenderFormChange(
                    swallonTenderEntry?.[key]?.LEFTTENDER?.QSTEST || valueData?.[key]?.LEFTTENDER?.QSTEST,
                    swallonTenderEntry?.[key]?.LEFTTENDER?.QSCAT || valueData?.[key]?.LEFTTENDER?.QSCAT,
                    swallonTenderEntry?.[key]?.LEFTTENDER?.QSORRES || valueData?.[key]?.LEFTTENDER?.QSORRES,
                    swallonTenderEntry?.[key]?.RIGHTTENDER?.QSTEST || valueData?.[key]?.RIGHTTENDER?.QSTEST,
                    swallonTenderEntry?.[key]?.RIGHTTENDER?.QSCAT || valueData?.[key]?.RIGHTTENDER?.QSCAT,
                    swallonTenderEntry?.[key]?.RIGHTTENDER?.QSORRES || valueData?.[key]?.RIGHTTENDER?.QSORRES,
                    swallonTenderEntry?.[key]?.LEFTSWALLON?.QSTEST || valueData?.[key]?.LEFTSWALLON?.QSTEST,
                    swallonTenderEntry?.[key]?.LEFTSWALLON?.QSCAT || valueData?.[key]?.LEFTSWALLON?.QSCAT,
                    swallonTenderEntry?.[key]?.LEFTSWALLON?.QSORRES || valueData?.[key]?.LEFTSWALLON?.QSORRES,
                    joint.rightTitle,
                    "Swallon",
                    e.target.value,
                    key,
                    item,
                    valueData,
                    disabledData || swallonTenderJoint?.map(item => item.value)?.includes(joint?.right),
                  )
                }
                checked={((swallonTenderEntry?.[key]?.RIGHTSWALLON?.QSCAT === "Swallon" || valueData?.[key]?.RIGHTSWALLON?.QSCAT === "Swallon") && (swallonTenderEntry?.[key]?.RIGHTSWALLON?.QSTEST === joint.rightTitle || valueData?.[key]?.RIGHTSWALLON?.QSTEST === joint.rightTitle) && (swallonTenderEntry?.[key]?.RIGHTSWALLON?.QSORRES || valueData?.[key]?.RIGHTSWALLON?.QSORRES)) === "Present"}
              />
            }
          </td>
          <td title={joint.rightTitle} className="p-0">
            {joint.group !== "Hip Extremity" &&
              <input
                disabled={disabledData || swallonTenderJoint?.map(item => item.value)?.includes(joint?.right)}
                name={`${joint.right}_${key}_Swallon`}
                type="radio"
                value={"Absent"}
                onChange={(e) =>
                  handleSwallonTenderFormChange(
                    swallonTenderEntry?.[key]?.LEFTTENDER?.QSTEST || valueData?.[key]?.LEFTTENDER?.QSTEST,
                    swallonTenderEntry?.[key]?.LEFTTENDER?.QSCAT || valueData?.[key]?.LEFTTENDER?.QSCAT,
                    swallonTenderEntry?.[key]?.LEFTTENDER?.QSORRES || valueData?.[key]?.LEFTTENDER?.QSORRES,
                    swallonTenderEntry?.[key]?.RIGHTTENDER?.QSTEST || valueData?.[key]?.RIGHTTENDER?.QSTEST,
                    swallonTenderEntry?.[key]?.RIGHTTENDER?.QSCAT || valueData?.[key]?.RIGHTTENDER?.QSCAT,
                    swallonTenderEntry?.[key]?.RIGHTTENDER?.QSORRES || valueData?.[key]?.RIGHTTENDER?.QSORRES,
                    swallonTenderEntry?.[key]?.LEFTSWALLON?.QSTEST || valueData?.[key]?.LEFTSWALLON?.QSTEST,
                    swallonTenderEntry?.[key]?.LEFTSWALLON?.QSCAT || valueData?.[key]?.LEFTSWALLON?.QSCAT,
                    swallonTenderEntry?.[key]?.LEFTSWALLON?.QSORRES || valueData?.[key]?.LEFTSWALLON?.QSORRES,
                    joint.rightTitle,
                    "Swallon",
                    e.target.value,
                    key,
                    item,
                    valueData,
                    disabledData || swallonTenderJoint?.map(item => item.value)?.includes(joint?.right),
                  )
                }
                checked={((swallonTenderEntry?.[key]?.RIGHTSWALLON?.QSCAT === "Swallon" || valueData?.[key]?.RIGHTSWALLON?.QSCAT === "Swallon") && (swallonTenderEntry?.[key]?.RIGHTSWALLON?.QSTEST === joint.rightTitle || valueData?.[key]?.RIGHTSWALLON?.QSTEST === joint.rightTitle) && (swallonTenderEntry?.[key]?.RIGHTSWALLON?.QSORRES || valueData?.[key]?.RIGHTSWALLON?.QSORRES)) === "Absent"}
              />
            }
          </td>
          <td title={joint.rightTitle} className="p-0">
            {joint.group !== "Hip Extremity" &&
              <input
                disabled={disabledData || swallonTenderJoint?.map(item => item.value)?.includes(joint?.right)}
                name={`${joint.right}_${key}_Swallon`}
                type="radio"
                value={"ND"}
                onChange={(e) =>
                  handleSwallonTenderFormChange(
                    swallonTenderEntry?.[key]?.LEFTTENDER?.QSTEST || valueData?.[key]?.LEFTTENDER?.QSTEST,
                    swallonTenderEntry?.[key]?.LEFTTENDER?.QSCAT || valueData?.[key]?.LEFTTENDER?.QSCAT,
                    swallonTenderEntry?.[key]?.LEFTTENDER?.QSORRES || valueData?.[key]?.LEFTTENDER?.QSORRES,
                    swallonTenderEntry?.[key]?.RIGHTTENDER?.QSTEST || valueData?.[key]?.RIGHTTENDER?.QSTEST,
                    swallonTenderEntry?.[key]?.RIGHTTENDER?.QSCAT || valueData?.[key]?.RIGHTTENDER?.QSCAT,
                    swallonTenderEntry?.[key]?.RIGHTTENDER?.QSORRES || valueData?.[key]?.RIGHTTENDER?.QSORRES,
                    swallonTenderEntry?.[key]?.LEFTSWALLON?.QSTEST || valueData?.[key]?.LEFTSWALLON?.QSTEST,
                    swallonTenderEntry?.[key]?.LEFTSWALLON?.QSCAT || valueData?.[key]?.LEFTSWALLON?.QSCAT,
                    swallonTenderEntry?.[key]?.LEFTSWALLON?.QSORRES || valueData?.[key]?.LEFTSWALLON?.QSORRES,
                    joint.rightTitle,
                    "Swallon",
                    e.target.value,
                    key,
                    item,
                    valueData,
                    disabledData || swallonTenderJoint?.map(item => item.value)?.includes(joint?.right),
                  )
                }
                checked={((swallonTenderEntry?.[key]?.RIGHTSWALLON?.QSCAT === "Swallon" || valueData?.[key]?.RIGHTSWALLON?.QSCAT === "Swallon") && (swallonTenderEntry?.[key]?.RIGHTSWALLON?.QSTEST === joint.rightTitle || valueData?.[key]?.RIGHTSWALLON?.QSTEST === joint.rightTitle) && (swallonTenderEntry?.[key]?.RIGHTSWALLON?.QSORRES || valueData?.[key]?.RIGHTSWALLON?.QSORRES)) === "ND"}
              />
            }
          </td>
          <td title={joint.rightTitle} className="p-0">
            {joint.group !== "Hip Extremity" &&
              <input
                disabled={disabledData || swallonTenderJoint?.map(item => item.value)?.includes(joint?.right)}
                name={`${joint.right}_${key}_Swallon`}
                type="radio"
                value={"NA"}
                onChange={(e) =>
                  handleSwallonTenderFormChange(
                    swallonTenderEntry?.[key]?.LEFTTENDER?.QSTEST || valueData?.[key]?.LEFTTENDER?.QSTEST,
                    swallonTenderEntry?.[key]?.LEFTTENDER?.QSCAT || valueData?.[key]?.LEFTTENDER?.QSCAT,
                    swallonTenderEntry?.[key]?.LEFTTENDER?.QSORRES || valueData?.[key]?.LEFTTENDER?.QSORRES,
                    swallonTenderEntry?.[key]?.RIGHTTENDER?.QSTEST || valueData?.[key]?.RIGHTTENDER?.QSTEST,
                    swallonTenderEntry?.[key]?.RIGHTTENDER?.QSCAT || valueData?.[key]?.RIGHTTENDER?.QSCAT,
                    swallonTenderEntry?.[key]?.RIGHTTENDER?.QSORRES || valueData?.[key]?.RIGHTTENDER?.QSORRES,
                    swallonTenderEntry?.[key]?.LEFTSWALLON?.QSTEST || valueData?.[key]?.LEFTSWALLON?.QSTEST,
                    swallonTenderEntry?.[key]?.LEFTSWALLON?.QSCAT || valueData?.[key]?.LEFTSWALLON?.QSCAT,
                    swallonTenderEntry?.[key]?.LEFTSWALLON?.QSORRES || valueData?.[key]?.LEFTSWALLON?.QSORRES,
                    joint.rightTitle,
                    "Swallon",
                    e.target.value,
                    key,
                    item,
                    valueData,
                    disabledData || swallonTenderJoint?.map(item => item.value)?.includes(joint?.right),
                  )
                }
                checked={((swallonTenderEntry?.[key]?.RIGHTSWALLON?.QSCAT === "Swallon" || valueData?.[key]?.RIGHTSWALLON?.QSCAT === "Swallon") && (swallonTenderEntry?.[key]?.RIGHTSWALLON?.QSTEST === joint.rightTitle || valueData?.[key]?.RIGHTSWALLON?.QSTEST === joint.rightTitle) && (swallonTenderEntry?.[key]?.RIGHTSWALLON?.QSORRES || valueData?.[key]?.RIGHTSWALLON?.QSORRES)) === "NA"}
              />
            }
          </td>
        </tr>
    );
  };
  return (
    <>
      <Table
        bordered
        className="text-center border-start border-end mt-1 w-100"
      >
        <thead style={{ backgroundColor: "#b8cfdf" }}>
          <tr>
            <th className="p-0" colSpan="7">
              <Form.Label className="m-0">Left</Form.Label>
            </th>
            <th className="p-0" colSpan="1">
              <Form.Label className="m-0">Joint</Form.Label>
            </th>
            <th className="p-0" colSpan="7">
              <Form.Label className="m-0">Right</Form.Label>
            </th>
          </tr>
          <tr>
            <th className="p-0" colSpan="3">
              <Form.Label className="m-0">Tender</Form.Label>
            </th>
            <th className="p-0" colSpan="4">
              <Form.Label className="m-0">Swelling</Form.Label>
            </th>
            <th className="p-0"></th>
            <th className="p-0" colSpan="3">
              <Form.Label className="m-0">Tender</Form.Label>
            </th>
            <th className="p-0" colSpan="4">
              <Form.Label className="m-0">Swelling</Form.Label>
            </th>
          </tr>
          <tr>
            <th className="p-0">
              <Form.Label className="m-0">Present</Form.Label>
            </th>
            <th className="p-0">
              <Form.Label className="m-0">Absent</Form.Label>
            </th>
            <th className="p-0">
              <Form.Label className="m-0">ND</Form.Label>
            </th>
            <th className="p-0">
              <Form.Label className="m-0">Present</Form.Label>
            </th>
            <th className="p-0">
              <Form.Label className="m-0">Absent</Form.Label>
            </th>
            <th className="p-0">
              <Form.Label className="m-0">ND</Form.Label>
            </th>
            <th className="p-0">
              <Form.Label className="m-0">NA</Form.Label>
            </th>
            <th className="p-0"></th>
            <th className="p-0">
              <Form.Label className="m-0">Present</Form.Label>
            </th>
            <th className="p-0">
              <Form.Label className="m-0">Absent</Form.Label>
            </th>
            <th className="p-0">
              <Form.Label className="m-0">ND</Form.Label>
            </th>
            <th className="p-0">
              <Form.Label className="m-0">Present</Form.Label>
            </th>
            <th className="p-0">
              <Form.Label className="m-0">Absent</Form.Label>
            </th>
            <th className="p-0">
              <Form.Label className="m-0">ND</Form.Label>
            </th>
            <th className="p-0">
              <Form.Label className="m-0">NA</Form.Label>
            </th>
          </tr>
        </thead>
        <tbody>{joints?.map((joint, key) => renderRow(joint, key))}</tbody>
      </Table>
      <div className="mt-2 d-flex gap-3 flex-wrap text-nowrap">
        <Form.Label>ND: Not Done</Form.Label>
        <Form.Label>NA: Not Applicable</Form.Label>
        <Form.Label>MCP: Metacarpophalangeal joints</Form.Label>
        <Form.Label>PIP: Proximal Interphalangeal Joints</Form.Label>
        <Form.Label>DIP: Distal Interphalangeal Joints</Form.Label>
        <Form.Label>MTP: Metatarsal Phalangeal Joints</Form.Label>
        <Form.Label>Total Tender Present: {tenderCount || 0}</Form.Label>
        <Form.Label>Total Swallon Present: {swallonCount || 0}</Form.Label>
      </div>
    </>
  );
};

export default SwallonTenderJoints;
