import React, { useState } from 'react'
import { Form, Offcanvas } from 'react-bootstrap'
import { randomizerServices } from '../../../Services/IWRS/randomizerServices'

const RandomizeExportData = ({ Show, Hide, studyId, randomizedId }) => {
    const [extension, setExtension] = useState("csv");
    let studyMode = JSON.parse(localStorage.getItem("StudyMode"));
    const exportRandomizedData = async () => {
        await randomizerServices.exportRandomizedData(studyId, randomizedId, extension, studyMode)
    }
    return (
        <>
            <Offcanvas
                show={Show} onHide={Hide}
                style={{ width: 400 }} placement="end"
            >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Export Data</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className="p-0">
                    <div
                        className="p-3 overflow-auto"
                        style={{ height: "calc(100vh - 89px)" }}
                    >
                        <Form.Group className="mb-2">
                            <Form.Label className="mb-1">
                                <b>Select File</b>
                            </Form.Label>
                            <Form.Select
                                onChange={(e) => setExtension(e.target.value)}
                                className="p-1 px-2   text-capitalize"
                            >
                                <option value="">Select File</option>
                                <option value={"csv"} selected={extension === "csv"}>
                                    CSV
                                </option>
                                <option value={"csv"} selected={extension === "excel"}>
                                    Excel
                                </option>
                            </Form.Select>
                        </Form.Group>
                    </div>
                    <div onClick={exportRandomizedData} className="p-2 bg-light shadow-sm hstack justify-content-end gap-2">
                        <button
                            disabled={!extension}
                            className="border fontSize12   p-1 px-2 updateProfile"
                        >
                            Export
                        </button>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

export default RandomizeExportData
