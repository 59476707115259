import React, { useEffect, useState } from "react";
import { Form, Spinner, Table } from "react-bootstrap";
import { useParams } from "react-router";
import { usersOnboardingService } from "../../../../Services/usersOnboarding";
import {
  NoDataFoundTable,
  SpinerDataLoader,
} from "../../../NoDataFoundTable/NoDataFoundTable";
import { cdiscServices } from "../../../../Services/CDISC/cdiscServices";

const AssignedUser = ({
  onMouseDown1,
  tableRef1,
  crfProperties,
  getStudyDataTemplates,
}) => {
  const { studyId } = useParams();
  const [checkAssignInitiatedUser, setCheckAssignInitiatedUser] = useState([]);
  const [checkboxValues, setCheckboxValues] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const getCDISCClientUser = async () => {
    setLoader(true);
    let data = await usersOnboardingService.getCDISCClientUser(studyId);
    setCheckAssignInitiatedUser(data?.data);
    setLoader(false);
  };
  const handleSelectAllCheckboxes = (e) => {
    const isChecked = e.target.checked;
    const updatedCheckboxValues = isChecked
      ? checkAssignInitiatedUser.map((item) => item._id)
      : [];

    setCheckboxValues(updatedCheckboxValues);

    const updatedForms = checkAssignInitiatedUser.map((item) => ({
      ...item,
      isChecked,
    }));

    setCheckAssignInitiatedUser(updatedForms);
  };

  const handleCheckboxChange = (id, isChecked) => {
    const updatedCheckboxValues = isChecked
      ? [...checkboxValues, id]
      : checkboxValues.filter((item) => item !== id);

    setCheckboxValues(updatedCheckboxValues);

    const updatedForms = checkAssignInitiatedUser.map((item) =>
      item._id === id ? { ...item, isChecked } : item
    );

    setCheckAssignInitiatedUser(updatedForms);
  };

  let assignedForm =
    checkboxValues?.length === 0 || checkboxValues?.length === undefined
      ? []
      : checkboxValues;

  useEffect(() => {
    getCDISCClientUser();
  }, [studyId]);

  useEffect(() => {
    setCheckboxValues(crfProperties?.assignedUsers || []);
  }, [crfProperties]);

  const assignStudy = async () => {
    setLoading(true);
    let userData = {};
    userData.id = crfProperties?._id;
    userData.studyId = studyId;
    userData.assignedUsers = checkboxValues;
    await cdiscServices.assignedUserStudyDataTemplates(userData);
    getStudyDataTemplates();
    setLoading(false);
  };

  return (
    <>
      {loader ? (
        <SpinerDataLoader />
      ) : checkAssignInitiatedUser?.length === 0 ? (
        <NoDataFoundTable MSG="User not found" />
      ) : (
        <>
          <Table
            id="resizable-table"
            striped
            hover
            className="m-0 custom-table"
            ref={tableRef1}
          >
            <thead>
              <tr>
                <th
                  onMouseDown1={(e) => onMouseDown1(e, 0)}
                  style={{ width: 50 }}
                  className="text-center"
                >
                  <Form.Check
                    type="checkbox"
                    onChange={(e) => handleSelectAllCheckboxes(e)}
                    checked={
                      checkAssignInitiatedUser?.length === 0
                        ? false
                        : assignedForm?.length ===
                          checkAssignInitiatedUser?.length
                    }
                  />
                </th>
                <th onMouseDown1={(e) => onMouseDown1(e, 1)}>UserId</th>
                <th onMouseDown1={(e) => onMouseDown1(e, 2)}>Full Name</th>
              </tr>
            </thead>
            <tbody>
              {checkAssignInitiatedUser?.map((item, index) => (
                <tr key={index}>
                  <td className="text-center">
                    <Form.Check
                      type="checkbox"
                      name={item?._id}
                      id={item?._id}
                      checked={assignedForm?.includes(item?._id)}
                      onChange={(e) =>
                        handleCheckboxChange(e.target.name, e.target.checked)
                      }
                    />
                  </td>
                  <td>{item.userId}</td>
                  <td>{item.fullName}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          {checkAssignInitiatedUser?.length > 0 && (
            <div className="position-sticky w-100 bg-white">
              <div className="text-end p-2">
                <button
                  disabled={loading}
                  className="submitbutton save__all__data_action"
                  onClick={() => assignStudy()}
                >
                  {loading ? (
                    <Spinner
                      className="me-1"
                      style={{ width: 15, height: 15, color: "var(--blue)" }}
                      animation="border"
                    />
                  ) : (
                    <i className="fa-solid fa-right-to-bracket me-1"></i>
                  )}
                  Assign Form
                </button>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default AssignedUser;
