import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useParams } from "react-router";
import { cdiscServices } from "../../../../Services/CDISC/cdiscServices";
import {
  NoDataFoundTable,
  SpinerDataLoader,
} from "../../../NoDataFoundTable/NoDataFoundTable";
import Moment from "react-moment";

const LockUnlockHistory = ({ onMouseDown1, tableRef1, crfProperties }) => {
  const { studyId } = useParams();
  const [lockedHistory, setLockedHistory] = useState([]);
  const [loader, setLoader] = useState(false);
  const getStudyDataTemplateLockedHistory = async (id) => {
    setLoader(true);
    let data = await cdiscServices.getStudyDataTemplateLockedHistory(
      id,
      studyId
    );
    setLockedHistory(data?.data || []);
    setLoader(false);
  };
  useEffect(() => {
    getStudyDataTemplateLockedHistory(crfProperties?._id);
  }, [crfProperties, studyId]);
  return (
    <>
      {loader ? (
        <SpinerDataLoader />
      ) : lockedHistory?.length === 0 ? (
        <NoDataFoundTable MSG="Lock History not found" />
      ) : (
        <>
          <Table
            id="resizable-table"
            striped
            hover
            className="m-0 custom-table"
            ref={tableRef1}
          >
            <thead className="thead-sticky">
              <tr>
                <th
                  onMouseDown1={(e) => onMouseDown1(e, 0)}
                  style={{ width: 50 }}
                  className="text-center"
                >
                  Sr.
                </th>
                <th onMouseDown1={(e) => onMouseDown1(e, 1)}>Status</th>
                <th onMouseDown1={(e) => onMouseDown1(e, 2)}>Activity By</th>
                <th onMouseDown1={(e) => onMouseDown1(e, 3)}>
                  Activity Date | Time
                </th>
              </tr>
            </thead>
            <tbody>
              {lockedHistory?.map((item, index) => (
                <tr key={index}>
                  <td className="text-center">{index + 1}</td>
                  <td className="text-capitalize">{item.status}</td>
                  <td>{item.fullName}</td>
                  <td>
                    <Moment format="DD MMM YYYY | HH:mm:ss">
                      {item?.createdAt}
                    </Moment>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )}
    </>
  );
};

export default LockUnlockHistory;
