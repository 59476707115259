import React, { useEffect, useRef, useState } from "react";
import UpdatedHistory from "./UpdatedHistory";
import EventReviewHistory from "./EventReviewHistory";
import StudyEventAuditLogs from "./StudyEventAuditLogs";
import StudyIdStudyVisitCrf from "./StudyIdStudyVisitCrf";
import StudyEventAttributeTable from "./StudyEventAttributeTable";
import StudyEventQueryListComponent from "./QueryManagement/StudyEventQueryListComponent";
const EventTabProperties = ({
  visitUniqueId,
  viewTypeData,
  setIsSectionVisible,
  getStudyVisitRowData,
}) => {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [activeTabs, setActiveTabs] = useState("properties");
  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  let finalTabArray =
    viewTypeData === "ctms"
      ? ["properties", "review History", "query"]
      : ["properties", "history", "assign Event Form", "activities"];

  const [resizingColumn, setResizingColumn] = useState(null);
  const startX = useRef(0);
  const startWidth = useRef(0);
  const tableRef1 = useRef(null);

  useEffect(() => {
    const onMouseMove = (e) => {
      if (resizingColumn !== null) {
        const width = startWidth.current + (e.pageX - startX.current);
        const table = tableRef1.current;

        if (!table) {
          console.error("Table reference is null.");
          return;
        }

        const th = table.querySelector(`th:nth-child(${resizingColumn + 1})`);

        if (!th) {
          console.error(
            `No th element found for column index: ${resizingColumn}`
          );
          return;
        }

        th.style.width = `${width}px`;
        table.classList.add("resizing");
      }
    };

    const onMouseUp = () => {
      setResizingColumn(null);
      document.body.style.userSelect = "";
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };

    if (resizingColumn !== null) {
      document.addEventListener("mousemove", onMouseMove);
      document.addEventListener("mouseup", onMouseUp);
    }

    return () => {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };
  }, [resizingColumn]);

  const onMouseDown1 = (e, columnIndex) => {
    setResizingColumn(columnIndex);
    startX.current = e.pageX;
    startWidth.current = e.target.offsetWidth;
    document.body.style.userSelect = "none";
    // tableRef1?.current?.querySelectorAll("th")?.forEach((column, index) => {
    //   if (index !== columnIndex) {
    //     column.style.width = `${column.offsetWidth}px`;
    //   }
    // });
  };

  return (
    <>
      <div className={isFullScreen ? "full-screen-component" : ""}>
        <div className="hstack justify-content-between bg-light border-bottom pt-1">
          <div className="hstack me-1 gap-1">
            {finalTabArray?.map((tab) => (
              <button
                key={tab}
                className={`buttonForTabs ${activeTabs === tab ? "activee" : ""
                  }`}
                onClick={() => setActiveTabs(tab)}
                title={tab.charAt(0).toUpperCase() + tab.slice(1)}
              >
                {tab.charAt(0).toUpperCase() + tab.slice(1)}
              </button>
            ))}
          </div>
          <div className="d-flex gap-1">
            <div className="toolbar">
              <i
                className="fa-solid fa-angle-down"
                onClick={() => setIsSectionVisible(false)}
              ></i>
            </div>
            <div className="toolbar">
              <i
                className={`fa-solid me-2 ${!isFullScreen ? "fa-maximize" : "fa-minimize"
                  }`}
                title={!isFullScreen ? "Full Mode" : "Original Mode"}
                onClick={toggleFullScreen}
              ></i>
            </div>
          </div>
        </div>
        {activeTabs === "properties" ? (
          <StudyEventAttributeTable
            visitUniqueId={visitUniqueId}
            tableRef1={tableRef1}
            onMouseDown1={onMouseDown1}
          />
        ) : activeTabs === "history" ? (
          <UpdatedHistory
            // studyMetadataHistoryLists={studyVisitHistoryLists}
            tableRef1={tableRef1}
            onMouseDown1={onMouseDown1}
          />
        ) : activeTabs === "review History" ? (
          <EventReviewHistory
            studyUniqueId={visitUniqueId}
            tableRef1={tableRef1}
            onMouseDown1={onMouseDown1}
          />
        ) : activeTabs === "assign Event Form" ? (
          <StudyIdStudyVisitCrf
            tableRef1={tableRef1}
            onMouseDown1={onMouseDown1}
            visitUniqueId={visitUniqueId}
          />
        ) : activeTabs === "query" ? (
          <StudyEventQueryListComponent
            tableRef1={tableRef1}
            onMouseDown1={onMouseDown1}
            visitUniqueId={visitUniqueId}
            getStudyVisitRowData={getStudyVisitRowData}
          />
        ) : activeTabs === "activities" ? (
          <StudyEventAuditLogs
            tableRef1={tableRef1}
            onMouseDown1={onMouseDown1}
            visitUniqueId={visitUniqueId}
          />
        ) : (
          " "
        )}
      </div>
    </>
  );
};

export default EventTabProperties;
