import React, { useEffect } from "react";
import { Table } from "react-bootstrap";
import SplitPane from "react-split-pane";

const StudySiteSubjectSettingsTable = ({ subjectData, tableRef1, onMouseDown1 }) => {

  useEffect(() => {
  }, [subjectData]);
  
  return (
    <>
      <SplitPane
        split="vertical"
        minSize={20}
        defaultSize="100%"
        style={{ height: "84%" }}
      >
        <div className="table-container tableLibrarySection">
          <Table
            id="resizable-table"
            striped
            className="m-0 custom-table"
            ref={tableRef1}
            bordered
          >
            <thead className="thead-sticky">
              <tr>
                <th onMouseDown1={(e) => onMouseDown1(e, 0)}>Attribute</th>
                <th onMouseDown1={(e) => onMouseDown1(e, 1)}>Value</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Subject Settings</td>
                <td className="text-capitalize">{subjectData?.subjectSettings}</td>
              </tr>
              <tr>
                <td>Prefix</td>
                <td>{subjectData?.subjectPrefix}</td>
              </tr>
              <tr>
                <td>Starting Number</td>
                <td>
                  {subjectData?.subjectStartingNumber}
                </td>
              </tr>
              <tr>
                <td>Suffix</td>
                <td>
                  {subjectData?.subjectPostfix}
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </SplitPane>
    </>
  );
};

export default StudySiteSubjectSettingsTable;
