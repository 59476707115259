import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import { NavLink, useParams } from "react-router-dom";
import { Form, Offcanvas, Table } from "react-bootstrap";
import { randomizerServices } from "../../../Services/IWRS/randomizerServices";

const RandomizedRequestButtonData = ({ Show, Hide, onMouseDown, tableRef, updatedData, findRandomizedData, randomizedId }) => {
  let { studyId } = useParams()
  let studyMode = JSON.parse(localStorage.getItem("StudyMode"));
  const [requestRemark, setRequestRemark] = useState("");
  const [requestData, setRequestData] = useState("");
  const [loader, setLoader] = useState(false);
  
  const requestForUnblined = async () => {
    setLoader(true)
    let userData = {}
    userData.studyId = studyId
    userData.studyMode = studyMode
    userData.requestType = "indivisual"
    userData.randomizedId = randomizedId
    userData.siteId = updatedData?.site
    userData.uniqueId = updatedData?.uniqueId
    userData.block = updatedData?.Block
    userData.subjectNumber = updatedData?.subjectNumber
    userData.randomizationNumber = updatedData?.randomizationNumber
    userData.requestRemark = requestRemark
    await randomizerServices.requestForUnblined(userData)
    setLoader(true)
    findRandomizedData()
    Hide()
  }

  const findSingleIndivisualRequestDetails = async (randomizedId, siteId, block, uniqueId) => {
    let data = await randomizerServices.findSingleIndivisualRequestDetails(studyId, randomizedId, siteId, block, uniqueId, studyMode)
    setRequestData(data?.data)
    setRequestRemark(data?.data?.requestRemark)
  }

  useEffect(() => {
    if (updatedData) {
      findSingleIndivisualRequestDetails(randomizedId, updatedData?.site, updatedData?.Block, updatedData?.uniqueId)
    }
    setRequestRemark("")
  }, [updatedData, randomizedId])
  return (
    <>
      <Offcanvas show={Show} onHide={Hide} style={{ width: 400 }} placement="start">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Request For Unblinding</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-0">
          <div className="overflow-auto"
            style={{ height: "calc(100vh - 89px)" }}
          >
            <div className="table-container tableLibrarySection">
              <Table
                id="resizable-table" striped
                className="m-0 custom-table"
                ref={tableRef}
                bordered
              >
                <thead className="thead-sticky">
                  <tr>
                    <th onMouseDown={(e) => onMouseDown(e, 0)}>
                      Attribute
                    </th>
                    <th onMouseDown={(e) => onMouseDown(e, 1)}>
                      Value
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Site Id</td>
                    <td>{updatedData?.site}</td>
                  </tr>
                  <tr>
                    <td>Block</td>
                    <td>{updatedData?.Block}</td>
                  </tr>
                  <tr>
                    <td>Subject Number</td>
                    <td>{updatedData?.subjectNumber}</td>
                  </tr>
                  <tr>
                    <td>Randomization Number</td>
                    <td>{updatedData?.randomizationNumber}</td>
                  </tr>
                  <tr>
                    <td>Randomizad By</td>
                    <td>{updatedData?.randomizedBy}</td>
                  </tr>
                  <tr>
                    <td>Randomizad Date | Time</td>
                    <td>
                      <Moment format="DD MMM YYYY | HH:mm" withTitle>
                        {updatedData?.randomizedDT}
                      </Moment>
                    </td>
                  </tr>
                  <tr>
                    <td>Status</td>
                    <td className={`${updatedData?.status === "blinding" ? "text-danger" : "text-success"} text-capitalize`}>{updatedData?.status}</td>
                  </tr>
                </tbody>
              </Table>
            </div>
            <div>
              <Form.Group className="p-2">
                <Form.Label style={{ fontWeight: 500 }} className="p-0">Remarks:</Form.Label>
                <Form.Control
                  as="textarea"
                  onChange={(e) => setRequestRemark(e.target.value)}
                  value={requestRemark}
                  rows={4}
                  disabled={requestData}
                  placeholder="Add Remarks"
                  style={{ marginTop: -7 }}
                />
              </Form.Group>
              {requestData &&
                <Form.Group className="mt-2 p-2">
                  <Form.Label style={{ fontWeight: 600 }} className="p-0 text-danger">Request Already sent.</Form.Label>
                </Form.Group>
              }
            </div>
          </div>
          {!requestData &&
            <div>
              <div className="p-2 hstack justify-content-end gap-2 position-sticky bottom-0">
                <button
                  onClick={requestForUnblined}
                  disabled={loader}
                  className="updateProfile"
                  style={{ width: 75, fontSize: "13px" }}
                >
                  Request
                </button>
              </div>
            </div>
          }
        </Offcanvas.Body>
      </Offcanvas>

    </>
  );
};

export default RandomizedRequestButtonData;
