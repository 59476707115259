import React, { useEffect, useState } from "react";
import { Form, Offcanvas, Table } from "react-bootstrap";
import { cdiscServices } from "../../../../../Services/CDISC/cdiscServices";

const ExportTableDataByColumn = ({
  Show,
  Hide,
  Title,
  tableRef,
  onMouseDown,
  crfData,
  columnList,
  setColumnList
}) => {
  const [checkboxValues, setCheckboxValues] = useState([]);
  const handleSelectAllCheckboxes = (e) => {
    const isChecked = e.target.checked;
    const updatedCheckboxValues = isChecked
      ? columnList.map((item) => item.name)
      : [];

    setCheckboxValues(updatedCheckboxValues);

    const updatedForms = columnList.map((item) => ({
      ...item,
      isChecked,
    }));

    setColumnList(updatedForms);
  };

  const handleCheckboxChange = (name, isChecked) => {
    const updatedCheckboxValues = isChecked
      ? [...checkboxValues, name]
      : checkboxValues.filter((item) => item !== name);

    setCheckboxValues(updatedCheckboxValues);

    const updatedForms = columnList.map((item) =>
      item.name === name ? { ...item, isChecked } : item
    );

    setColumnList(updatedForms);
  };

  let hideTableProperties = (checkboxValues?.length === 0 || checkboxValues?.length === undefined) ? [] : checkboxValues

  const exportDerivationDataFileMultiFormat = async () => {
    let userData = {}
    userData.crfData = crfData
    userData.totalColumn = checkboxValues
    await cdiscServices.exportDerivationDataFileMultiFormat(userData)
    Hide()
  }

  return (
    <>
      <Offcanvas show={Show} onHide={Hide} placement="end" style={{ width: 550 }}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{Title}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-0">
          <div
            className="overflow-auto"
            style={{ height: "calc(100vh - 89px)" }}
          >
            <Table
              ref={tableRef}
              id="resizable-table"
              className="text-nowrap custom-table"
              hover
              bordered
              striped
            >
              <thead className="thead-sticky z-1" style={{ top: -1 }}>
                <tr>
                  <th className="text-center" onMouseDown={(e) => onMouseDown(e, 1)} style={{ width: 40 }}>
                    <Form.Check
                      type="checkbox"
                      onChange={(e) => handleSelectAllCheckboxes(e)}
                      checked={
                        columnList?.length === 0 ? false : (hideTableProperties?.length === columnList?.length)
                      }
                    />
                  </th>
                  <th onMouseDown={(e) => onMouseDown(e, 2)}>Name</th>
                  <th onMouseDown={(e) => onMouseDown(e, 3)}>Label</th>
                  <th onMouseDown={(e) => onMouseDown(e, 4)}>Type</th>
                  <th onMouseDown={(e) => onMouseDown(e, 5)}>
                    Permission
                  </th>
                </tr>
              </thead>
              <tbody>
                {columnList?.map((item, index) => (
                  <tr
                    key={index}
                    className={`position-relative cursor-pointer`}
                  >
                    <td className="text-center">
                      <Form.Check type="checkbox"
                        name={item?.name}
                        id={item?.name}
                        checked={hideTableProperties?.includes(
                          item?.name
                        )}
                        onChange={(e) =>
                          handleCheckboxChange(
                            e.target.name,
                            e.target.checked
                          )
                        }
                      />
                    </td>
                    <td title={item.name}>{item?.name}</td>
                    <td title={item.label}>{item?.label}</td>
                    <td title={item.type} className="text-capitalize">{item?.type}</td>
                    <td title={item.check} className="text-capitalize">{item?.check}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          <div className="p-2 bg-light shadow-sm hstack justify-content-end gap-2">
            <button
              onClick={exportDerivationDataFileMultiFormat}
              className="border p-1 px-2 updateProfile"
            >
              Export
            </button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default ExportTableDataByColumn;
