import React, { useEffect, useRef, useState } from "react";
import { Table } from "react-bootstrap";
import Pagination from "../../../ETMF/Pagination/Pagination";
import { randomizerServices } from "../../../../Services/IWRS/randomizerServices";

const FactorTableData = ({ tableRef, onMouseDown, options, studyId, factorName, getAllSubjectPreDefineColumnOption, getStudySubjectColumnForFactor }) => {
  const ref = useRef(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [selectOption, setSelectOption] = useState();
  const [itemsPerPage, setItemsPerPage] = useState(15);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = options?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(options?.length / itemsPerPage);

  const findUpdateRandomizerFactorOrder = async () => {
    let data = await randomizerServices.findUpdateRandomizerFactorOrder(studyId)
    setSelectOption(data?.data)
  }

  const addUpdateRandomizerFactorOrder = async (randomize, option) => {
    let userData = {}
    userData.studyId = studyId
    userData.name = factorName
    userData.randomize = randomize === "yes" ? "no" : "yes"
    userData.option = option
    let data = await randomizerServices.addUpdateRandomizerFactorOrder(userData)
    if (data?.statusCode === 200) {
      getAllSubjectPreDefineColumnOption()
      getStudySubjectColumnForFactor()
      findUpdateRandomizerFactorOrder()
    }
  }
  useEffect(() => {
    findUpdateRandomizerFactorOrder();
  }, [studyId]);

  return (
    <>
      <Table
        ref={tableRef}
        id="resizable-table"
        striped
        className="custom-table"
        hover
      >
        <thead className="thead-sticky">
          <tr>
            <th onMouseDown={(e) => onMouseDown(e, 0)} style={{ width: 40 }}>
              Sr.
            </th>
            <th onMouseDown={(e) => onMouseDown(e, 1)}>Factor</th>
            <th onMouseDown={(e) => onMouseDown(e, 2)}>Randomize</th>
          </tr>
        </thead>
        <tbody ref={ref}>
          {result?.map((item, index, i) => (
            <>
              <tr key={index}>
                <td>{index + 1 + startIndex}</td>
                <td className="text-capitalize">{item.option}</td>
                <td className="text-capitalize">
                  <button
                    onClick={() => addUpdateRandomizerFactorOrder(selectOption?.find(data => ((data.name === factorName) && (data.option === item.option)))?.randomize, item.option)}
                    className={`text-white ${selectOption?.find(data => ((data.name === factorName) && (data.option === item.option)))?.randomize === "yes" ? "border-danger bg-danger" : "border-success bg-success"} border text-capitalize`}
                    style={{ fontSize: 10 }}
                  >
                    {selectOption?.find(data => ((data.name === factorName) && (data.option === item.option)))?.randomize === "yes" ? "Disable" : "Enable"}
                  </button>
                </td>
              </tr>
            </>
          ))}
        </tbody>
      </Table>
      <div className="position-sticky bottom-0">
        <Pagination
          totalPage={totalPage}
          pageNumber={pageNumber}
          itemsPerPage={itemsPerPage}
          totalItems={options?.length}
          setPageNumber={setPageNumber}
          setItemsPerPage={setItemsPerPage}
        />
      </div>
    </>
  );
};

export default FactorTableData;
