import React, { useEffect, useState } from "react";
import { Card, CardBody, CardFooter, CardHeader, Col, Form, Row } from "react-bootstrap";
import { studyOnboardingService } from "../../Services/studyServices/studyOnboarding";
import { supplimentaryServices } from "../../Services/CDISC/supplimentaryServices";
import { useParams } from "react-router";

const StudyConfiguration = () => {
  const [versionList, setVersionList] = useState([]);
  const [selectedVersion, setSelectedVersion] = useState("");
  const [selectedCRFData, setSelectedCRFData] = useState("");
  const [randomizerPrefix, setRandomizerPrefix] = useState("");
  const [randomizerSufix, setRandomizerSufix] = useState("");
  const [randomizerStart, setRandomizerStart] = useState("");
  const { studyId } = useParams()
  const configurationStudyID = async (type, value) => {
    let userData = {}
    if (type === "version") {
      userData.version = value
    }
    if (type === "crfType") {
      userData.crfType = value
    }
    if (type === "randomizerPrefix") {
      userData.randomizerPrefix = value
    }
    if (type === "randomizerSufix") {
      userData.randomizerSufix = value
    }
    if (type === "randomizerStart") {
      userData.randomizerStart = value
    }
    userData.studyId = studyId
    await studyOnboardingService.configurationStudyID(userData)
    getSingleStudies()
  }
  const getActiveVersions = async () => {
    let data = await supplimentaryServices.getActiveVersions()
    setVersionList(data?.data || [])
  }
  const getSingleStudies = async () => {
    let data = await studyOnboardingService.getSingleStudies(studyId)
    setSelectedVersion(data?.data?.[0]?.version || "")
    setSelectedCRFData(data?.data?.[0]?.crfType || "")
    setRandomizerPrefix(data?.data?.[0]?.randomizerPrefix || "")
    setRandomizerSufix(data?.data?.[0]?.randomizerSufix || "")
    setRandomizerStart(data?.data?.[0]?.randomizerStart || "")
  }

  useEffect(() => {
    getActiveVersions();
    getSingleStudies();
  }, []);
  return (
    <div className="p-3">
      <div style={{ fontSize: 14, color: "#103c5e", fontWeight: 500, marginTop: -7 }} className="mb-1">SDTM Settings</div>
      <div className="d-flex gap-3 w-100 m-auto">
        <Card className="w-100">
          <CardHeader className="fontSize12">Version Setting</CardHeader>
          <CardBody className="fontSize12">
            {versionList?.map((item, index) => (
              <Form.Check
                onChange={(e) => configurationStudyID("version", item._id)}
                key={index}
                type="radio"
                checked={selectedVersion === item._id}
                label={item?.version}
                name="version" />
            ))}
          </CardBody>
        </Card>
        <Card className="w-100">
          <CardHeader className="fontSize12">CRF Data Setting</CardHeader>
          <CardBody className="fontSize12">
            <Form.Check
              onChange={(e) => configurationStudyID("crfType", "custom")}
              type="radio"
              checked={selectedCRFData === "custom"}
              label="Custom"
              name="crf"
            />
            <Form.Check
              onChange={(e) => configurationStudyID("crfType", "predefined")}
              type="radio"
              checked={selectedCRFData === "predefined"}
              label="Predefined"
              name="crf" />
          </CardBody>
        </Card>
      </div>
      <div style={{ fontSize: 14, color: "#103c5e", fontWeight: 500, marginTop: 14 }} className="mb-0">IWRS Settings</div>
      <Row className="">
        <Form.Group className="mb-0" as={Col} md={4}>
          <Form.Label style={{ fontWeight: 500 }}>Prefix:</Form.Label>
          <Form.Control
            onChange={(e) => configurationStudyID("randomizerPrefix", e.target.value)}
            value={randomizerPrefix}
            type="text"
            placeholder="Add Prefix"
          />
        </Form.Group>
        <Form.Group className="mb-0" as={Col} md={4}>
          <Form.Label style={{ fontWeight: 500 }}>Suffix:</Form.Label>
          <Form.Control
            onChange={(e) => configurationStudyID("randomizerSufix", e.target.value)}
            value={randomizerSufix}
            type="text"
            placeholder="Add Suffix"
          />
        </Form.Group>
        <Form.Group className="mb-0" as={Col} md={4}>
          <Form.Label style={{ fontWeight: 500 }}>Starting Number:</Form.Label>
          <Form.Control
            onChange={(e) => configurationStudyID("randomizerStart", e.target.value)}
            value={randomizerStart}
            type="number"
            placeholder="Add Starting Number"
          />
        </Form.Group>
      </Row>
    </div>
  );
};

export default StudyConfiguration;
