import React from "react";
import { Table } from "react-bootstrap";
import Moment from "react-moment";
import SplitPane from "react-split-pane";

const PropertyTable = ({ crfProperties, onMouseDown1, tableRef1 }) => {

  return (
    <>
      <SplitPane split="vertical" minSize={20} defaultSize="100%">
        <div>
          <Table
           id="resizable-table"
           striped
           hover
           className="m-0 custom-table"
           ref={tableRef1}
          >
            <thead className="thead-sticky" style={{ top: -1 }}>
              <tr>
                <th style={{ width: "200px" }} onMouseDown1={(e) => onMouseDown1(e, 0)}>Attribute</th>
                <th onMouseDown1={(e) => onMouseDown1(e, 1)}>Value</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>Dataset Name</td>
                <td>{crfProperties?.datasetName}</td>
              </tr>
              <tr>
                <td>Dataset Label</td>
                <td>{crfProperties?.datasetLabel}</td>
              </tr>
              <tr>
                <td>Review Status</td>
                <td className="text-capitalize">{crfProperties?.reviewStatus === "notAssign" ? "Not Assign" : crfProperties?.reviewStatus}</td>
              </tr>
              <tr>
                <td>Data Entry Status</td>
                <td className="text-capitalize">{crfProperties?.dataEntrystatus}</td>
              </tr>
              <tr>
                <td>Query Status</td>
                <td className="text-capitalize">{crfProperties?.queryStatus}</td>
              </tr>
              <tr>
                <td>Query Date | Time</td>
                <td>
                  {crfProperties?.queryStatus === "open" ?
                    <Moment format="DD MMM YYYY | HH:mm:ss">
                      {crfProperties?.queryDateTime}
                    </Moment>
                    :
                    "_"
                  }
                </td>
              </tr>
              <tr>
                <td>Import Date | Time</td>
                <td>
                  <Moment format="DD MMM YYYY | HH:mm:ss">
                    {crfProperties?.createdAt}
                  </Moment>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </SplitPane>
    </>
  );
};

export default PropertyTable;
