import config from "../../config/config.json";
import saveAs from "file-saver";
import { headers } from "../../Helpers/Headers";
import {
  handleResponse,
  headersWithAuth,
  headersWithAuthWithoutBody,
} from "../../Helpers/Responses";

export const randomizerServices = {
  studyRandomizerAtOnce,
  studyRandomizerSingleRandomize,
  findRandomizedData,
  addUpdateRandomizerFactorOrder,
  findUpdateRandomizerFactorOrder,
  addRandomizerStudyParameter,
  findRandomizerStudyParameter,
  getRandomizerMetadataData,
  addRandomizerMetadata,
  updateRandomizerMetadataData,
  deleteRandomizerMetadataData,

  requestForUnblined,
  approveRequestedUnblinedTreatment,
  getRequestUnblinedHistory,
  findSingleIndivisualRequestDetails,
  getAllRequestUnblinedHistory,
  findSingleAllRequestDetails,
  getRequestUnblinedForApproval,
  getAllRequestUnblinedForApproval,
  exportRandomizedData,
  getRandomizerActivity,
  getRandomizerActivityByRandomized,
  getRandomizerActivityForNotification,
};

async function requestForUnblined(datas) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}requestForUnblined`,
      headersWithAuth("POST", datas, headers)
    );
    const data = await response.json();
    await handleResponse(data);
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function approveRequestedUnblinedTreatment(datas) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}approveRequestedUnblinedTreatment`,
      headersWithAuth("PUT", datas, headers)
    );
    const data = await response.json();
    await handleResponse(data);
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getRequestUnblinedHistory(studyId, randomizedId, studyMode) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getRequestUnblinedHistory/${studyId}/${randomizedId}/${studyMode}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getRandomizerActivity(studyId, studyMode) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getRandomizerActivity/${studyId}/${studyMode}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getRandomizerActivityByRandomized(studyId, studyMode, randomizedId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getRandomizerActivityByRandomized/${studyId}/${studyMode}/${randomizedId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getRandomizerActivityForNotification(studyId, studyMode) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getRandomizerActivityForNotification/${studyId}/${studyMode}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function findSingleIndivisualRequestDetails(studyId, randomizedId, siteId, block, uniqueId, studyMode) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}findSingleIndivisualRequestDetails/${studyId}/${randomizedId}/${siteId}/${block}/${uniqueId}/${studyMode}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getAllRequestUnblinedHistory(studyId, randomizedId, studyMode) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getAllRequestUnblinedHistory/${studyId}/${randomizedId}/${studyMode}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function findSingleAllRequestDetails(studyId, randomizedId, studyMode) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}findSingleAllRequestDetails/${studyId}/${randomizedId}/${studyMode}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getRequestUnblinedForApproval(studyId, studyMode) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getRequestUnblinedForApproval/${studyId}/${studyMode}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getAllRequestUnblinedForApproval(studyId, studyMode) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getAllRequestUnblinedForApproval/${studyId}/${studyMode}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function studyRandomizerAtOnce(datas) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}studyRandomizerAtOnce`,
      headersWithAuth("POST", datas, headers)
    );
    const data = await response.json();
    await handleResponse(data);
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function studyRandomizerSingleRandomize(datas) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}studyRandomizerSingleRandomize`,
      headersWithAuth("POST", datas, headers)
    );
    const data = await response.json();
    await handleResponse(data);
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function addUpdateRandomizerFactorOrder(datas) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}addUpdateRandomizerFactorOrder`,
      headersWithAuth("POST", datas, headers)
    );
    const data = await response.json();
    await handleResponse(data);
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function addRandomizerStudyParameter(datas) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}addRandomizerStudyParameter`,
      headersWithAuth("POST", datas, headers)
    );
    const data = await response.json();
    await handleResponse(data);
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function addRandomizerMetadata(datas) {
  try {
    const response = await fetch(
      `${config.API_URL_USER_ONBOARDING}addRandomizerMetadata`,
      headersWithAuth("POST", datas, headers)
    );
    const data = await response.json();
    await handleResponse(data);
    return data;
  } catch (error) {
    console.log(error);
  }
}

async function getRandomizerMetadataData(studyId, studyMode) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getRandomizerMetadataData/${studyId}/${studyMode}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function findRandomizedData(studyId, id, studyMode) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}findRandomizedData/${studyId}/${id}/${studyMode}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function findRandomizerStudyParameter(studyId, randomizeId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}findRandomizerStudyParameter/${studyId}/${randomizeId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function findUpdateRandomizerFactorOrder(studyId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}findUpdateRandomizerFactorOrder/${studyId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function updateRandomizerMetadataData(datas) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}updateRandomizerMetadataData`,
    headersWithAuth("PUT", datas, headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data
}

async function deleteRandomizerMetadataData(studyId, id) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}deleteRandomizerMetadataData/${studyId}/${id}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  await handleResponse(data);
  return data
}

async function exportRandomizedData(studyId, randomizedId, extension, studyMode) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}exportRandomizedData/${studyId}/${randomizedId}/${extension}/${studyMode}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  let url = config.BACKEND_URL + data?.data;
  saveAs(url, data?.data);
}