import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import Moment from "react-moment";
import SplitPane from "react-split-pane";
import { useParams } from "react-router";
import { GoSearch } from "react-icons/go";
import Pagination from "../../ETMF/Pagination/Pagination";
import { Collapse, Form, InputGroup, Spinner, Table } from "react-bootstrap";
import { rbqmStudyServices } from "../../../Services/rbqmServices/rbqmStudyServices";

const RiskManagement = ({ onMouseDown, tableRef }) => {
  let [id, setId] = useState("");
  let { studyId } = useParams("");
  let [loader, setLoader] = useState(false);
  let [openForm, setOpenForm] = useState("");
  let [riskArea, setRiskArea] = useState([]);
  let [threshold, setThreshold] = useState("");
  let [indicator, setIndicator] = useState("");
  let [indicators, setIndicators] = useState([]);
  let [riskAreaId, setRiskAreaId] = useState("");
  let [openFilter, setOpenFilter] = useState(false);
  let [riskCategory, setRiskCategory] = useState([]);
  let [indicatorData, setIndicatorData] = useState({});
  let [riskCategoryId, setRiskCategoryId] = useState("");
  let [riskManagementList, setRiskManagementList] = useState("");

  const selectIndicator = async (id) => {
    let checkData = riskArea?.find((item) => item?.areaId === id);
    setIndicators(checkData?.indicator || []);
  };

  const handleChange = (e, name) => {
    const { value } = e.target;
    setIndicatorData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const addNewRiskManagement = async () => {
    setLoader(true);
    let userData = {};
    userData.studyId = studyId;
    userData.categoryId = riskCategoryId;
    userData.areaId = riskAreaId;
    userData.indicators = indicatorData;
    let data = await rbqmStudyServices.addNewRiskManagement(userData);
    if (data?.statusCode === 200) {
      getRiskManagementList();
      clearForm();
    }
    setLoader(false);
  };

  const getRiskCategoryList = async () => {
    let data = await rbqmStudyServices.getRiskCategoryList(studyId);
    setRiskCategory(data?.data);
  };

  const getRiskAreaList = async () => {
    let data = await rbqmStudyServices.getRiskAreaList(studyId);
    setRiskArea(data?.data);
  };

  const getRiskManagementList = async () => {
    setLoader(true);
    let data = await rbqmStudyServices.getRiskManagementList(studyId);
    setRiskManagementList(data?.data);
    setLoader(false);
  };

  const handleUpdateRiskManagement = (e, item) => {
    setId(item?._id);
    setThreshold(item?.threshold);
    setIndicator(item?.indicator);
    setOpenForm(true);
  };

  const clearForm = () => {
    setRiskCategoryId("");
    setRiskAreaId("");
    setIndicatorData({});
    setId("");
  };

  const updateRiskManagement = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Update this Risk Category?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, update it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoader(true);
        let userData = {};
        userData.studyId = studyId;
        userData.id = id;
        userData.threshold = threshold;
        let data = await rbqmStudyServices.updateRiskManagement(userData);
        if (data?.statusCode === 200) {
          getRiskManagementList();
        }
        setLoader(false);
      }
    });
  };

  const deleteRiskManagement = async (e, id) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Delete this riskManagement?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = await rbqmStudyServices.deleteRiskManagement(studyId, id);
        if (data?.statusCode === 200) {
          getRiskManagementList();
        }
      }
    });
  };

  useEffect(() => {
    getRiskCategoryList();
    getRiskManagementList();
  }, [studyId]);

  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(15);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = riskManagementList?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(riskManagementList?.length / itemsPerPage);

  return (
    <>
      <div className="hstack justify-content-between border-bottom px-1">
        <div className="hstack gap-2 toolbar" style={{ padding: "5px" }}>
          <i
            title="Add"
            class="fa-solid fa-square-plus CP p-1 fontSize12"
            onClick={() => setOpenForm(!openForm)}
            style={{ borderRadius: 3 }}
          ></i>
          <div className="vr"></div>
          <i
            title="Add"
            class="fa-solid fa-filter CP p-1 fontSize12"
            onClick={() => setOpenFilter(!openFilter)}
            style={{ borderRadius: 3 }}
          ></i>
          <Collapse in={openFilter} dimension="width">
            <div>
              <InputGroup style={{ width: 200 }}>
                <Form.Control
                  type="search"
                  className="px-2 rounded-0"
                  placeholder="Search..."
                  style={{ fontSize: 10, padding: "0px" }}
                />
                <button
                  className="tabButtons border fs-13"
                  style={{
                    padding: "0px 5px",
                    borderRadius: "0 3px 3px 0",
                  }}
                >
                  <GoSearch />
                </button>
              </InputGroup>
            </div>
          </Collapse>
        </div>
      </div>
      <SplitPane
        split="horizontal"
        style={{ height: "calc(100vh - 185px)" }}
        className="position-relative"
        defaultSize={"100%"}
      >
        <SplitPane minSize={20} defaultSize={openForm ? "20%" : "100%"}>
          {openForm && (
            <div className="p-2">
              {id ? (
                <>
                  <Form.Group className="mb-2">
                    <div className="hstack justify-content-between">
                      <Form.Label className="mb-1 fw-bold">
                        Indicator <span className="text-danger">*</span>
                      </Form.Label>
                      <button
                        title="Add Mew Comment"
                        className="text-white border-success bg-success border-0 fs-10"
                        onClick={() => clearForm()}
                      >
                        <i className="fa-solid fa-square-plus"></i> Add New
                      </button>
                    </div>
                    <Form.Control
                      disabled
                      type="text"
                      placeholder="Enter Indicator Title"
                      value={indicator}
                      onChange={(e) => setIndicator(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-2">
                    <Form.Label className="mb-1 fw-bold">
                      Threshold <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Threshold Value"
                      value={threshold}
                      onChange={(e) => setThreshold(e.target.value)}
                    />
                  </Form.Group>
                </>
              ) : (
                <>
                  <Form.Group className="mb-2">
                    <Form.Label className="fw-bold mb-1">
                      Risk Categories <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Select
                      className="rounded-1"
                      disabled={id}
                      onChange={(e) => {
                        setRiskCategoryId(e.target.value);
                        getRiskAreaList(e.target.value);
                      }}
                      value={riskCategoryId}
                    >
                      <option value="">Select Risk Categories</option>
                      {riskCategory?.map((item, index) => (
                        <option
                          selected={item.riskCategoryId === item.categoryId}
                          key={index}
                          value={item.categoryId}
                        >
                          {item.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-2">
                    <Form.Label className="mb-1 fw-bold">
                      Risk Area <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Select
                      className="rounded-1"
                      disabled={id}
                      onChange={(e) => {
                        setRiskAreaId(e.target.value);
                        selectIndicator(e.target.value);
                      }}
                      value={riskAreaId}
                    >
                      <option value="">Select Risk Area</option>
                      {riskArea?.map((item, index) => (
                        <option
                          selected={item.riskAreaId === item.areaId}
                          key={index}
                          value={item.areaId}
                        >
                          {item.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-2">
                    {indicators.map((item, index) => (
                      <div className="mb-1" key={index}>
                        <Form.Label className="mb-1 fw-bold">
                          {item?.name} <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          required
                          type="text"
                          value={indicatorData[item?.name] || ""}
                          onChange={(e) => handleChange(e, item?.name)}
                          className="w-100"
                          placeholder="Enter Threshold"
                        />
                      </div>
                    ))}
                  </Form.Group>
                </>
              )}
              <div className="text-end">
                <button
                  className="submitbutton px-2 fontSize12"
                  onClick={() =>
                    id ? updateRiskManagement() : addNewRiskManagement()
                  }
                >
                  {loader ? (
                    <Spinner
                      animation="border"
                      style={{ width: 15, height: 15 }}
                      className="me-2"
                    />
                  ) : null}
                  {id ? "Update" : "Add"} Risk Management
                </button>
              </div>
            </div>
          )}
          {loader ? (
            <div className="hstack justify-content-center h-100">
              <Spinner />
            </div>
          ) : result?.length > 0 ? (
            <div>
              <Table
                id="resizable-table"
                striped
                hover
                className="m-0 custom-table"
                ref={tableRef}>
                <thead className="thead-sticky">
                  <tr>
                    <th style={{ width: 55 }} onMouseDown={(e) => onMouseDown(e, 0)}>S No.</th>
                    <th onMouseDown={(e) => onMouseDown(e, 1)}>Risk Category</th>
                    <th onMouseDown={(e) => onMouseDown(e, 2)}>Risk Area</th>
                    <th onMouseDown={(e) => onMouseDown(e, 3)}>Indicator</th>
                    <th onMouseDown={(e) => onMouseDown(e, 4)}>Threshold</th>
                    <th onMouseDown={(e) => onMouseDown(e, 5)}>Created Date | Time</th>
                    <th onMouseDown={(e) => onMouseDown(e, 6)}>Updated Date | Time</th>
                    <th style={{ width: 60 }} className="text-center" onMouseDown={(e) => onMouseDown(e, 7)}>
                      Update
                    </th>
                    <th style={{ width: 60 }} className="text-center" onMouseDown={(e) => onMouseDown(e, 8)}>
                      Delete
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {result?.length > 0 &&
                    result?.map((item, index) => (
                      <tr>
                        <td>{index + 1}</td>
                        <td>{item.category}</td>
                        <td>{item.area}</td>
                        <td>{item.indicator}</td>
                        <td>{item.threshold}</td>
                        <td>
                          <Moment format="DD MMM YYYY | HH:mm" withTitle>
                            {item.createdAt}
                          </Moment>
                        </td>
                        <td>
                          <Moment format="DD MMM YYYY | HH:mm" withTitle>
                            {item.updatedAt}
                          </Moment>
                        </td>
                        <td className=" text-center" title="Update Risk Area">
                          <button
                            className="text-white border-success bg-success border-0 fs-10"
                            onClick={(e) => handleUpdateRiskManagement(e, item)}
                          >
                            Update
                          </button>
                        </td>
                        <td className="text-center" title="Delete Risk Area">
                          <button
                            className="text-white border-danger bg-danger border-0 fs-10"
                            onClick={(e) => deleteRiskManagement(e, item._id)}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
              <div className="position-sticky bottom-0">
                <Pagination
                  totalPage={totalPage}
                  pageNumber={pageNumber}
                  itemsPerPage={itemsPerPage}
                  totalItems={riskManagementList?.length}
                  setPageNumber={setPageNumber}
                  setItemsPerPage={setItemsPerPage}
                />
              </div>
            </div>
          ) : (
            <div className="hstack justify-content-center h-100">
              <div className="noRecordFound">Risk Category List not found!</div>
            </div>
          )}
        </SplitPane>
      </SplitPane>
    </>
  );
};

export default RiskManagement;
