import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import UpdateProfile from "./UpdateProfile";
import UseToggle from "../../Hooks/UseToggle";
import ChangePassword from "./ChangePassword";
import TwoFactorAuth from "./TwoFactorAuth/TwoFactorAuth";
import { userServices } from "../../Services/userServices";
import { randomizerServices } from "../../Services/IWRS/randomizerServices";

const ProfileActions = ({ firstName, lastName, handleAddNewTab }) => {
  const { toggle, setToggle, toggle1, setToggle1, toggle2, setToggle2 } =
    UseToggle();

  const [authType, setAuthType] = useState("");
  const [authTypeData, setAuthTypeData] = useState("");
  const [status, setStatus] = useState(false);
  const [appTwoFA, setAppTwoFA] = useState(false);
  const [notification, setNotification] = useState("");
  const [emailTwoFA, setEmailTwoFA] = useState(false);

  const AppsRef = useRef(null);
  const NotificationRef = useRef(null);
  const toggleApps = () => setAppsActive(!AppsActive);
  const [AppsActive, setAppsActive] = useState("");

  const toggleNotification = () => setNotification(!notification);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (AppsRef.current && !AppsRef.current.contains(event.target)) {
        setAppsActive("");
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const getTwoFactorAuthType = async () => {
    let data = await userServices.getTwoFactorAuthType();
    setAuthType(data?.authType);
    setAuthTypeData(data?.authType);
    setStatus(data?.status);
    setAppTwoFA(data?.appTwoFA);
    setEmailTwoFA(data?.emailTwoFA);
  };

  const openTwoFactorAuth = () => {
    getTwoFactorAuthType();
    setToggle2();
  };

  let currentURL = window.location.href
  let splitCurrentURL = currentURL?.split("/")

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (AppsRef.current && !AppsRef.current.contains(event.target)) {
        setAppsActive("");
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (NotificationRef.current && !NotificationRef.current.contains(event.target)) {
        setNotification("");
      }
    };

    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  let { studyId } = useParams()

  let studyMode = JSON.parse(localStorage.getItem("StudyMode"));

  const [notifications, setNotifications] = useState([]);

  const getStudyUseQueryNotification = async () => {
    let data;
    if (splitCurrentURL[3] === "iwrs") {
      data = await randomizerServices.getRandomizerActivityForNotification(studyId, studyMode)
    } else {
      data = await userServices.getStudyUseQueryNotification(studyId, studyMode)
    }
    setNotifications(data?.data || [])
  }

  useEffect(() => {
    getTwoFactorAuthType();
    getStudyUseQueryNotification();
  }, []);

  return (
    <>
      <div
        ref={NotificationRef}
        className={`Apps position-relative ${notification ? "active" : ""}`}
      >
        <div className="icon_wrap hstack gap-1 py-1" onClick={toggleNotification} style={{ marginRight: 5 }}>
          <div
            className={`title_name hstack gap-1 px-2 py-1 ${notification ? "AppsNameActive" : ""
              }`}
            style={{ cursor: "pointer" }}
          >
            <div className="hstack gap-1">
              <i className="fa-solid fa-bell me-1 text-info position-relative" ></i>
              {notifications?.length > 0 &&
                <i class="fa-solid fa-circle position-absolute" style={{ fontSize: 6, top: 5, left: 17 }}></i>
              }
            </div>
          </div>
        </div>
        {notifications?.length > 0 &&
          <div className="Apps_dd shadow">
            <ul className="Apps_ul notificationRef" style={{ color: "black", fontSize: 13, cursor: "none", width: 300, height: 273, overflowY: "auto" }}>
              <div className="hstack justify-content-between h-100" style={{ flexDirection: "column" }}>
                <div>
                  {notifications?.slice(0, 3).map((item, index) => (
                    <li key={index}>
                      <a ><i className="fa-solid fa-circle" style={{ fontSize: 5, marginRight: 5 }}></i>{item?.activities}</a>
                    </li>
                  ))}
                </div>
                {/* {splitCurrentURL[3] !== "iwrs" &&
                  <a className="w-100" style={{
                    display: "block", textAlign: "center", padding: "4px 0 10px", fontWeight: 500,
                    background: "#f1f1f1", borderRadius: "4px", bottom: 0, textDecoration: "none", color: "#103c5e"
                  }}
                    onClick={() => {
                      handleAddNewTab(
                        "Query Notification",
                        "fa-solid fa-file-circle-question",
                        "notificationData"
                      );
                    }}
                  >
                    View All
                  </a>
                } */}
                <a
                  className="w-100"
                  style={{
                    display: "block",
                    textAlign: "center",
                    padding: "4px 0 10px",
                    fontWeight: 500,
                    background: "#f1f1f1",
                    borderRadius: "4px",
                    bottom: 0,
                    textDecoration: "none",
                    color: "#103c5e",
                  }}
                  onClick={() => {
                    handleAddNewTab(
                      "Notification",
                      "fa-solid fa-file-circle-question",
                      splitCurrentURL[3] === "iwrs" ? "iWRSNotification" : "notificationData"
                    );
                  }}
                >
                  View All
                </a>
                {/* 
                {
                  splitCurrentURL[3] === "iwrs" ? (
                    <a className="w-100"
                      style={{
                        display: "block", textAlign: "center", padding: "4px 0 10px", fontWeight: 500,
                        background: "#f1f1f1", borderRadius: "4px", bottom: 0, textDecoration: "none", color: "#103c5e",
                      }}
                      onClick={() => {
                        handleAddNewTab(
                          "Query Notification",
                          "fa-solid fa-file-circle-question",
                          "iWRSNotification"
                        );
                      }}
                    >
                      View All
                    </a>
                  ) : splitCurrentURL[3] === "studyId" ? (
                    <a className="w-100"
                      style={{
                        display: "block", textAlign: "center", padding: "4px 0 10px",
                        fontWeight: 500, background: "#f1f1f1", borderRadius: "4px", bottom: 0,
                        textDecoration: "none", color: "#103c5e",
                      }}
                      onClick={() => {
                        handleAddNewTab(
                          "Query Notification",
                          "fa-solid fa-file-circle-question",
                          "notificationData"
                        );
                      }}
                    >
                      View All
                    </a>
                  ) : null
                } */}

              </div>
            </ul>
          </div>

        }
      </div>

      <div
        ref={AppsRef}
        className={`Apps position-relative ${AppsActive ? "active" : ""}`}
      >
        <div className="icon_wrap hstack gap-1 py-1" onClick={toggleApps} style={{ marginRight: 5 }}>
          <div
            className={`title_name hstack gap-1 px-2 py-1 ${AppsActive ? "AppsNameActive" : ""
              }`}
            style={{ cursor: "pointer" }}
          >
            <div className="hstack gap-1">
              <i className="fa-solid fa-user-tie me-1 text-info"></i>
              <p
                className="m-0"
                style={{ fontSize: 13 }}
              >{`${firstName} ${lastName}`}</p>
              <i class={`fa-solid fa-angle-${AppsActive ? "up" : "down"}`}></i>
            </div>
          </div>
        </div>
        <div className="Apps_dd shadow">
          <ul className="Apps_ul">
            <li>
              <a
                onClick={setToggle}
                className="dropdown-item"
                style={{ fontSize: 12, cursor: "pointer" }}
              >
                <i className="fa-solid fa-address-card me-2"></i>Profile
              </a>
            </li>
            <li>
              <a
                onClick={setToggle1}
                className="dropdown-item"
                style={{ fontSize: 12, cursor: "pointer" }}
              >
                <i className="fa-solid fa-unlock-keyhole me-2"></i> Change
                Password
              </a>
            </li>
            <li>
              {authTypeData && (
                <a
                  onClick={openTwoFactorAuth}
                  className="dropdown-item"
                  style={{ fontSize: 12, cursor: "pointer" }}
                >
                  <i className="fa-solid fa-shield-halved me-2"></i>Two Factor
                  Auth
                </a>
              )}
            </li>
          </ul>
        </div>
      </div>

      <UpdateProfile SHOW={!toggle} HIDE={setToggle} TITLE={"User Profile"} />

      <ChangePassword
        SHOW={!toggle1}
        HIDE={setToggle1}
        TITLE={"Change Password"}
      />

      <TwoFactorAuth
        SHOW={!toggle2}
        HIDE={setToggle2}
        TITLE={"Two Factor Auth"}
        getTwoFactorAuthType={getTwoFactorAuthType}
        setAuthType={setAuthType}
        authType={authType}
        authTypeData={authTypeData}
        status={status}
        appTwoFA={appTwoFA}
        emailTwoFA={emailTwoFA}
      />
    </>
  );
};

export default ProfileActions;
