import React, { useEffect, useRef } from "react";
import { Table } from "react-bootstrap";
import DynamicRuler from "./DynamicRuler";
import CdrgData from "../../../CDRG/CdrgData";
import CDRGPdfDataDetails from "../../CDRGPdfDataDetails";
import "../../../../../FormReport/CommonDesign/print.css";

const EditorWritingPage = ({
  size,
  margin,
  showFooter,
  showHeader,
  orientation,
  isFullScreen,
  componentRef,
  imageToInsert,
  emojiToInsert,
  // setTocData, tocData,
  handleLinePositionChange,
}) => {

  // const pageRefs = useRef([]);
  const editorRef = useRef(null);
  const editorStyle = { width: "100%" };

  const marginStyle = {
    paddingTop: margin.top,
    paddingBottom: margin.bottom,
    paddingLeft: margin.left,
    paddingRight: margin.right,
  };

  const pageStyle = {
    width: orientation === "portrait" ? size.width : size.height,
    height: orientation === "portrait" ? size.height : size.width,
    marginBottom: margin.bottom,
    marginLeft: "auto",
    marginRight: "auto",
    position: "relative",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
  };

  const contentStyle = {
    ...marginStyle,
    flexGrow: 1,
  };

  const insertImageAtCaret = (imageSrc) => {
    const img = document.createElement("img");
    img.src = imageSrc;
    img.style.maxWidth = "100%";
    img.style.maxHeight = "100%";

    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      range.insertNode(img);
      range.collapse(false);
    }
  };

  useEffect(() => {
    if (imageToInsert) {
      insertImageAtCaret(imageToInsert);
    }
  }, [imageToInsert]);

  const insertEmojiAtCaret = (emoji) => {
    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      const emojiNode = document.createTextNode(emoji);
      range.insertNode(emojiNode);
      range.collapse(false);
    }
  };

  useEffect(() => {
    if (emojiToInsert) {
      insertEmojiAtCaret(emojiToInsert);
    }
  }, [emojiToInsert]);

  // useEffect(() => {
  //   if (pageRefs.current[0]) {
  //     pageRefs.current[0].focus();
  //   }
  // }, []);

  // const rebuildTOC = () => {
  //   const newToc = [];
  //   const counters = [0, 0, 0, 0, 0, 0];

  //   editorRef.current.querySelectorAll('h1, h2, h3, h4, h5, h6').forEach((heading) => {
  //     const level = parseInt(heading.tagName.replace('H', ''), 10) - 1;

  //     for (let i = level + 1; i < counters.length; i++) {
  //       counters[i] = 0;
  //     }
  //     counters[level]++;
  //     const numbering = counters.slice(0, level + 1).join('.');
  //     const pageIndex = [...pageRefs.current].findIndex((page) => {
  //       const rect = heading.getBoundingClientRect();
  //       const pageRect = page.getBoundingClientRect();
  //       return rect.top >= pageRect.top && rect.bottom <= pageRect.bottom;
  //     });
  //     newToc.push({
  //       text: `${numbering} ${heading.textContent}`,
  //       level: level + 1,
  //       id: heading.id,
  //       page: pageIndex + 1,
  //     });
  //   });
  //   setTocData(newToc);
  // };

  // useEffect(() => {
  //   const handleEditorInput = () => rebuildTOC();
  //   editorRef.current.addEventListener('input', handleEditorInput);
  //   return () => editorRef.current.removeEventListener('input', handleEditorInput);
  // }, []);

  return (
    <>
      <DynamicRuler
        orientation={orientation}
        size={size}
        onLinePositionChange={handleLinePositionChange}
      />
      <div
        style={{
          height: isFullScreen ? "calc(100vh - 130px)" : "calc(100vh - 285px)",
          overflow: "auto",
        }}
      >
        <div className="text-editor text-editor-container" ref={editorRef}>
          <div
            contentEditable={true}
            style={{
              border: "1px solid #eee",
              borderRadius: 3,
              outline: "none",
              minHeight: "11in",
              ...editorStyle,
              ...pageStyle,
            }}
            suppressContentEditableWarning={true}
          >
            <div ref={componentRef}>
              {showHeader && (
                <div
                  className="singleFormHeader w-100"
                  style={{
                    ...contentStyle,
                    borderBottom: "1px dashed #eee",
                  }}
                  contentEditable={true}
                >
                  <div className="d-flex justify-content-between">
                    <p className="m-0 fw-bold">Study 039-24</p>
                    <p className="m-0 fw-bold">
                      Clinical Study Data Reviewer’s Guide
                    </p>
                  </div>
                </div>
              )}
              <div style={{ ...contentStyle }}>
                <Table>
                  {showHeader && (
                    <thead>
                      <tr className="spacingHeader">
                        <th className="border-0">
                          <div className="t-head">&nbsp;</div>
                          <div className="t-head">&nbsp;</div>
                          <div className="t-head">&nbsp;</div>
                        </th>
                      </tr>
                    </thead>
                  )}
                  <tbody>
                    <tr>
                      <td className="border-0 p-0">
                        <div className="content">
                          <div className="page">
                            <CdrgData />
                          </div>
                          <div className="pages">
                            <CDRGPdfDataDetails />
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  {showFooter && (
                    <tfoot>
                      <tr className="spacingHeader">
                        <td className="border-0 p-0">
                          <div className="t-foot">&nbsp;</div>
                          <div className="t-foot">&nbsp;</div>
                          <div className="t-foot">&nbsp;</div>
                          <div className="t-foot">&nbsp;</div>
                        </td>
                      </tr>
                    </tfoot>
                  )}
                </Table>
                {/* {tocData.length > 0 && (
                  <div className="table-of-contents">
                    <div className='text-center' style={{ fontWeight: 500, fontSize: 16 }}>Table of Contents</div>
                    <ul>
                      {tocData?.map((item, index) => (
                        <li
                          key={index}
                          style={{
                            marginLeft: `${(item.level - 1) * 20}px`,
                            listStyleType: item.level === 1 ? 'disc' : 'circle',
                            display: 'flex',
                            alignItems: 'center',
                          }}>
                          <a href={`#${item.id}`}
                            style={{
                              textDecoration: 'none', flex: 1, display: 'flex', alignItems: 'center',
                            }}>
                            <span style={{ flex: 'none' }}>
                              {item.text}
                            </span>
                            <span style={{ flex: 1, borderBottom: '2px dotted', margin: '0 5px' }}></span>
                          </a>
                          <span style={{ marginLeft: '5px', whiteSpace: 'nowrap' }}>
                            {item.page}
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                )} */}
              </div>
              {showFooter && (
                <div
                  className="footer"
                  style={{
                    ...contentStyle,
                    borderTop: "1px dashed #eee",
                  }}
                >
                  <div className="hstack justify-content-between">
                    <div>
                      <p className="m-0 fontSize12" contentEditable={true}>
                        This is an electronically authenticated report generated
                        by Galaaxx
                      </p>
                      <p className="m-0 fontSize12" contentEditable={true}>
                        Confidencials
                      </p>
                    </div>
                    <p className="m-0 fontSize12" style={{ pointerEvents: "none" }}>
                      Page : 1 of 1
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditorWritingPage;
