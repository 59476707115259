import React, { useEffect, useRef, useState } from "react";
import StudySiteAttributeTable from "./StudySiteAttributeTable";
import StudySiteAuditLogs from "./StudySiteAuditLogs";
import UpdatedFieldHistory from "./UpdatedFieldHistory";
import StudySiteQueryListComponent from "./QueryManagement/StudySiteQueryListComponent";
import SiteSubjectList from "./SiteSubjectList";
import SiteReviewHistory from "./SiteReviewHistory";
import StudySiteReport from "./QueryManagement/StudySiteReport";
import StudySiteSubjectSettingsTable from "./StudySiteSubjectSettingsTable";
const SiteTabProperties = ({
  siteUniqueId,
  subjectUniqueId,
  tableRef,
  onMouseDown,
  setIsSectionVisible,
  getStudySiteRowData,
  handleAddNewTab,
  selectedStudySiteId,
  studySubjectState,
  siteIdData,
  StudyMode,
  viewTypeData,
  subjectData,
}) => {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [activeTabs, setActiveTabs] = useState(subjectData?.subjectSettings === "manual" ? "properties" : "subject Settings");
  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  let finalTabArray = subjectData?.subjectSettings === "manual" ? (viewTypeData === "edc" ?
    [
      "properties",
      "history",
      "subject",
      "report",
      "activities",
    ] : [
      "properties",
      "review History",
      "query",
    ]) : [
    "subject Settings",
    "properties",
    "review History",
    "query",
  ]

  const [resizingColumn, setResizingColumn] = useState(null);
      const startX = useRef(0);
      const startWidth = useRef(0);
      const tableRef1 = useRef(null);
    
      useEffect(() => {
        const onMouseMove = (e) => {
          if (resizingColumn !== null) {
            const width = startWidth.current + (e.pageX - startX.current);
            const table = tableRef1.current;
    
            if (!table) {
              console.error("Table reference is null.");
              return;
            }
    
            const th = table.querySelector(`th:nth-child(${resizingColumn + 1})`);
    
            if (!th) {
              console.error(
                `No th element found for column index: ${resizingColumn}`
              );
              return;
            }
    
            th.style.width = `${width}px`;
            table.classList.add("resizing");
          }
        };
    
        const onMouseUp = () => {
          setResizingColumn(null);
          document.removeEventListener("mousemove", onMouseMove);
          document.removeEventListener("mouseup", onMouseUp);
        };
    
        if (resizingColumn !== null) {
          document.addEventListener("mousemove", onMouseMove);
          document.addEventListener("mouseup", onMouseUp);
        }
    
        return () => {
          document.removeEventListener("mousemove", onMouseMove);
          document.removeEventListener("mouseup", onMouseUp);
        };
      }, [resizingColumn]);
    
      const onMouseDown1 = (e, columnIndex) => {
        setResizingColumn(columnIndex);
        startX.current = e.pageX;
        startWidth.current = e.target.offsetWidth;
        document.body.style.userSelect = "none";
        // tableRef1?.current?.querySelectorAll("th")?.forEach((column, index) => {
        //   if (index !== columnIndex) {
        //     column.style.width = `${column.offsetWidth}px`;
        //   }
        // });
      };

  return (
    <>
      <div
        className={`pt-1 border-bottom bg-light ${isFullScreen ? "full-screen-component" : ""
          }`}
      >
        <div className="hstack justify-content-between">
          <div className="hstack me-1 gap-1">
            {finalTabArray?.map((tab) => (
              <button
                key={tab}
                className={`buttonForTabs ${activeTabs === tab ? "activee" : ""
                  }`}
                onClick={() => setActiveTabs(tab)}
                title={tab.charAt(0).toUpperCase() + tab.slice(1)}
              >
                {tab.charAt(0).toUpperCase() + tab.slice(1)}
              </button>
            ))}
          </div>
          <div className="d-flex gap-1">
            <div className="toolbar">
              <i
                className="fa-solid fa-angle-down"
                onClick={() => setIsSectionVisible(false)}
              >
              </i>
            </div>
            <div className="toolbar">
              <i
                className={`fa-solid me-2 ${!isFullScreen ? "fa-maximize" : "fa-minimize"
                  }`}
                title={!isFullScreen ? "Full Mode" : "Original Mode"}
                onClick={toggleFullScreen}
              ></i>
            </div>
          </div>
        </div>
        {activeTabs === "subject Settings" ? (
          <StudySiteSubjectSettingsTable
            subjectData={subjectData}
            tableRef1={tableRef1}
            onMouseDown1={onMouseDown1}
          />
        ) : activeTabs === "properties" ? (
          <StudySiteAttributeTable
            siteUniqueId={siteUniqueId}
            tableRef1={tableRef1}
            onMouseDown1={onMouseDown1}
          />
        ) : activeTabs === "history" ? (
          <UpdatedFieldHistory
            siteUniqueId={siteUniqueId}
            tableRef1={tableRef1}
            onMouseDown1={onMouseDown1}
          />
        ) : activeTabs === "review History" ? (
          <SiteReviewHistory tableRef1={tableRef1} onMouseDown1={onMouseDown1} studyUniqueId={siteUniqueId} />
        ) : activeTabs === "subject" ? (
          <SiteSubjectList
            subjectUniqueId={subjectUniqueId}
            handleAddNewTab={handleAddNewTab}
            studySubjectState={studySubjectState}
            selectedStudySiteId={selectedStudySiteId}
            siteIdData={siteIdData}
          />
        ) : activeTabs === "report" ? (
          <StudySiteReport studyUniqueId={siteUniqueId} />
        ) : activeTabs === "query" ? (
          <StudySiteQueryListComponent
            tableRef1={tableRef1}
            onMouseDown1={onMouseDown1}
            siteUniqueId={siteUniqueId}
            isFullScreen={isFullScreen}
            getStudySiteRowData={getStudySiteRowData}
          />
        ) : activeTabs === "activities" ? (
          <StudySiteAuditLogs
            tableRef1={tableRef1}
            onMouseDown1={onMouseDown1}
            siteUniqueId={siteUniqueId}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default SiteTabProperties;
