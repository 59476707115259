import React, { useEffect, useState } from "react";
import { Collapse, Form, InputGroup } from "react-bootstrap";
import SplitPane from "react-split-pane";
import WhereClausesTableData from "./WhereClausesTableData";
import WhereClausesTableProperties from "./WhereClausesTableProperties";
import UseToggle from "../../../../../Hooks/UseToggle";
import { GoSearch } from "react-icons/go";
import { cdiscServices } from "../../../../../Services/CDISC/cdiscServices";
import { useParams } from "react-router";
import { NoDataFoundTable, SpinerDataLoader } from "../../../../NoDataFoundTable/NoDataFoundTable";

const WhereClauses = ({ onMouseDown, tableRef }) => {
  const [loader, setLoader] = useState(false);
  const { toggle, setToggle } = UseToggle();
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [whereClauseData, setWhereClauseData] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);
  const { studyId } = useParams();

  const getWhereClauseData = async () => {
    setLoader(true);
    let data = await cdiscServices.getWhereClauseData(studyId);
    setWhereClauseData(data?.data || []);
    setLoader(false);
  };

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  useEffect(() => {
    getWhereClauseData();
  }, []);

  return (
    <div className={`code-list-container ${isFullScreen ? "full-screen" : ""}`}>
      <div className="p-1 hstack justify-content-between border-bottom toolbar">
        <div className="hstack gap-2">
          <i
            title="Table Properties"
            className="fa-solid fa-list CP"
            onClick={setToggle}
          />
          <div className="vr"></div>
          <i
            className="fa-solid fa-magnifying-glass"
            title="Search"
            onClick={() => setOpenFilter(!openFilter)}
          ></i>
          <Collapse in={openFilter} dimension="width">
            <div>
              <InputGroup style={{ width: 200 }}>
                <Form.Control
                  type="search"
                  className="px-2"
                  placeholder="Search..."
                  style={{ fontSize: 10, borderRadius: "0", padding: 0 }}
                />
                <InputGroup.Text
                  id="basic-addon2"
                  style={{ padding: "1px 5px" }}
                >
                  <GoSearch />
                </InputGroup.Text>
              </InputGroup>
            </div>
          </Collapse>
        </div>
        {isFullScreen ? (
          <i class="fa-solid fa-maximize" title="Original Mode" onClick={toggleFullScreen}></i>
        ) : (
          <i class="fa-solid fa-minimize" title="Full Mode" onClick={toggleFullScreen}></i>
        )}
      </div>
      <div className="position-relative">
        <SplitPane
          split="horizontal"
          style={{
            height: isFullScreen ? "calc(100vh - 35px)" : "calc(100vh - 148px)",
          }}
          defaultSize={"100%"}
        >
          <SplitPane split="vertical" minSize={20} defaultSize={"100%"}>
            <>
              {loader ? (
                <SpinerDataLoader />
              ) : whereClauseData?.length === 0 ? (
                <NoDataFoundTable MSG={" Data Not Found!"} />
              ) : (
                <WhereClausesTableData
                  studyId={studyId}
                  whereClauseData={whereClauseData}
                  onMouseDown={onMouseDown}
                  tableRef={tableRef}
                  getWhereClauseData={getWhereClauseData}
                />
              )}
            </>
          </SplitPane>
        </SplitPane>
      </div>
      <WhereClausesTableProperties
        Show={!toggle}
        Hide={setToggle}
        Title={"Table Properties"}
      />
    </div>
  );
};

export default WhereClauses;
