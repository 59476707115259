import React, { useEffect, useState } from "react";
import {
  FaCircleCheck,
  FaCircleXmark,
} from "react-icons/fa6";
import Moment from "react-moment";
import { useParams } from "react-router";
import { Table } from "react-bootstrap";
import Pagination from "../../../../../../ETMF/Pagination/Pagination";
import {
  NoDataFoundTable,
  SpinerDataLoader,
} from "../../../../../../NoDataFoundTable/NoDataFoundTable";
import { usersOnboardingService } from "../../../../../../../Services/usersOnboarding";
import SplitPane from "react-split-pane";

const StudySubjectAuditLogs = ({ onMouseDown, tableRef, subjectUniqueId }) => {
  const { studyId } = useParams();
  const [recentActivity, setRecentActivity] = useState([]);
  const [tableLoader, setTableLoader] = useState(false);
  let studyMode = JSON.parse(localStorage.getItem("StudyMode"));

  const getStudyActivitiesByModule = async () => {
    setTableLoader(true);
    let data = await usersOnboardingService.getStudyActivitiesByModule(
      subjectUniqueId,
      studyId,
      "studySubject",
      studyMode
    );
    setRecentActivity(data?.data || []);
    setTableLoader(false);
  };

  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(15);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = recentActivity?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(recentActivity?.length / itemsPerPage);

  useEffect(() => {
    getStudyActivitiesByModule();
  }, [subjectUniqueId]);
  return (
    <>
      <SplitPane split="vertical" minSize={20} defaultSize={"100%"}>
        {tableLoader ? (
          <SpinerDataLoader />
        ) : recentActivity?.length === 0 ? (
          <NoDataFoundTable MSG="Activity not found" />
        ) : (
          <>
            <Table
              id="resizable-table"
              striped
              className="custom-table"
              style={{ tableLayout: "auto" }}
              hover
              ref={tableRef}
            >
              <thead className="position-sticky" style={{ top: -1 }}>
                <tr>
                  <th
                    onMouseDown={(e) => onMouseDown(e, 0)}
                    style={{ width: 40 }}
                  >
                    Sr.
                  </th>
                  <th
                    className="text-center"
                    onMouseDown={(e) => onMouseDown(e, 1)}
                  >
                    Status
                  </th>
                  <th
                    onMouseDown={(e) => onMouseDown(e, 2)}
                  >
                    Type
                  </th>
                  <th onMouseDown={(e) => onMouseDown(e, 3)}>Activity By</th>
                  <th onMouseDown={(e) => onMouseDown(e, 4)}>Activities</th>
                  <th onMouseDown={(e) => onMouseDown(e, 5)}>Category</th>
                  <th onMouseDown={(e) => onMouseDown(e, 6)}>Activity Date</th>
                  <th onMouseDown={(e) => onMouseDown(e, 7)}>Activity Time</th>
                </tr>
              </thead>
              <tbody>
                {result?.map((item, index) => (
                  <tr className="relative">
                    <td>{index + startIndex + 1}.</td>
                    <td
                      title={item.status === "200" ? "Success" : "Failed"}
                      className="text-center"
                    >
                      {item.status === "200" ? (
                        <FaCircleCheck className="text-success" />
                      ) : (
                        <FaCircleXmark className="text-danger" />
                      )}
                    </td>
                    <td>
                      {item.type}
                    </td>
                    <td title={item.activityBy}>{item.activityByName}</td>
                    <td>{item.activities}</td>
                    <td className="text-capitalize">
                      {item.module === "workflowTask"
                        ? "Workflow Task"
                        : item.module}
                    </td>
                    <td>
                      <Moment format="DD MMM YYYY">{item?.createdAt}</Moment>
                    </td>
                    <td>
                      <Moment format="HH:mm:ss">{item?.createdAt}</Moment>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div className="position-sticky" style={{ bottom: 24 }}>
              <Pagination
                itemsPerPage={itemsPerPage}
                setItemsPerPage={setItemsPerPage}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
                totalPage={totalPage}
                totalItems={recentActivity?.length}
              />
            </div>
          </>
        )}
      </SplitPane>
    </>
  );
};

export default StudySubjectAuditLogs;
