import React from "react";
import { Alert, Form, Spinner } from "react-bootstrap";

const AddUpdateSiteRows = ({
  siteId,
  loading,
  setSiteId,
  statusCode,
  handleSubmit2,
  studyUniqueId,
  customMessage,
  handleSubmit1,
  subjectLimit,
  setSubjectLimit,
  subjectSettings,
  setSubjectSettings,
  subjectPrefix,
  setSubjectPrefix,
  subjectStartingNumber,
  setSubjectStartingNumber,
  subjectPostfix,
  setSubjectPostfix,
  addHandleChange,
  updateHandleChange,
  UpdateHistoryOnChange,
  studyDynamicColumnData,
  studyAddDynamicColumnData,
}) => {
  let checkAddDunamicValue = studyAddDynamicColumnData.map((item) =>
    item.valueData ? true : false
  );
  let checkAddFilledData = checkAddDunamicValue?.filter((item) => item === true);
  let dynamicAddConditionData =
    studyAddDynamicColumnData?.length === checkAddFilledData?.length;

  let checkUpdateDunamicValue = studyDynamicColumnData.map((item) =>
    item.valueData ? true : false
  );
  let checkUpdateFilledData = checkUpdateDunamicValue?.filter((item) => item === true);
  let dynamicUpdateConditionData =
    studyDynamicColumnData?.length === checkUpdateFilledData?.length;
  return (
    <div className="p-2">
      <Form.Group className="mb-2">
        <div className="hstack justify-content-between">
          <Form.Label className="fw-bold mb-1">
            Site Id <span className="text-danger">*</span>
          </Form.Label>
        </div>
        <Form.Control
          type="text"
          className="inputboxborder"
          placeholder="Enter Column Label"
          value={siteId}
          onChange={(e) => setSiteId(e.target.value)}
        />
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Label className="fw-bold mb-1">
          No. of Subject <span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          type="number"
          className="inputboxborder"
          placeholder="Enter No of Subject"
          value={subjectLimit}
          onChange={(e) => setSubjectLimit(e.target.value)}
        />
      </Form.Group>
      {studyUniqueId
        ? studyDynamicColumnData?.length > 0 &&
        studyDynamicColumnData?.map(
          ({ name, types, valueData, history }, i) => (
            <>
              <div key={i}>
                <Form.Group className="mb-2">
                  <Form.Label className="fw-bold mb-1">
                    {name} <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    key={i}
                    type={types}
                    className="inputboxborder"
                    name="valueData"
                    value={valueData ? valueData : ""}
                    onChange={(e) => updateHandleChange(e, i)}
                    placeholder={`Enter ${name}`}
                    required
                  />
                </Form.Group>
              </div>
              <div key={i}>
                <Form.Group className="mb-2">
                  <Form.Label className="fw-bold mb-1">Comment</Form.Label>
                  <input
                    key={i}
                    type={"text"}
                    className="form-control inputboxborder mb-3 w-100"
                    name="history"
                    value={history}
                    onChange={(e) => UpdateHistoryOnChange(e, i)}
                    placeholder={"Enter Comment"}
                    required
                  />
                </Form.Group>
              </div>
            </>
          )
        )
        : studyAddDynamicColumnData?.length > 0 &&
        studyAddDynamicColumnData?.map(({ name, types, valueData }, i) => (
          <div key={i}>
            <Form.Group className="mb-2">
              <Form.Label className="fw-bold mb-1">
                {name} <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                key={i}
                type={types}
                className="inputboxborder"
                name="valueData"
                value={valueData ? valueData : ""}
                onChange={(e) => addHandleChange(e, i)}
                placeholder={`Enter ${name}`}
                required
              />
            </Form.Group>
          </div>
        ))}
      <Form.Group className="mb-2">
        <Form.Label className="fw-bold mb-1">
          Subject Settings <span className="text-danger">*</span>
        </Form.Label>
        <Form.Select onChange={(e) => setSubjectSettings(e.target.value)}>
          <option>Select Subject Setytings</option>
          <option selected={subjectSettings === "auto"} value={"auto"}>Auto</option>
          <option selected={subjectSettings === "manual"} value={"manual"}>Manual</option>
          <option selected={subjectSettings === "iwrs"} value={"iwrs"}>IWRS</option>
        </Form.Select>
      </Form.Group>
      {subjectSettings === "auto" &&
        <>
          <Form.Group className="mb-2">
            <Form.Label className="fw-bold mb-1">
              Subject Prefix Name <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              className="inputboxborder"
              placeholder="Enter Subject Prefix Name"
              value={subjectPrefix}
              onChange={(e) => setSubjectPrefix(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label className="fw-bold mb-1">
              Subject Starting Number <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="number"
              className="inputboxborder"
              placeholder="Enter Subject Starting Number"
              value={subjectStartingNumber}
              onChange={(e) => setSubjectStartingNumber(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label className="fw-bold mb-1">
              Subject Postfix Name <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              className="inputboxborder"
              placeholder="Enter Subject Postfix Name"
              value={subjectPostfix}
              onChange={(e) => setSubjectPostfix(e.target.value)}
            />
          </Form.Group>
        </>
      }
      <div className="text-end mb-2">
        {studyUniqueId ? (
          <button
            className="submitbutton"
            onClick={handleSubmit2}
            disabled={loading || !siteId || !subjectLimit || !subjectSettings || !dynamicUpdateConditionData}
            title={
              !siteId || !subjectLimit || !subjectSettings || !dynamicUpdateConditionData
                ? "Fill all mandatory fields"
                : ""
            }
          >
            {loading ? (
              <Spinner
                className="me-1"
                style={{ width: 15, height: 15 }}
                animation="border"
              />
            ) : (
              <i className="fa-solid fa-pen-to-square me-1"></i>
            )}
            Update Site
          </button>
        ) : (
          <button
            onClick={handleSubmit1}
            className="submitbutton"
            disabled={loading || !siteId || !subjectLimit || !subjectSettings || !dynamicAddConditionData}
            title={
              !siteId || !subjectLimit || !subjectSettings || !dynamicAddConditionData
                ? "Fill all mandatory fields"
                : ""
            }
          >
            {loading ? (
              <Spinner
                className="me-1"
                style={{ width: 15, height: 15 }}
                animation="border"
              />
            ) : (
              <i className="fa-solid fa-square-plus me-1"></i>
            )}
            Add Site
          </button>
        )}
      </div>
      {customMessage && (
        <Alert variant={statusCode === 200 ? "success" : "danger"}>
          {customMessage}
        </Alert>
      )}
    </div>
  );
};

export default AddUpdateSiteRows;
