import React, { useEffect, useRef, useState } from "react";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import { useParams } from "react-router";
import ACRF from "./CDISC/SDTM/ACRF/ACRF";
import CDISCSidebar from "./CDISCSidebar";
import Legacy from "./CDISC/Legacy/Legacy";
import EDCMainPanel from "../EDC/EDCMainPanel";
import CDISCDashboard from "./CDISC/CDISCDashboard";
import Datasets from "./CDISC/SDTM/Datasets/Datasets";
import Variable from "./CDISC/SDTM/Variable/Variable";
import Documents from "./CDISC/SDTM/Documents/Documents";
import CDISCCollpaseSidebar from "./CDISCCollpaseSidebar";
import STDMMethod from "./CDISC/SDTM/STDMMethod/STDMMethod";
import DerivedData from "./CDISC/SDTM/Datasets/DerivedData";
import { ResizableX, ResizableY } from "../Layouts/Layouts";
import ValueLabel from "./CDISC/SDTM/ValueLabel/ValueLabel";
import UseSidebarReducer from "../../Hooks/UseSidebarReducer";
import STDMComment from "./CDISC/SDTM/STDMComment/STDMComment";
import ResizerXManager from "../ResizerXManager/ResizerXManager";
import WhereClauses from "./CDISC/SDTM/WhereClauses/WhereClauses";
import STDMCodeList from "./CDISC/SDTM/STDMCodeList/STDMCodeList";
import Dictionaries from "./CDISC/SDTM/Dictionaries/Dictionaries";
import ValueLavel from "./CDISC/SDTMSettings/ValueLavel/ValueLavel";
import CRFDataset from "./CDISC/SDTM/Datasets/CRFDataset/CRFDataset";
import SDTMSettings from "./CDISC/SDTMSettings/SDTMSettings/SDTMSettings";
import StudyMetadata from "./CDISC/SDTMSettings/StudyMetadata/StudyMetadata";
import IssueSummary from "./CDISC/CDRGStudyMetaData/IssueSummary/IssueSummary";
import DerivationData from "./CDISC/SDTM/Datasets/DerivationData/DerivationData";
import CDRGWordEditorData from "./CDISC/CDRG/CDRGFormReport/CommonDesign/WordEditorData";
import AcronymsMetadata from "./CDISC/CDRGStudyMetaData/AcronymsMetadata/AcronymsMetadata";
import StudySupplementary from "./CDISC/SDTMSettings/StudySupplementary/StudySupplementary";
import ADRGWordEditorData from "./CDISC/ADRG/ADRGFormReport/CommonDesign/ADRGWordEditorData";
import SourceDataAnalytics from "./CDISC/SDTMSettings/SourceDataAnalytics/SourceDataAnalytics";
import TrialDesignDatasets from "./CDISC/CDRGStudyMetaData/TrialDesignDatasets/TrialDesignDatasets";
import StudyManagementData from "../CommonComponents/DashboardBody/StudyIdData/StudyManagementData";
import DataConformanceInputs from "./CDISC/CDRGStudyMetaData/DataConformanceInputs/DataConformanceInputs";
import AppendixInclusionMetadata from "./CDISC/CDRGStudyMetaData/AppendixExclusionInclusion/AppendixInclusionMetadata";
import ProtocolTrialSummeryMetadata from "../CommonComponents/DashboardBody/StudyMetadata/ProtocolTrialSummeryMetadata ";
import SubjectDataDescriptionOverview from "./CDISC/CDRGStudyMetaData/SubjectDataDescription/SubjectDataDescriptionOverview";
import SubjectDataDescriptionAnnotationCrfs from "./CDISC/CDRGStudyMetaData/SubjectDataDescription/SubjectDataDescriptionAnnotationCrfs";

const CDISCPanel = () => {
  const { studyId } = useParams();
  const { showSidebar } = UseSidebarReducer();
  const [studyTabs, setStudyTabs] = useState([]);
  const [collapseSidebar, setCollpaseSidebar] = useState(false);

  const [resizingColumn, setResizingColumn] = useState(null);
  const startX = useRef(0);
  const startWidth = useRef(0);
  const tableRef = useRef(null);

  useEffect(() => {
    const onMouseMove = (e) => {
      if (resizingColumn !== null) {
        const width = startWidth.current + (e.pageX - startX.current);
        const table = tableRef.current;

        if (!table) {
          console.error("Table reference is null.");
          return;
        }

        const th = table.querySelector(`th:nth-child(${resizingColumn + 1})`);

        if (!th) {
          console.error(
            `No th element found for column index: ${resizingColumn}`
          );
          return;
        }

        th.style.width = `${width}px`;
        table.classList.add("resizing");
      }
    };

    const onMouseUp = () => {
      setResizingColumn(null);
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };

    if (resizingColumn !== null) {
      document.addEventListener("mousemove", onMouseMove);
      document.addEventListener("mouseup", onMouseUp);
    }

    return () => {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };
  }, [resizingColumn]);

  const onMouseDown = (e, columnIndex) => {
    setResizingColumn(columnIndex);
    startX.current = e.pageX;
    startWidth.current = e.target.offsetWidth;
    document.body.style.userSelect = "none";
    tableRef?.current?.querySelectorAll("th")?.forEach((column, index) => {
      if (index !== columnIndex) {
        column.style.width = `${column.offsetWidth}px`;
      }
    });
  };

  const handleAddNewTab = (studyTabName, icon, studyTabId, ids) => {
    let newTab = {
      studyTabId: studyTabId,
      studyTabName: studyTabName,
      icon: icon,
      openDT: new Date(),
      status: "active",
      ids: ids,
    };
    const updatedTabs = studyTabs?.map((studyTab) => ({
      ...studyTab,
      status: "inactive",
    }));
    const existTab = studyTabs?.some(
      (studyTab) => studyTab.studyTabId === studyTabId
    );
    if (existTab) {
      updatedTabs.forEach((element) => {
        if (element.studyTabId === studyTabId) {
          element.status = "active";
          element.studyTabName = studyTabName;
        }
      });
      setStudyTabs(updatedTabs || []);
    } else {
      setStudyTabs([...updatedTabs, newTab] || []);
    }
  };

  const checkActiveTab = (studyTabId) => {
    return studyTabs?.some(
      (item) => item.studyTabId === studyTabId && item.status === "active"
    );
  };

  const checkDynamicActiveTab = (studyTabId) => {
    return studyTabs?.some(
      (item) => item.studyTabId.includes(studyTabId) && item.status === "active"
    );
  };

  const checkHomeActiveTab = () => {
    return studyTabs?.some((item) => item.status === "active");
  };

  const activeStudyTab = studyTabs?.some((item) => item.status === "active");

  useEffect(() => {
    const savedStudyTabs = JSON.parse(localStorage.getItem("studyTabs"));
    if (savedStudyTabs) {
      setStudyTabs(savedStudyTabs || []);
    }
  }, []);

  const [objectId, setObjectId] = useState(null);

  useEffect(() => {
    localStorage.setItem("studyTabs", JSON.stringify(studyTabs));
    let data = studyTabs?.find((item) => item.status === "active");
    setObjectId(data?.ids);
  }, [studyTabs]);

  return (
    <div>
      <section>
        <Navbar Profiler1 />
        <ResizableX>
          {!showSidebar && (
            <div id="div0" style={{ width: collapseSidebar ? 40 : 250 }}>
              <section className="middle-content">
                <div className="sidebar" id="myGroup">
                  <div className="menus">
                    <div className="sidebar-body">
                      {collapseSidebar ? (
                        <CDISCCollpaseSidebar
                          handleAddNewTab={handleAddNewTab}
                          checkActiveTab={checkActiveTab}
                          studyId={studyId}
                        />
                      ) : (
                        <CDISCSidebar
                          handleAddNewTab={handleAddNewTab}
                          checkActiveTab={checkActiveTab}
                          studyId={studyId}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </section>
            </div>
          )}
          <ResizerXManager collapseSidebar={collapseSidebar} />
          <ResizableY>
            <section className="middle-content " id="dashboard-section">
              <div className="inner-cont">
                <EDCMainPanel
                  setStudyTabs={setStudyTabs}
                  studyTabs={studyTabs}
                  activeStudyTab={activeStudyTab}
                  collapseSidebar={collapseSidebar}
                  setCollpaseSidebar={setCollpaseSidebar}
                />
                {!checkHomeActiveTab() && <CDISCDashboard />}

                {checkActiveTab("protocolMetadata") && (
                  <ProtocolTrialSummeryMetadata
                    tableRef={tableRef}
                    onMouseDown={onMouseDown}
                  />
                )}

                {checkActiveTab("cdiscStudyDetails") && (
                  <StudyManagementData
                    tableRef={tableRef}
                    onMouseDown={onMouseDown}
                  />
                )}

                {checkActiveTab("sdtmDataset") && (
                  <Datasets
                    tableRef={tableRef}
                    onMouseDown={onMouseDown}
                    handleAddNewTab={handleAddNewTab}
                  />
                )}

                {checkActiveTab("sdtmVariable") && (
                  <Variable tableRef={tableRef} onMouseDown={onMouseDown} />
                )}

                {checkActiveTab("sdtmValueLavel") && (
                  <ValueLabel tableRef={tableRef} onMouseDown={onMouseDown} />
                )}

                {checkActiveTab("sdtmWhereClause") && (
                  <WhereClauses tableRef={tableRef} onMouseDown={onMouseDown} />
                )}

                {checkActiveTab("sdtmCodeList") && (
                  <STDMCodeList tableRef={tableRef} onMouseDown={onMouseDown} />
                )}

                {checkActiveTab("sdtmMethod") && (
                  <STDMMethod tableRef={tableRef} onMouseDown={onMouseDown} />
                )}

                {checkActiveTab("sdtmComment") && (
                  <STDMComment tableRef={tableRef} onMouseDown={onMouseDown} />
                )}

                {checkActiveTab("sdtmDocument") && (
                  <Documents tableRef={tableRef} onMouseDown={onMouseDown} />
                )}

                {checkActiveTab("sdtmDictionaries") && (
                  <Dictionaries tableRef={tableRef} onMouseDown={onMouseDown} />
                )}

                {checkActiveTab("sdtmACRF") && (
                  <ACRF />
                )}

                {checkActiveTab("sdtmCDRG") && (
                  <CDRGWordEditorData
                    tableRef={tableRef}
                    onMouseDown={onMouseDown}
                  />
                )}

                {checkActiveTab("sdtmADRG") && (
                  <ADRGWordEditorData
                    tableRef={tableRef}
                    onMouseDown={onMouseDown}
                  />
                )}

                {checkActiveTab("sdtmStudyMetdata") && (
                  <StudyMetadata
                  />
                )}

                {checkActiveTab("sdtmSDAData") && (
                  <SourceDataAnalytics
                    tableRef={tableRef}
                    onMouseDown={onMouseDown}
                  />
                )}

                {checkActiveTab("CDRGAcronymsData") && (
                  <AcronymsMetadata tableRef={tableRef} onMouseDown={onMouseDown} />
                )}

                {checkActiveTab("CDRGTrialDesignDataset") && (
                  <TrialDesignDatasets tableRef={tableRef} onMouseDown={onMouseDown} />
                )}

                {checkActiveTab("descriptionOverview") && (
                  <SubjectDataDescriptionOverview />
                )}

                {checkActiveTab("annotatedCRFs") && (
                  <SubjectDataDescriptionAnnotationCrfs />
                )}

                {checkActiveTab("CDRGDataConformance") && (
                  <DataConformanceInputs />
                )}

                {checkActiveTab("CDRGIssueSummary") && (
                  <IssueSummary tableRef={tableRef} onMouseDown={onMouseDown} />
                )}

                {checkActiveTab("CDRGAppendixInclusion") && (
                  <AppendixInclusionMetadata tableRef={tableRef} onMouseDown={onMouseDown} />
                )}

                {checkActiveTab("sdtSettings") && (
                  <SDTMSettings tableRef={tableRef} onMouseDown={onMouseDown} />
                )}

                {checkActiveTab("studyValueLavel") && (
                  <ValueLavel tableRef={tableRef} onMouseDown={onMouseDown} />
                )}

                {checkActiveTab("studySupplementary") && (
                  <StudySupplementary
                    tableRef={tableRef}
                    onMouseDown={onMouseDown}
                  />
                )}

                {checkActiveTab("crfDataId") && (
                  <Legacy tableRef={tableRef} onMouseDown={onMouseDown} />
                )}

                {checkDynamicActiveTab("entryDataId") && (
                  <CRFDataset
                    tableRef={tableRef}
                    domainId={objectId?.id}
                    onMouseDown={onMouseDown}
                  />
                )}

                {checkDynamicActiveTab("derivationDataId") && (
                  <DerivationData
                    tableRef={tableRef}
                    onMouseDown={onMouseDown}
                    handleAddNewTab={handleAddNewTab}
                    domainId={objectId?.id}
                    studyId={studyId}
                  />
                )}

                {checkDynamicActiveTab("derivedDatasets") && (
                  <DerivedData
                    tableRef={tableRef}
                    onMouseDown={onMouseDown}
                    handleAddNewTab={handleAddNewTab}
                    studyId={studyId}
                    derivationId={objectId?.derivationId}
                    studyTabs={studyTabs}
                    setStudyTabs={setStudyTabs}
                  />
                )}
              </div>
            </section>
          </ResizableY>
        </ResizableX>
        <Footer />
      </section>
    </div>
  );
};

export default CDISCPanel;
