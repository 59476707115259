import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import { randomizerServices } from "../../../Services/IWRS/randomizerServices";
import Randomization from "../Randomize/Randomization";

const NewTrialParametersDesign = ({ tableRef, type, onMouseDown }) => {
  const { studyId } = useParams();
  const [blinding, setBlinding] = useState("");
  const [randomizeId, setRandomizeId] = useState("");
  const [randomMethods, setRandomMethods] = useState("completelyRandom");
  const [codeBlock, setCodeBlock] = useState("");
  const [noBlock, setNoBlock] = useState("");
  const [blindingCodePrefix, setBlindingCodePrefix] = useState("");
  const [blindingCodeSuffix, setBlindingCodeSuffix] = useState("");
  const [plannedUnblind, setPlannedUnblind] = useState("");
  const [approvalRequired, setApprovalRequired] = useState("");
  const [unblindingRequired, setUnblindingRequired] = useState("");
  const [randomizeStatus, setRandomizeStatus] = useState("");
  const [seedBock, setSeedBock] = useState(false);
  const [randomNumberSeed, setRandomNumberSeed] = useState("");
  let studyMode = JSON.parse(localStorage.getItem("StudyMode"));
  const findRandomizerStudyParameter = async (randomizeId) => {
    let data = await randomizerServices.findRandomizerStudyParameter(studyId, randomizeId);
    setBlinding(data?.data?.blinding || "");
    setRandomMethods(data?.data?.randomMethods || "completelyRandom");
    setCodeBlock(data?.data?.codeBlock || "");
    setNoBlock(data?.data?.noBlock || "");
    setBlindingCodePrefix(data?.data?.blindingCodePrefix || "");
    setBlindingCodeSuffix(data?.data?.blindingCodeSuffix || "");
    setPlannedUnblind(data?.data?.plannedUnblind || "");
    setApprovalRequired(data?.data?.approvalRequired || "");
    setUnblindingRequired(data?.data?.unblindingRequired || "");
    setRandomizeStatus(data?.data?.randomizeStatus || "");
    if(studyMode === "DBP MODE"){
      setRandomNumberSeed(data?.data?.randomNumberDBPSeed || "");
    }else if(studyMode === "QC Mode"){
      setRandomNumberSeed(data?.data?.randomNumberQCSeed || "");
    }else if(studyMode === "Live Mode"){
      setRandomNumberSeed(data?.data?.randomNumberLiveSeed || "");
    }
  };

  const addRandomizerStudyParameter = async () => {
    let userData = {};
    userData.studyMode = studyMode;
    userData.studyId = studyId;
    userData.randomizeId = randomizeId;
    userData.blinding = blinding;
    userData.randomMethods = randomMethods;
    userData.noBlock = noBlock;
    userData.blindingCodePrefix = blindingCodePrefix;
    userData.blindingCodeSuffix = blindingCodeSuffix;
    userData.plannedUnblind = plannedUnblind;
    userData.approvalRequired = approvalRequired;
    userData.unblindingRequired = unblindingRequired;
    userData.randomizeStatus = randomizeStatus;
    userData.randomNumberSeed = randomNumberSeed;
    let result = await randomizerServices.addRandomizerStudyParameter(userData);
    if (result?.statusCode === 200) {
      findRandomizerStudyParameter(randomizeId);
    }
  };

  useEffect(() => {
    findRandomizerStudyParameter(randomizeId);
  }, [randomizeId]);

  return (
    <>
      <Container fluid>
        <Row>
          <Col md={randomizeId ? 6 : 12} className="p-0">
            <Randomization
              type={type}
              tableRef={tableRef}
              onMouseDown={onMouseDown}
              setRandomizeId={setRandomizeId}
              setSeedBock={setSeedBock}
            />
          </Col>
          {randomizeId &&
            <Col md={6}>
              <div className="p-0" style={{ marginTop: 28 }}>
                <div className="d-flex gap-2">
                  <Card className="mb-3 bg-light border" style={{ width: 345 }}>
                    <Card.Body className="p-0">
                      <div className="border-bottom p-2 text-center">Blinding</div>
                      <div
                        className="p-3 overflow-auto bg-white"
                        style={{ height: "calc(100vh - 240px)" }}
                      >
                        <Form.Group className="mb-2">
                          <Form.Label className="mb-1">
                            Blinding <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Select
                            disabled={studyMode !== "DBP MODE"}
                            required
                            onChange={(e) => setBlinding(e.target.value)}
                          >
                            <option>None (open trial)</option>
                            <option
                              selected={blinding === "singleBlind"}
                              value="singleBlind"
                            >
                              Single Blind Trial
                            </option>
                            <option
                              selected={blinding === "doubleBlind"}
                              value="doubleBlind"
                            >
                              Double Blind Trial
                            </option>
                            <option
                              selected={blinding === "tripleBlind"}
                              value="tripleBlind"
                            >
                              Triple Blind Trial
                            </option>
                          </Form.Select>
                        </Form.Group>
                        <div id="showDoubleBlock">
                          <Form.Label className="mb-1 fw-bold">
                            Blinding Table Layout
                          </Form.Label>
                          <Form.Group className="mb-2">
                            <Form.Label className="mb-1">
                              No. of Block
                              <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              disabled={studyMode !== "DBP MODE"}
                              onChange={(e) => setNoBlock(e.target.value)}
                              value={noBlock}
                              required
                              type="number"
                              placeholder="Enter No. of Block"
                            />
                          </Form.Group>
                          <Form.Group className="mb-2">
                            <Form.Label className="mb-1">
                              Block Size
                              <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              disabled
                              value={codeBlock}
                              required
                              type="number"
                              placeholder="Enter Code Block Size"
                            />
                          </Form.Group>
                          <Form.Group className="mb-2">
                            <Form.Label className="mb-1">
                              Code Prefix <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              disabled={studyMode !== "DBP MODE"}
                              onChange={(e) => setBlindingCodePrefix(e.target.value)}
                              value={blindingCodePrefix}
                              required
                              type="text"
                              placeholder="Enter Code Prefix"
                            />
                          </Form.Group>
                          <Form.Group className="mb-2">
                            <Form.Label className="mb-1">
                              Code Suffix <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              disabled={studyMode !== "DBP MODE"}
                              onChange={(e) => setBlindingCodeSuffix(e.target.value)}
                              value={blindingCodeSuffix}
                              required
                              type="text"
                              placeholder="Enter Code Suffix"
                            />
                          </Form.Group>
                          <Form.Group className="mb-2">
                            <Form.Label className="mb-1">
                              Planned Unblind Date
                            </Form.Label>
                            <Form.Control
                              disabled={studyMode !== "DBP MODE"}
                              onChange={(e) => setPlannedUnblind(e.target.value)}
                              value={plannedUnblind}
                              type="date"
                              placeholder="Enter Planned Unblind Code"
                            />
                          </Form.Group>
                          <Form.Group className="mb-2">
                            <Form.Label className="mb-1">
                              How Many of Level Approval Required{" "}
                              <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Select
                              disabled={studyMode !== "DBP MODE"}
                              onChange={(e) => setApprovalRequired(e.target.value)}
                            >
                              <option>Select Lavel Approval</option>
                              <option selected={approvalRequired === "one"} value="one">
                                1
                              </option>
                              <option selected={approvalRequired === "two"} value="two">
                                2
                              </option>
                              <option
                                selected={approvalRequired === "three"}
                                value="three"
                              >
                                3
                              </option>
                              <option
                                selected={approvalRequired === "four"}
                                value="four"
                              >
                                4
                              </option>
                              <option
                                selected={approvalRequired === "five"}
                                value="five"
                              >
                                5
                              </option>
                            </Form.Select>
                          </Form.Group>
                          <Form.Group className="mb-2">
                            <Form.Label className="mb-1">
                              Unblinding To <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Select
                              disabled={studyMode !== "DBP MODE"}
                              onChange={(e) => setUnblindingRequired(e.target.value)}
                            >
                              <option>Select Unblinding For</option>
                              <option
                                selected={unblindingRequired === "allUser"}
                                value="allUser"
                              >
                                All Users
                              </option>
                              <option
                                selected={unblindingRequired === "requestedUser"}
                                value="requestedUser"
                              >
                                User Who Requested For Unblinding
                              </option>
                            </Form.Select>
                          </Form.Group>
                          <Form.Group className="mb-2">
                            <Form.Label className="mb-1">
                              Randomize At Once <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Select
                              disabled={studyMode !== "DBP MODE"}
                              onChange={(e) => setRandomizeStatus(e.target.value)}
                            >
                              <option>Select Randomize</option>
                              <option selected={randomizeStatus === "yes"} value="yes">
                                Yes
                              </option>
                              <option selected={randomizeStatus === "no"} value="no">
                                No
                              </option>
                            </Form.Select>
                          </Form.Group>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                  <Card className="mb-3 bg-light border" style={{ width: 345 }}>
                    <Card.Body className="p-0">
                      <div className="border-bottom p-2 text-center">Randomization</div>
                      <div
                        className="p-3 overflow-auto bg-white"
                        style={{ height: "calc(100vh - 250px)" }}
                      >
                        <Form.Group className="mb-2">
                          <Form.Label className="mb-1">
                            Randomization Method <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Select
                            disabled
                            onChange={(e) => setRandomMethods(e.target.value)}
                          >
                            <option value="">Select Method</option>
                            <option
                              value="completelyRandom"
                              selected={randomMethods === "completelyRandom"}
                            >
                              Completely at Random
                            </option>
                            <option
                              value="permutedBlocks"
                              selected={randomMethods === "permutedBlocks"}
                            >
                              Permuted Blocks
                            </option>
                          </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-2">
                          <Form.Label className="mb-1">
                            Random Number Seed <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            onChange={(e) => setRandomNumberSeed(e.target.value)}
                            value={randomNumberSeed}
                            disabled={seedBock}
                            type="number"
                            placeholder="Enter Random Number Seed"
                          />
                        </Form.Group>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
                <div className="p-1 hstack justify-content-end gap-2 position-sticky bottom-0" style={{ background: "#f8f9fa" }}>
                  <button
                    className="updateProfile"
                    onClick={addRandomizerStudyParameter}
                  >
                    Save
                  </button>
                </div>
              </div>
            </Col>
          }
        </Row>
      </Container>
    </>
  );
};

export default NewTrialParametersDesign;
