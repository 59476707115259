import React, { useEffect, useState } from "react";
import SplitPane from "react-split-pane";
import { SpinerDataLoader } from "../../../NoDataFoundTable/NoDataFoundTable";
import { Table } from "react-bootstrap";
import Pagination from "../../../ETMF/Pagination/Pagination";
import { elearningServices } from "../../../../Services/elearningServices";
import { useParams } from "react-router";
import Moment from "react-moment";
import UseToggle from "../../../../Hooks/UseToggle";
import ELearningProgressReport from "./ELearningProgressReport";
import ELearningCertificateReport from "./Certificate/ELearningCertificateReport";

const ELearningActivity = ({ trainingId, onMouseDown, tableRef }) => {
  const [loader, setLoader] = useState(false);
  const [userList, setUserList] = useState([]);
  const [userReport, setUserReport] = useState("");
  const [userName, setUserName] = useState("");
  const [traningDate, setTraningDate] = useState("");
  const { studyId } = useParams();

  const { toggle, setToggle, toggle1, setToggle1 } = UseToggle("");

  const fullUrl = window.location.href;
  const splitUrl = fullUrl.split('/');
  const getStudyELearningTraningUsers = async () => {
    setLoader(true);
    let data = await elearningServices.getStudyELearningTraningUsers(
      trainingId,
      studyId,
      splitUrl[3]
    );
    setUserList(data?.data || []);
    setLoader(false);
  };

  console.log(">>>>>>>>>userList", userList);
  
  const getStudyELearningTraningProgressReportByUser = async (
    trainingId,
    userId
  ) => {
    let data =
      await elearningServices.getStudyELearningTraningProgressReportByUser(
        trainingId,
        userId,
        studyId,
        splitUrl[3]
      );
    setUserReport(data?.data);
  };

  useEffect(() => {
    getStudyELearningTraningUsers();
  }, [trainingId]);

  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(15);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = userList?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(userList?.length / itemsPerPage);

  return (
    <>
      <SplitPane
        split="vertical"
        minSize={20}
        defaultSize="100%"
        style={{ height: "91%" }}
      >
        {loader ? (
          <SpinerDataLoader />
        ) : (
          <div className="table-container tableLibrarySection">
            <Table
              id="resizable-table"
              striped
              hover
              ref={tableRef}
              className="m-0 custom-table"
            >
              <thead className="thead-sticky top-0">
                <tr>
                  <th style={{ width: 45 }} onMouseDown={(e) => onMouseDown(e, 0)}>Sr.</th>
                  <th onMouseDown={(e) => onMouseDown(e, 1)}>Name</th>
                  <th onMouseDown={(e) => onMouseDown(e, 2)}>UserId</th>
                  <th onMouseDown={(e) => onMouseDown(e, 3)}>Last Activity Date | Time</th>
                  <th style={{ width: 100 }} onMouseDown={(e) => onMouseDown(e, 4)}>View Progress</th>
                  <th style={{ width: 100 }} onMouseDown={(e) => onMouseDown(e, 5)}>View Certificate</th>
                </tr>
              </thead>
              <tbody>
                {result?.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.fullName}</td>
                    <td>{item.userId}</td>
                    <td>
                      <Moment format="DD MMM YYYY | HH:mm:ss">
                        {item?.lastActivityDT}
                      </Moment>
                    </td>
                    <td>
                      <button
                        onClick={() => {
                          getStudyELearningTraningProgressReportByUser(
                            item?.traningId,
                            item?.userId
                          ); setToggle1();
                        }}
                        className="text-white border-success bg-success border"
                        style={{
                          fontSize: 10,
                          borderRadius: 3,
                          padding: "2px 8px",
                        }}
                      >
                        View Progress
                      </button>
                    </td>
                    <td>
                      <button
                        className="text-white border-success bg-success border"
                        onClick={() => {
                          getStudyELearningTraningProgressReportByUser(
                            item?.traningId,
                            item?.userId
                          ); setToggle(); setUserName(item?.fullName); setTraningDate(item?.lastActivityDT)
                        }
                        }
                        style={{
                          fontSize: 10,
                          borderRadius: 3,
                          padding: "2px 8px",
                        }}
                      >
                        View Certificate
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div className="position-sticky bottom-0">
              <Pagination
                totalPage={totalPage}
                pageNumber={pageNumber}
                itemsPerPage={itemsPerPage}
                totalItems={userList?.length}
                setPageNumber={setPageNumber}
                setItemsPerPage={setItemsPerPage}
              />
            </div>
          </div>
        )}
      </SplitPane>
      <ELearningProgressReport
        Show={!toggle1}
        Hide={setToggle1}
        Title={"Report"}
        userReport={userReport}
      />
      <ELearningCertificateReport
        Show={!toggle}
        Hide={setToggle}
        Title={"Certificate"}
        userReport={userReport}
        userName={userName}
        traningDate={traningDate}
      />
    </>
  );
};

export default ELearningActivity;
