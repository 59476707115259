import React, { useEffect, useRef, useState } from "react";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import RBQMSidebar from "./RBQMSidebar";
import { useParams } from "react-router";
import EDCMainPanel from "../EDC/EDCMainPanel";
import RiskArea from "./RBQM/RiskArea/RiskArea";
import RBQMDashboard from "./RBQM/RBQMDashboard";
import RiskManagement from "./RBQM/RiskManagement";
import RBQMCollapseSidebar from "./RBQMCollapseSidebar";
import RiskCategory from "./RBQM/RiskCategory/RiskCategory";
import { ResizableX, ResizableY } from "../Layouts/Layouts";
import UseSidebarReducer from "../../Hooks/UseSidebarReducer";
import ResizerXManager from "../ResizerXManager/ResizerXManager";

const RBQMPanel = () => {
  const { showSidebar } = UseSidebarReducer();

  const [studyTabs, setStudyTabs] = useState([]);
  const { studyId } = useParams();
  const [collapseSidebar, setCollpaseSidebar] = useState(false);

  const [resizingColumn, setResizingColumn] = useState(null);
  const startX = useRef(0);
  const startWidth = useRef(0);
  const tableRef = useRef(null);

  useEffect(() => {
    const onMouseMove = (e) => {
      if (resizingColumn !== null) {
        const width = startWidth.current + (e.pageX - startX.current);
        const table = tableRef.current;

        if (!table) {
          console.error("Table reference is null.");
          return;
        }

        const th = table.querySelector(`th:nth-child(${resizingColumn + 1})`);

        if (!th) {
          console.error(
            `No th element found for column index: ${resizingColumn}`
          );
          return;
        }

        th.style.width = `${width}px`;
        table.classList.add("resizing");
      }
    };

    const onMouseUp = () => {
      setResizingColumn(null);
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };

    if (resizingColumn !== null) {
      document.addEventListener("mousemove", onMouseMove);
      document.addEventListener("mouseup", onMouseUp);
    }

    return () => {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };
  }, [resizingColumn]);

  const onMouseDown = (e, columnIndex) => {
    setResizingColumn(columnIndex);
    startX.current = e.pageX;
    startWidth.current = e.target.offsetWidth;
    document.body.style.userSelect = "none";
    tableRef?.current?.querySelectorAll("th")?.forEach((column, index) => {
      if (index !== columnIndex) {
        column.style.width = `${column.offsetWidth}px`;
      }
    });
  };

  const handleAddNewTab = (studyTabName, icon, studyTabId) => {
    let newTab = {
      studyTabId: studyTabId,
      studyTabName: studyTabName,
      icon: icon,
      openDT: new Date(),
      status: "active",
    };
    const updatedTabs = studyTabs?.map((studyTab) => ({
      ...studyTab,
      status: "inactive",
    }));
    const existTab = studyTabs?.some(
      (studyTab) => studyTab.studyTabId === studyTabId
    );
    if (existTab) {
      updatedTabs.forEach((element) => {
        if (element.studyTabId === studyTabId) {
          element.status = "active";
          element.studyTabName = studyTabName;
        }
      });
      setStudyTabs(updatedTabs || []);
    } else {
      setStudyTabs([...updatedTabs, newTab] || []);
    }
  };

  const checkActiveTab = (studyTabId) => {
    return studyTabs?.some(
      (item) => item.studyTabId === studyTabId && item.status === "active"
    );
  };

  const checkHomeActiveTab = () => {
    return studyTabs?.some((item) => item.status === "active");
  };

  const activeStudyTab = studyTabs?.some((item) => item.status === "active");

  useEffect(() => {
    const savedStudyTabs = JSON.parse(localStorage.getItem("studyTabs"));
    if (savedStudyTabs) {
      setStudyTabs(savedStudyTabs || []);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("studyTabs", JSON.stringify(studyTabs));
  }, [studyTabs]);

  return (
    <div>
      <section>
        <Navbar Profiler1 handleAddNewTab={handleAddNewTab} />
        <ResizableX>
          {!showSidebar && (
            <div id="div0" style={{ width: collapseSidebar ? 40 : 250 }}>
              <section className="middle-content">
                <div className="sidebar" id="myGroup">
                  <div className="menus">
                    {collapseSidebar ? (
                      <RBQMCollapseSidebar
                        handleAddNewTab={handleAddNewTab}
                        checkActiveTab={checkActiveTab}
                        studyId={studyId}
                      />
                    ) : (
                      <RBQMSidebar
                        handleAddNewTab={handleAddNewTab}
                        checkActiveTab={checkActiveTab}
                        studyId={studyId}
                      />
                    )}
                  </div>
                </div>
              </section>
            </div>
          )}
          <ResizerXManager collapseSidebar={collapseSidebar} />
          <ResizableY>
            <section className="middle-content " id="dashboard-section">
              <div className="inner-cont">
                <EDCMainPanel
                  setStudyTabs={setStudyTabs}
                  studyTabs={studyTabs}
                  activeStudyTab={activeStudyTab}
                  collapseSidebar={collapseSidebar}
                  setCollpaseSidebar={setCollpaseSidebar}
                />
                {!checkHomeActiveTab() && <RBQMDashboard />}

                {checkActiveTab("riskCategoryDataId") && (
                  <RiskCategory tableRef={tableRef} onMouseDown={onMouseDown} />
                )}

                {checkActiveTab("riskAreaDataId") && (
                  <RiskArea tableRef={tableRef} onMouseDown={onMouseDown} />
                )}

                {checkActiveTab("riskManagementDataId") && (
                  <RiskManagement
                    tableRef={tableRef}
                    onMouseDown={onMouseDown}
                  />
                )}
              </div>
            </section>
          </ResizableY>
        </ResizableX>
        <Footer />
      </section>
    </div>
  );
};

export default RBQMPanel;
