import React from "react";
import Moment from "react-moment";
import { Form, Offcanvas, Table } from "react-bootstrap";

const RequestmgmtOffcanvasData = ({
    Show,
    Hide,
    loader,
    tableRef,
    password,
    setPassword,
    onMouseDown,
    approvalRemark,
    updatedDetails,
    getStatusColor,
    setApprovalRemark,
    approveRequestedUnblinedTreatment,
}) => {
    return (
        <>
            <Offcanvas show={Show} onHide={Hide} style={{ width: 400 }} placement="start">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>{updatedDetails?.randomizationNumber}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className="p-0">
                    <div className="overflow-auto"
                        style={{ height: "calc(100vh - 95px)" }}
                    >
                        <div className="table-container tableLibrarySection">
                            <Table
                                id="resizable-table" striped
                                className="m-0 custom-table"
                                ref={tableRef}
                                bordered
                            >
                                <thead className="thead-sticky">
                                    <tr>
                                        <th onMouseDown={(e) => onMouseDown(e, 0)}>
                                            Attribute
                                        </th>
                                        <th onMouseDown={(e) => onMouseDown(e, 1)}>
                                            Value
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Site Id</td>
                                        <td>{updatedDetails?.siteId}</td>
                                    </tr>
                                    <tr>
                                        <td>Block</td>
                                        <td>{updatedDetails?.block}</td>
                                    </tr>
                                    <tr>
                                        <td>Subject Number</td>
                                        <td>{updatedDetails?.subjectNumber}</td>
                                    </tr>
                                    <tr>
                                        <td>Randomization Number</td>
                                        <td>{updatedDetails?.randomizationNumber}</td>
                                    </tr>
                                    <tr>
                                        <td>Status</td>
                                        <td
                                            style={{
                                                color: getStatusColor(updatedDetails?.status),
                                                fontWeight: "500",
                                            }}
                                            className="text-capitalize">{updatedDetails?.status}</td>
                                    </tr>
                                    <tr>
                                        <td>Requested Remarks</td>
                                        <td>{updatedDetails?.requestRemark}</td>
                                    </tr>
                                    <tr>
                                        <td>Request by</td>
                                        <td style={{ fontWeight: "normal" }}>
                                            {updatedDetails?.requestedBy}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Date & Time</td>
                                        <td>
                                            <Moment format="DD MMM YYYY | HH:mm" withTitle>
                                                {updatedDetails?.createdAt}
                                            </Moment>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                        <div>
                            <Form.Group className="p-2 mb-0">
                                <Form.Label style={{ fontWeight: 500 }}>Remarks:</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={4}
                                    value={approvalRemark}
                                    onChange={(e) => setApprovalRemark(e.target.value)}
                                    placeholder="Add Remarks"
                                />
                            </Form.Group>
                            <Form.Group className="p-2">
                                <Form.Label style={{ fontWeight: 500 }}>Authenticate:</Form.Label>
                                <Form.Control
                                    type="password"
                                    rows={4}
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    placeholder="Enter password"
                                />
                            </Form.Group>
                        </div>
                    </div>
                    <div className="p-2 hstack justify-content-end gap-2 bottom-0">
                        <button
                            onClick={approveRequestedUnblinedTreatment}
                            disabled={loader}
                            className="border fontSize12 p-1 px-2 updateProfile"
                            style={{ width: 75, fontSize: "13px" }}
                        >
                            Approve
                        </button>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>

        </>
    );
};

export default RequestmgmtOffcanvasData;
