import React, { useEffect, useState } from "react";
import { SignIcon } from "../../../../../../Assets/Icons/GlobalIcons";
import {
  NoDataFoundTable,
  SpinerDataLoader,
} from "../../../../../NoDataFoundTable/NoDataFoundTable";
import { clientLevelFormServices } from "../../../../../../Services/formServices/clientLevelFormServices";
import UpdateJsonData from "../../../StudyIdData/StudyIdModal/updateJsonData";
import { UseSignature } from "../../../StudyIdData/UseAppendInput";
import Moment from "react-moment";
import { Offcanvas, Form, Table } from "react-bootstrap";
import Swal from "sweetalert2";
import { useParams } from "react-router";
import SplitPane from "react-split-pane";
import { FaSignature } from "react-icons/fa";

export const ReviewHistoryTable = ({
  studyUniqueId,
  visitFormType,
  ReviewFormFieldEndpoint,
  formUniqueId,
  selectedFormId,
  dataEntryFormMode,
  ReviewHistoryEndpoint,
  getGlobalQueryStatusEndPoint,
  connectUniqueIdData,
  formType,
  tableRef,
}) => {
  const StudyMode = JSON.parse(localStorage.getItem("StudyMode"));
  const studyPermissionData = JSON.parse(
    localStorage.getItem("studyPermissionData")
  );
  const { studyId } = useParams();
  const [reviewHistory, setReviewHistory] = useState([]);
  const [history, setHistory] = useState("");
  const [ticketStatus, setTicketStatus] = useState("");
  const [formUniqueIdData, setFormUniqueIdData] = useState("");
  const [formFieldIdData, setFormFieldIdData] = useState("");
  const [loader, setLoader] = useState(false);

  const { signFormProps } = UseSignature(ReviewFormFieldEndpoint);

  const getReviewHistory = async () => {
    setLoader(true);
    let endPointData =
      formType === "studyform"
        ? clientLevelFormServices.getReviewHistoryByID1(
          ReviewHistoryEndpoint,
          formUniqueId,
          selectedFormId
        )
        : connectUniqueIdData
          ? clientLevelFormServices.getLogFormFieldReviewHistoryByID(
            "getLogFormFieldReviewHistoryByID",
            formUniqueId,
            dataEntryFormMode,
            studyUniqueId,
            StudyMode,
            selectedFormId,
            connectUniqueIdData
          )
          : clientLevelFormServices.getDataEntryReviewHistoryByID(
            "getDataEntryFormFieldReviewHistoryByID",
            formUniqueId,
            dataEntryFormMode,
            studyUniqueId,
            StudyMode,
            selectedFormId
          );
    let res = await endPointData;
    setReviewHistory(res?.data);
    setLoader(false);
  };

  const getGlobalTicketsStatus = async () => {
    const res = await clientLevelFormServices.getDataEntryTickets(
      getGlobalQueryStatusEndPoint,
      formUniqueId,
      StudyMode,
      dataEntryFormMode,
      studyUniqueId,
      selectedFormId
    );
    setTicketStatus(res?.data === undefined ? "" : res?.data);
  };

  useEffect(() => {
    getGlobalTicketsStatus();
  }, [
    formUniqueId,
    selectedFormId,
    getGlobalQueryStatusEndPoint,
    dataEntryFormMode,
    studyUniqueId,
    StudyMode,
  ]);

  useEffect(() => {
    getReviewHistory();
  }, [ReviewHistoryEndpoint, formUniqueId, selectedFormId, StudyMode]);

  const permissionData = JSON.parse(localStorage.getItem("permissionData"));
  let role = JSON.parse(localStorage.getItem("role"));

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const reviewedFormField = (formUniqueId, selectedFormId) => {
    handleShow();
    setFormUniqueIdData(formUniqueId);
    setFormFieldIdData(selectedFormId);
  };

  let endPointData =
    formType === "studyform"
      ? ReviewFormFieldEndpoint
      : connectUniqueIdData
        ? "reviewStudyLogFormField"
        : "reviewDataEntryFormField";
  const reviewStudyFormField = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Review Form Field Value",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#103C5E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Verified it!",
    }).then((result) => {
      if (result.isConfirmed) {
        let updateData = {
          uniqueId: formUniqueIdData,
          formFieldId: formFieldIdData,
          formType: visitFormType,
          dataEntryMode: dataEntryFormMode,
          studyMode: StudyMode,
          studyUniqueId: studyUniqueId,
          connectUniqueId: connectUniqueIdData,
          studyId: studyId,
          history: history,
        };
        clientLevelFormServices.queryGenerator(endPointData, updateData);
        closeSignPopUp();
        handleClose();
      }
    });
  };

  const closeSignPopUp = () => {
    setHistory();
  };

  return (
    <>
      <UpdateJsonData Class="modalBackground" {...signFormProps()} />
      <div>
        {(((permissionData?.fieldSignatureClientLevelForm ||
            permissionData?.fieldSignatureGlobleLevelForm ||
            permissionData?.fieldSignatureProjectLevelForm) &&
            role === "user") ||
            ticketStatus === "open" ? (
            ""
          ) : StudyMode === "SDV Live Mode" || StudyMode === "Read Only Live Mode" ||
            StudyMode === "SDV QC Mode" ||
            (studyPermissionData?.siteIdForSDVLive?.length > 0 ||
              studyPermissionData?.siteIdForSDVQC?.length > 0) ? (
            <div className="p-1">
              <button
                className="hstack border-success border fontSize12   bg-blue text-white CP p-1 px-2"
                title="Review Now"
                style={{ fontSize: 10 }}
                onClick={(e) => reviewedFormField(formUniqueId, selectedFormId)}
              >
                <FaSignature style={{ fontSize: 15 }} className="pe-1" />Review Now
              </button>
            </div>
          ) : (
            ""
          ))}
        <SplitPane
          split="vertical"
          minSize={20}
          defaultSize="100%"
          style={{ height: "84%" }}
        >
          {loader ? (
            <SpinerDataLoader />
          ) : reviewHistory?.length > 0 ? (
            <Table
              id="resizable-table"
              striped
              className="m-0 custom-table"
              ref={tableRef}
            >
              <thead className="thead-sticky">
                <tr className="align-baseline text-white lineheight">
                  <th>Record ID</th>
                  <th>Reviewer ID </th>
                  {visitFormType === "log" && <th>Version</th>}
                  <th>Quality Checked Status</th>
                  <th>Remark</th>
                  <th>Created Date</th>
                </tr>
              </thead>
              <tbody className="border-top-0">
                {reviewHistory?.map(
                  (
                    {
                      recordId,
                      reviewerID,
                      status,
                      review,
                      createdAt,
                      version,
                    },
                    i
                  ) => (
                    <tr key={i}>
                      <td>{recordId}</td>
                      <td>{reviewerID}</td>
                      {visitFormType === "log" && <td>{version}</td>}
                      <td>{status}</td>
                      <td>{review}</td>
                      <td>
                        <Moment format="DD MMM YYYY | HH:mm" withTitle>
                          {createdAt}
                        </Moment>
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </Table>
          ) : (
            <NoDataFoundTable MSG="Review not found!" />
          )}
        </SplitPane>
      </div>

      <Offcanvas
        className={show ? "" : "d-none"}
        show={show}
        onHide={handleClose}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Review</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-0">
          <div
            className="p-3 overflow-auto"
            style={{ height: "calc(100vh - 89px)" }}
          >
            <div className="signInTerms mb-3">
              <p className="fontSize12 m-0">
                You are required to provide a signature for this activity in
                order to fully complete it. This records that you are signing
                off theat you have taken, and completed this online review
                activity. This will resilt in the completion record being
                recorded in your review history.
              </p>
            </div>
            <Form.Control
              as="textarea"
              placeholder="Enter Remark"
               
              style={{ height: 100 }}
              onChange={(e) => setHistory(e.target.value)}
              value={history}
            />
          </div>
          <div className="p-2 bg-light shadow-sm hstack justify-content-end gap-2">
            <button
              onClick={handleClose}
              className="border border-danger btn btn-outline-danger fontSize12   p-1 px-2"
            >
              Cancel
            </button>
            <button
              onClick={(e) => reviewStudyFormField(e)}
              className="border fontSize12   p-1 px-2 updateProfile"
            >
              Verify Now
            </button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};
