import React from "react";
import footer from "../../Styles/Footer.module.scss";

const Footer = () => {
  const currentDate = new Date();
  const year = currentDate.getUTCFullYear();
  const month = currentDate.getUTCMonth() + 1;
  const day = currentDate.getUTCDate();
  const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day.toString().padStart(2, "0")}`;

  return (
    <div className={footer.footer}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className={footer.innerFooter}>
              <p>
                Copyright <i className="fas fa-copyright" /> Galax Crop
              </p>
              <p>{formattedDate}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
