import React, { useRef, useCallback, useEffect, useState } from "react";
import {
  ReactFlow,
  ReactFlowProvider,
  addEdge,
  useNodesState,
  useEdgesState,
  Controls,
  useReactFlow,
  MiniMap,
} from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import "./flowChartIndex.css";
import { DnDProvider, useDnD } from "./DnDContext";
import Sidebar from "./Sidebar";
import CustomNode from "./CustomNode";
import { cdiscServices } from "../../../../../../Services/CDISC/cdiscServices";
import SplitPane from "react-split-pane";
import { BiCollapse, BiExpand } from "react-icons/bi";
import { CgChevronDown } from "react-icons/cg";
import MergeConfigurationToolsTab from "./DerivationTools/MergeConfigurationToolsTab";
import StatementTools from "./DerivationTools/StatementTools";
import SortTransposeConfigurationToolsTab from "./DerivationTools/SortTransposeConfigurationToolsTab";
import FunctionTools from "./DerivationTools/FunctionTools";
import ManipulationsTools from "./DerivationTools/ManipulationsTools";
import KeepDropColumnTools from "./DerivationTools/KeepDropColumnTools";

const initialNodes = [];

const DerivationData = ({ handleAddNewTab, domainId, studyId, onMouseDown, tableRef }) => {
  const reactFlowWrapper = useRef(null);
  const [activeTabs, setActiveTabs] = useState("configuration");
  const [isOpenProperty, setIsOpenProperty] = useState(false);
  const [isHalfScreen, setIsHalfScreen] = useState(false);
  const [derivationObject, setDerivationObject] = useState("");
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const { screenToFlowPosition } = useReactFlow();
  const [type] = useDnD();

  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge(params, eds)),
    []
  );

  const toggleHalfScreen = () => {
    setIsHalfScreen(!isHalfScreen);
  };

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();

      if (!type) {
        return;
      }

      const handleDrop = async () => {
        const position = screenToFlowPosition({
          x: event.clientX,
          y: event.clientY,
        });

        const newNode = {
          id: Math.random().toString().substr(2, 10),
          domainId: domainId,
          studyId: studyId,
          type: "custom",
          toolType: `${type}`,
          position,
          data: { label: `${type}` },
        };

        let userData = {};
        userData.studyId = studyId;
        userData.derivation = newNode;
        await cdiscServices.addNewDomainDerivation(userData);
        setNodes((nds) => nds.concat(newNode));
      };
      handleDrop();
    },
    [screenToFlowPosition, type]
  );

  const handleRemoveNode = async (id) => {
    let data = await cdiscServices.deleteDomainDerivationData(studyId, id);
    if (data?.statusCode === 200) {
      getDomainDerivationList();
    }
  };

  const getDomainDerivationList = async () => {
    let data = await cdiscServices.getDomainDerivationList(studyId, domainId);
    setNodes(data?.data || []);
    setEdges(data?.edgeData || []);
  };

  const updateDomainDerivation = async (changedNodes) => {
    onNodesChange(changedNodes);
    for (const node of changedNodes) {
      if (node.type === "position") {
        const updatedNodeData = {
          derivationId: node.id,
          position: node.position,
          measured: node.measured,
          studyId: studyId,
        };
        await cdiscServices.updateDomainDerivation(updatedNodeData);
      }
    }
  };

  const updateEdgeDomainDerivation = async (edges) => {
    for (const node of edges) {
      const updatedNodeData = {
        derivationId: node.source,
        target: node.target,
        edgeId: node.id,
        studyId: studyId,
      };
      await cdiscServices.updateDomainDerivation(updatedNodeData);
    }
  };
  useEffect(() => {
    updateEdgeDomainDerivation(edges);
  }, [studyId, edges]);

  useEffect(() => {
    getDomainDerivationList();
  }, [studyId, domainId]);

  let [crfDatasets, setCRFDatasets] = useState([]);
  let [datasetDeomain, setDatasetDeomain] = useState([]);
  const getStudyDataTemplates = async () => {
    let data = await cdiscServices.getStudyDataTemplates(studyId);
    setCRFDatasets(data?.data || []);
    let result = await cdiscServices.getCDISCDatasets(studyId);
    setDatasetDeomain(result?.data);
  };

  useEffect(() => {
    getStudyDataTemplates();
  }, [studyId]);

  return (
    <div>
      <SplitPane
        split="horizontal"
        className="position-relative"
        defaultSize={isOpenProperty ? "40%" : "100%"}
        style={{ height: "calc(100vh - 155px)" }}
      >
        <div className="dndflow position-relative h-100">
          <Sidebar />
          <div className="reactflow-wrapper" ref={reactFlowWrapper}>
            <ReactFlow
              nodes={nodes?.map((node) => ({
                ...node,
                data: {
                  ...node.data,
                  onRemove: handleRemoveNode,
                  getDomainDerivationList: getDomainDerivationList,
                  addTabs: handleAddNewTab,
                  setIsOpenProperty: setIsOpenProperty,
                  domainId: domainId,
                  id: node.id,
                  derivationId: node.derivationId,
                  primaryDataType: node.primaryDataType,
                  primaryDataset: node.primaryDataset,
                  secondaryDataType: node.secondaryDataType,
                  secondaryDataset: node.secondaryDataset,
                  primaryKey: node.primaryKey,
                  logStatus: node.logStatus,
                  logs: node.logs,
                  procedureType: node.procedureType,
                  toolType: node.toolType,
                  sortData: node.sortData,
                  byVar: node.byVar,
                  vars: node.vars,
                  idVar: node.idVar,
                  prefix: node.prefix,
                  suffix: node.suffix,
                  retainColumns: node.retainColumns,
                  retainCustoms: node.retainCustoms,
                  newColumnsCustomValue: node.newColumnsCustomValue,
                  newColumnsColumnValue: node.newColumnsColumnValue,
                  newColumnsFunctionValue: node.newColumnsFunctionValue,
                  keepColumns: node.keepColumns,
                  keepCustoms: node.keepCustoms,
                  dropColumns: node.dropColumns,
                  dropCustoms: node.dropCustoms,
                  cellDataset: node.cellDataset,
                  statements: node.statements,
                  customManipulation: node.customManipulation,
                  columnManipulation: node.columnManipulation,
                  functionManipulation: node.functionManipulation,
                  _id: node._id,
                  label: node.label || node?.data?.label,
                  setDerivationObject: setDerivationObject,
                  derivedData: nodes || [],
                },
              }))}
              edges={edges?.map((edge) => ({
                ...edge,
                markerEnd: {
                  type: "arrowclosed",
                  color: "#198754",
                },
                style: { stroke: "#16f474", strokeWidth: 1 },
              }))}
              onNodesChange={updateDomainDerivation}
              onEdgesChange={onEdgesChange}
              onConnect={onConnect}
              onDrop={onDrop}
              onDragOver={onDragOver}
              nodeTypes={{ custom: CustomNode }}
              fitView
            >
              <Controls />
              <MiniMap />
            </ReactFlow>
          </div>
        </div>
        {isOpenProperty && (
          <div
            className={`${isHalfScreen ? "full-screen" : ""}`}
            style={{ height: "100%" }}
          >
            <div
              className="hstack justify-content-between border-bottom px-2 pb-0 bg-light"
              style={{ padding: 5 }}
            >
              <div className="hstack me-1 gap-1">
                {[
                  "configuration",
                  "statement",
                  "manipulation",
                  "keep and Drop",
                  "functions",
                ].map((tab) => (
                  <button
                    key={tab}
                    className={`buttonForTabs px-2 ${activeTabs === tab ? "activee" : ""
                      }`}
                    onClick={() => setActiveTabs(tab)}
                    title={tab.charAt(0).toUpperCase() + tab.slice(1)}
                    style={{ borderRadius: "3px 3px 0px 0px" }}
                  >
                    {tab.charAt(0).toUpperCase() + tab.slice(1)}
                  </button>
                ))}
              </div>
              <div className="hstack gap-1">
                <button
                  className="tabButtons fontSize12 border-0"
                  style={{ borderRadius: "3px 3px 0px 0px" }}
                  title={"Hide"}
                  onClick={() => setIsOpenProperty(false)}
                >
                  <CgChevronDown />
                </button>
                <button
                  className="tabButtons fontSize12 border-0"
                  style={{ borderRadius: "3px 3px 0px 0px" }}
                  title={!isHalfScreen ? "Full Mode" : "Original Mode"}
                  onClick={toggleHalfScreen}
                >
                  {isHalfScreen ? <BiCollapse /> : <BiExpand />}
                </button>
              </div>
            </div>
            {activeTabs === "configuration" ? (
              derivationObject?.toolType === "Merge" ||
                derivationObject?.toolType === "Append" ? (
                <MergeConfigurationToolsTab
                  studyId={studyId}
                  crfDatasets={crfDatasets}
                  derivationId={derivationObject?.id}
                  type={derivationObject?.toolType}
                  getDomainDerivationList={getDomainDerivationList}
                  derivedData={derivationObject?.derivedData}
                  datasetDeomain={datasetDeomain}
                />
              ) : derivationObject?.toolType === "Sort" ||
                derivationObject?.toolType === "Manipulation" ||
                derivationObject?.toolType === "Transpose" ? (
                <SortTransposeConfigurationToolsTab
                  studyId={studyId}
                  crfDatasets={crfDatasets}
                  derivationId={derivationObject?.id}
                  type={derivationObject?.toolType}
                  getDomainDerivationList={getDomainDerivationList}
                  derivedData={derivationObject?.derivedData}
                  datasetDeomain={datasetDeomain}
                />
              ) : (
                ""
              )
            ) : activeTabs === "statement" ? (
              <StatementTools
                isHalfScreen={isHalfScreen}
                studyId={studyId}
                derivationId={derivationObject?.id}
                getDomainDerivationList={getDomainDerivationList}
              />
            ) : activeTabs === "keep and Drop" ? (
              <KeepDropColumnTools
                studyId={studyId}
                derivationId={derivationObject?.id}
                getDomainDerivationList={getDomainDerivationList}
              />
            ) : activeTabs === "manipulation" ? (
              <ManipulationsTools
                studyId={studyId}
                derivationId={derivationObject?.id}
                getDomainDerivationList={getDomainDerivationList}
              />
            ) : activeTabs === "functions" ? (
              <FunctionTools
                studyId={studyId}
                tableRef={tableRef}
                onMouseDown={onMouseDown}
                derivationId={derivationObject?.id}
                getDomainDerivationList={getDomainDerivationList}
              />
            ) : (
              ""
            )}
          </div>
        )}
      </SplitPane>
    </div>
  );
};

const DerivationDataWrapper = (props) => (
  <ReactFlowProvider>
    <DnDProvider>
      <DerivationData {...props} />
    </DnDProvider>
  </ReactFlowProvider>
);

export default DerivationDataWrapper;
