import React, { useEffect, useState } from "react";
import { Form, Offcanvas, Table } from "react-bootstrap";
import { randomizerServices } from "../../../Services/IWRS/randomizerServices";
import { useParams } from "react-router";

const RequestAllData = ({
    Show,
    Hide,
    onMouseDown,
    tableRef,
    requestedData,
    setRequestedData,
    getRandomizerMetadataData
}) => {
    let { studyId } = useParams()
    let studyMode = JSON.parse(localStorage.getItem("StudyMode"));
    const [requestRemark, setRequestRemark] = useState("");
    const [requestData, setRequestData] = useState("");
    const [loader, setLoader] = useState(false);
    const requestForUnblined = async () => {
        setLoader(true)
        let userData = {}
        userData.studyMode = studyMode
        userData.studyId = studyId
        userData.requestType = "all"
        userData.randomizedId = requestedData?._id
        userData.requestRemark = requestRemark
        await randomizerServices.requestForUnblined(userData)
        setLoader(true)
        getRandomizerMetadataData()
        Hide()
    }

    const findSingleAllRequestDetails = async (randomizedId) => {
        let data = await randomizerServices.findSingleAllRequestDetails(studyId, randomizedId, studyMode)
        setRequestData(data?.data)
        setRequestRemark(data?.data?.requestRemark)
    }

    useEffect(() => {
        if (requestedData) {
            findSingleAllRequestDetails(requestedData?._id)
        }
        setRequestRemark("")
    }, [requestedData])
    return (
        <>
            <Offcanvas show={Show} onHide={Hide} style={{ width: 400 }} placement="start">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Request All</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className="p-0">
                    <div className="overflow-auto"
                        style={{ height: "calc(100vh - 89px)" }}
                    >
                        <div className="table-container tableLibrarySection">
                            <Table
                                id="resizable-table" striped
                                className="m-0 custom-table"
                                ref={tableRef}
                                bordered
                            >
                                <thead className="thead-sticky">
                                    <tr>
                                        <th onMouseDown={(e) => onMouseDown(e, 0)}>
                                            Attribute
                                        </th>
                                        <th onMouseDown={(e) => onMouseDown(e, 1)}>
                                            Value
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Title</td>
                                        <td>{requestedData?.name}</td>
                                    </tr>

                                    <tr>
                                        <td>Descriptions</td>
                                        <td>{requestedData?.description}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                        <div>
                            <Form.Group className="p-2">
                                <Form.Label style={{ fontWeight: 500 }} className="p-0">Remarks:</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    onChange={(e) => setRequestRemark(e.target.value)}
                                    value={requestRemark}
                                    rows={4}
                                    disabled={requestData}
                                    placeholder="Add Remarks"
                                    style={{ marginTop: -7 }}
                                />
                            </Form.Group>
                            {requestData &&
                                <Form.Group className="mt-2 p-2">
                                    <Form.Label style={{ fontWeight: 600 }} className="p-0 text-danger">Request Already sent.</Form.Label>
                                </Form.Group>
                            }
                        </div>
                    </div>
                    {!requestData &&
                        <div>
                            <div className="p-2 hstack justify-content-end gap-2 position-sticky bottom-0">
                                <button
                                    onClick={requestForUnblined}
                                    disabled={loader}
                                    className="updateProfile"
                                    style={{ width: 75, fontSize: "13px" }}
                                >
                                    Request
                                </button>
                            </div>
                        </div>
                    }
                </Offcanvas.Body>
            </Offcanvas>

        </>
    );
};

export default RequestAllData;
