import React, { useEffect, useRef } from "react";

const LineScaleValues = ({
  startLinePoint = 0,
  endLinePoint = 100,
  handleLineScaleChange,
  lineScaleInput,
  index,
  item,
  valueData,
  disabledData,
}) => {
  const rangeRef = useRef(null);
  const tooltipRef = useRef(null);

  const updateController = (obj) => {
    const thumbPos = getThumbPercentage(obj);
    const thumbPosPX = getThumbPosition(obj);

    if (obj.getAttribute("data-tooltip") !== "false") {
      updateTooltip(tooltipRef.current, obj.value, thumbPosPX, thumbPos);
    } else {
      if (tooltipRef.current) tooltipRef.current.style.display = "none";
    }

    updateTrackColor(obj, thumbPos);
    
    if (obj.value > 0 && !disabledData) {
      handleLineScaleChange(obj.value, index, item);
    }
  };

  const getThumbPosition = (obj) => {
    const range = obj.max - obj.min;
    return Math.round(((obj.value - obj.min) / range) * obj.offsetWidth);
  };

  const getThumbPercentage = (obj) => {
    const range = obj.max - obj.min;
    return Math.round(((obj.value - obj.min) / range) * 100);
  };

  const updateTooltip = (tooltip, value, position, percentage) => {
    if (!tooltip) return;
    tooltip.innerHTML = value;
    tooltip.style.left = `${position}px`;

    const tooltipStartPos = 26;
    let tooltipPosFix = percentage / 1.8;
    if (percentage > 0) {
      tooltipPosFix = percentage / 2.2;
    }
    tooltip.style.transform = `translate(-${
      tooltipStartPos + tooltipPosFix
    }%, 0)`;
  };

  const updateTrackColor = (obj, percentage) => {
    const rangeBg = `linear-gradient(to right, #FFCCCC 0%, #FF0000 ${percentage}%, #e0e0e0 ${percentage}%, #e0e0e0 100%)`;
    obj.style.background = rangeBg;
  };

  useEffect(() => {
    const rangeInput = rangeRef.current;

    const handleInput = () => updateController(rangeInput);

    if (rangeInput) {
      updateController(rangeInput);
      rangeInput.addEventListener("input", handleInput);
      rangeInput.addEventListener("change", handleInput);
    }

    if (rangeInput) {
      const initialPercentage = getThumbPercentage(rangeInput);
      updateTrackColor(rangeInput, initialPercentage);
    }

    return () => {
      if (rangeInput) {
        rangeInput.removeEventListener("input", handleInput);
        rangeInput.removeEventListener("change", handleInput);
      }
    };
  }, [valueData?.value]);

  return (
    <div className="custom-rangeslider">
      <div className="hstack">
        <div className="vr"></div>
        <input
          ref={rangeRef}
          className={disabledData ? "custom-rangeInputDisabled" : "custom-rangeInput"}
          id="range-slider1"
          type="range"
          min={startLinePoint}
          max={endLinePoint}
          defaultValue={startLinePoint}
          disabled={disabledData}
          value={valueData?.value ? valueData?.value : lineScaleInput?.[index]?.value}
          step="1"
          data-tooltip="true"
          aria-controls="rangeTooltip rangeLabel"
          aria-live="polite"
        />
        <div className="vr"></div>
      </div>
        <span ref={tooltipRef} className="custom-rangeslider__tooltip">
          {startLinePoint}
        </span>
      <span className="custom-rangeslider__label">
        <span className="custom-rangeslider__label-min">{startLinePoint}</span>
        <span className="custom-rangeslider__label-max">{endLinePoint}</span>
      </span>
    </div>
  );
};

export default LineScaleValues;
