import config from "../../config/config.json";
import { headers } from "../../Helpers/Headers";
import {
  handleResponse,
  headersWithAuth,
  headersWithAuthWithoutBody,
} from "../../Helpers/Responses";

export const studyOnboardingService = {
  currentTime,

  //Projects
  createProjectID,
  getProjects,
  getAllProjectsofClients,
  updateProjectIDStatus,
  getProjectIdByStudyId,
  updateProjectID,

  //Studies
  createStudyID,
  configurationStudyID,
  getStudies,
  getSingleStudies,
  getStudiesUserDashboard,
  getStudiesClientDashboard,
  updateSudyIDStatus,
  etmfStudyDatabaseLockAndUnlock,
  getETMFStudyDatabaseLogs,
  etmfStudyDatabaseStatus,

  //Control
  addAgeGroup,
  getAgeGroupList,
  updateAgeGroupData,
  addGender,
  getGenderList,
  updateGenderData,
  addUnits,
  getUnitsList,
  updateUnitsData,
  verifyStudyData,
  getVerifiedStudyData,
  getStudyModuleData,

  //Study login Bookmarks
  addStudyLoginBookmarks,
  getStudyLoginBookmarks,
  deleteStudyLoginBookmarks,

  sendStudyDatabaseStatusRequest,
  getStudyDatabaseStatusRequest,
  approvalStudyDatabaseStatusRequest,
  getStudyProjectDetails,

  // protocol metadata
  addProtocolMetadata,
  updateProtocolMetadataData,
  deleteProtocolMetadataData,
  getProtocolMetadataData,
};

// Add Protocol
async function addProtocolMetadata(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}addProtocolMetadata`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  return data;
}

// Update Protocol
async function updateProtocolMetadataData(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}updateProtocolMetadataData`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  return data;
}

// Update Project ID
async function updateProjectID(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}updateProjectID`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  return data;
}

// Delete Protocol
async function deleteProtocolMetadataData(id) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}deleteProtocolMetadataData/${id}`,
    headersWithAuthWithoutBody("DELETE", headers)
  );
  const data = await response.json();
  return data;
}

// Get Protocols
async function getProtocolMetadataData(studyId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getProtocolMetadataData/${studyId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function currentTime() {
  let today = new Date();
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0");
  let yyyy = today.getFullYear();
  let currentDate = yyyy + "-" + mm + "-" + dd;
  return currentDate;
}

async function getVerifiedStudyData(studyId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getVerifiedStudyData/${studyId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getStudyModuleData(studyId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getStudyModuleData/${studyId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getProjectIdByStudyId(id) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getProjectIdByStudyId/${id}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getProjects(id) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getProjects/${id}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getAllProjectsofClients(id) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getAllProjectsofClients/${id}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getStudies(id) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getStudies/${id}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getSingleStudies(id) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getSingleStudies/${id}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getStudiesUserDashboard() {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getStudiesUserDashboard`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getStudiesClientDashboard() {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getStudiesClientDashboard`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getETMFStudyDatabaseLogs(studyId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getETMFStudyDatabaseLogs/${studyId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function etmfStudyDatabaseStatus(studyId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}etmfStudyDatabaseStatus/${studyId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function updateProjectIDStatus(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}updateProjectIDStatus`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
}

async function updateSudyIDStatus(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}updateSudyIDStatus`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
}

async function etmfStudyDatabaseLockAndUnlock(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}etmfStudyDatabaseLockAndUnlock`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

async function createProjectID(datas) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}createProjectID`,
    headersWithAuth("POST", datas, headers)
  );
  const data = await response.json();
  await handleResponse(data);
}

async function verifyStudyData(datas) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}verifyStudyData`,
    headersWithAuth("POST", datas, headers)
  );
  const data = await response.json();
  await handleResponse(data);
}

async function createStudyID(datas) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}createStudyID`,
    headersWithAuth("POST", datas, headers)
  );
  const data = await response.json();
  await handleResponse(data);
}

async function configurationStudyID(datas) {
  await fetch(
    `${config.API_URL_USER_ONBOARDING}configurationStudyID`,
    headersWithAuth("PUT", datas, headers)
  );
}

// Controller API
async function addGender(datas) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}addGender`,
    headersWithAuth("POST", datas, headers)
  );
  const data = await response.json();
  await handleResponse(data);
}

async function updateGenderData(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}updateGenderData`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
}

async function getGenderList(studyId, currentPage, limit) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getGenderList/${studyId}/${currentPage}/${limit}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function addAgeGroup(datas) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}addAgeGroup`,
    headersWithAuth("POST", datas, headers)
  );
  const data = await response.json();
  await handleResponse(data);
}

async function updateAgeGroupData(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}updateAgeGroupData`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
}

async function getAgeGroupList(studyId, currentPage, limit) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getAgeGroupList/${studyId}/${currentPage}/${limit}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function addUnits(datas) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}addUnits`,
    headersWithAuth("POST", datas, headers)
  );
  const data = await response.json();
  await handleResponse(data);
}

async function updateUnitsData(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}updateUnitsData`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
}

async function getUnitsList(studyId, currentPage, limit) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getUnitsList/${studyId}/${currentPage}/${limit}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function addStudyLoginBookmarks(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}addStudyLoginBookmarks`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  await handleResponse(data);
}

async function deleteStudyLoginBookmarks(datas) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}deleteStudyLoginBookmarks`,
    headersWithAuth("DELETE", datas, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

async function getStudyLoginBookmarks() {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getStudyLoginBookmarks`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function sendStudyDatabaseStatusRequest(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}sendStudyDatabaseStatusRequest`,
    headersWithAuth("POST", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

async function approvalStudyDatabaseStatusRequest(userData) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}approvalStudyDatabaseStatusRequest`,
    headersWithAuth("PUT", userData, headers)
  );
  const data = await response.json();
  handleResponse(data);
  return data;
}

async function getStudyDatabaseStatusRequest(studyId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getStudyDatabaseStatusRequest/${studyId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}

async function getStudyProjectDetails(studyId) {
  const response = await fetch(
    `${config.API_URL_USER_ONBOARDING}getStudyProjectDetails/${studyId}`,
    headersWithAuthWithoutBody("GET", headers)
  );
  const data = await response.json();
  return data;
}
