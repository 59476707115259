import React from "react";
import { Form, Table } from "react-bootstrap";

const PASIFormValues = ({ item, valueData, handlePasiFormChange, pasiFormDataEntry, disabledData }) => {
  const sections = ["Head", "Arms", "Trunk", "Legs"];
  const radios = (section, category, labels, key) =>
    labels.map((label, idx) => (
      <Form.Check
        key={idx}
        type="radio"
        label={label}
        onChange={(e) =>
          handlePasiFormChange(
            "QSCAT",
            section,
            "QSTEST",
            category,
            "QSORRES",
            label,
            key,
            item,
            valueData,
            disabledData
          )
        }
        disabled={disabledData}
        checked={(pasiFormDataEntry?.[key]?.QSORRES || valueData?.[key]?.QSORRES) === label}
        name={`${section.toLowerCase()}${category}Group`}
      />
    ));


  const parseQSORRES = (value) => {
    if (value) {
      if (value?.includes('%')) {
        let finalValue = value === "0%" ? 0 : value === "<10%" ? 1 : value === "10-29%" ? 2 : value === "30-49%" ? 3 : value === "50-69%" ? 4 : value === "70-89%" ? 5 : value === "90-100%" ? 6 : 0;
        return finalValue;
      }
      return parseFloat(value);
    }
  };

  let HeadData;
  if (pasiFormDataEntry?.length > 0) {
    HeadData = pasiFormDataEntry?.filter(item => item?.QSCAT === "Head") || []
  } else {
    HeadData = valueData?.filter(item => item?.QSCAT === "Head") || []
  }

  let ArmsData;
  if (pasiFormDataEntry?.length > 0) {
    ArmsData = pasiFormDataEntry?.filter(item => item?.QSCAT === "Arms") || []
  } else {
    ArmsData = valueData?.filter(item => item?.QSCAT === "Arms") || []
  }

  let TrunkData;
  if (pasiFormDataEntry?.length > 0) {
    TrunkData = pasiFormDataEntry?.filter(item => item?.QSCAT === "Trunk") || []
  } else {
    TrunkData = valueData?.filter(item => item?.QSCAT === "Trunk") || []
  }

  let LegsData;
  if (pasiFormDataEntry?.length > 0) {
    LegsData = pasiFormDataEntry?.filter(item => item?.QSCAT === "Legs") || []
  } else {
    LegsData = valueData?.filter(item => item?.QSCAT === "Legs") || []
  }

  let headData1 = parseQSORRES(HeadData?.[0]?.QSORRES);
  let headData2 = parseQSORRES(HeadData?.[1]?.QSORRES);
  let headData3 = parseQSORRES(HeadData?.[2]?.QSORRES);
  let headData4 = parseQSORRES(HeadData?.[3]?.QSORRES);

  let armsData1 = parseQSORRES(ArmsData?.[0]?.QSORRES);
  let armsData2 = parseQSORRES(ArmsData?.[1]?.QSORRES);
  let armsData3 = parseQSORRES(ArmsData?.[2]?.QSORRES);
  let armsData4 = parseQSORRES(ArmsData?.[3]?.QSORRES);

  let trunkData1 = parseQSORRES(TrunkData?.[0]?.QSORRES);
  let trunkData2 = parseQSORRES(TrunkData?.[1]?.QSORRES);
  let trunkData3 = parseQSORRES(TrunkData?.[2]?.QSORRES);
  let trunkData4 = parseQSORRES(TrunkData?.[3]?.QSORRES);

  let legsData1 = parseQSORRES(LegsData?.[0]?.QSORRES);
  let legsData2 = parseQSORRES(LegsData?.[1]?.QSORRES);
  let legsData3 = parseQSORRES(LegsData?.[2]?.QSORRES);
  let legsData4 = parseQSORRES(LegsData?.[3]?.QSORRES);

  let HeadDataValue = 0.1 * (headData2 + headData3 + headData4) * headData1
  let ArmDataValue = 0.2 * (armsData2 + armsData3 + armsData4) * armsData1
  let TrunkDataValue = 0.3 * (trunkData2 + trunkData3 + trunkData4) * trunkData1
  let LegDataValue = 0.4 * (legsData2 + legsData3 + legsData4) * legsData1

  let finalPassiValue = HeadDataValue + ArmDataValue + TrunkDataValue + LegDataValue

  const renderSection = (section, i) => (
    <Table bordered className="border-start border-end" key={section}>
      <thead style={{ background: "#b8cfdf" }}>
        <tr>
          <th className="p-1"></th>
          <th className="p-1">
            <Form.Label className="m-0">{section}</Form.Label>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="p-1">
            <Form.Label className="mb-0 fw-bold">
              Percentage Affected Area
            </Form.Label>
          </td>
          <td>
            <div className="hstack gap-2 radioMap">
              {radios(section, "percentage", [
                "0%",
                "<10%",
                "10-29%",
                "30-49%",
                "50-69%",
                "70-89%",
                "90-100%",
              ],
                section === "Head" ? i + 0 : section === "Arms" ? i + 3 + 0 : section === "Trunk" ? i + 6 + 0 : i + 9 + 0
              )}
            </div>
          </td>
        </tr>
        {["Erythema", "Induration", "Desquamation"].map((category, idx) => (
          <tr key={idx}>
            <td className="p-1">
              <Form.Label className="mb-0">
                <b>{category}</b> {category === "Erythema" && "(redness)"}
                {category === "Induration" && "(thickness)"}
                {category === "Desquamation" && "(scaling)"}
              </Form.Label>
            </td>
            <td>
              <div className="hstack gap-2 radioMap">
                {radios(section, category.toLowerCase(), [
                  "0",
                  "1",
                  "2",
                  "3",
                  "4",
                ],
                  section === "Head" ? i + idx + 1 : section === "Arms" ? i + 3 + idx + 1 : section === "Trunk" ? i + 6 + idx + 1 : i + 9 + idx + 1
                )}
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );

  return <div className="mt-3">{sections.map(renderSection)}
    <Form.Label className="m-0"><b>Passi Value:</b> {finalPassiValue ? (finalPassiValue).toFixed(2) : 0}</Form.Label>
  </div>;
};

export default PASIFormValues;
