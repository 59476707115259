import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import { Button, Offcanvas, Table } from "react-bootstrap";
import {
  NoDataFoundTable,
  SpinerDataLoader,
} from "../../../../../NoDataFoundTable/NoDataFoundTable";
import SplitPane from "react-split-pane";
import UseToggle from "../../../../../../Hooks/UseToggle";
import { studyOnboardingService } from "../../../../../../Services/studyServices/studyVisitsServices";

const EventReviewHistory = ({ onMouseDown1, tableRef1, studyUniqueId }) => {
  const [reviewHistory, setReviewHistory] = useState([]);
  const [reviewHistoryData, setReviewHistoryData] = useState([]);
  const [tableLoader, setTableLoader] = useState(false);

  const { setToggle, toggle } = UseToggle();
  const [comment, setComment] = useState("");

  const getStudySiteReviewData = async () => {
    setTableLoader(true);
    let data = await studyOnboardingService.getStudyVisitReviewData(studyUniqueId);
    setReviewHistory(data?.data || []);
    setTableLoader(false)
  };

  useEffect(() => {
    getStudySiteReviewData();
  }, [studyUniqueId]);

  const handleRowReviewMessage = (e, item) => {
    setToggle();
    setReviewHistoryData(item)
    setComment(comment);
  };

  return (
    <>
      <SplitPane split="vertical" minSize={20} defaultSize={"100%"} style={{ height: "84%" }}>
        {tableLoader ? (
          <SpinerDataLoader />
        ) : reviewHistory?.length === 0 ? (
          <NoDataFoundTable MSG="Review history not found" />
        ) : (
          <>
            <Table
              id="resizable-table"
              striped
              className="custom-table"
              style={{ tableLayout: "auto" }}
              hover
              bordered
              ref={tableRef1}
            >
              <thead className="position-sticky top-0">
                <tr>
                  <th
                    onMouseDown1={(e) => onMouseDown1(e, 0)}
                    style={{ width: 40 }}
                  >
                    Sr.
                  </th>
                  <th
                    onMouseDown1={(e) => onMouseDown1(e, 1)}
                  >
                    Site Id
                  </th>
                  <th
                    className="text-center"
                    onMouseDown1={(e) => onMouseDown1(e, 2)}
                  >
                    Version
                  </th>
                  <th className="text-center" onMouseDown1={(e) => onMouseDown1(e, 3)}>Status</th>
                  <th onMouseDown1={(e) => onMouseDown1(e, 4)}>Reviewed By</th>
                  <th onMouseDown1={(e) => onMouseDown1(e, 5)}>Review Date</th>
                  <th onMouseDown1={(e) => onMouseDown1(e, 6)}>Review Time</th>
                </tr>
              </thead>
              <tbody>
                {reviewHistory?.map((item, index) => (
                  <tr className="relative CP" 
                  onClick={(e) => handleRowReviewMessage(e, item)}
                  >
                    <td>{index + 1}.</td>
                    <td>{item.visitId}</td>
                    <td className="text-center">{item.version}</td>
                    <td className={`text-center text-capitalize fw-bold ${item.status === "verified" ? "text-success" : "text-secondary"}`}>{item.status}</td>
                    <td title={item.reviewerID}>{item.fullName}</td>
                    <td>
                      <Moment format="DD MMM YYYY">{item?.createdAt}</Moment>
                    </td>
                    <td>
                      <Moment format="HH:mm:ss">{item?.createdAt}</Moment>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </>
        )}
      </SplitPane>
      <Offcanvas
        show={!toggle}
        onHide={setToggle}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Details</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="table-container tableLibrarySection">
            <Table
              id="resizable-table"
              striped
              className="m-0 custom-table"
              ref={tableRef1}
              bordered
            >
              <thead className="thead-sticky">
                <tr>
                  <th onMouseDown1={(e) => onMouseDown1(e, 0)}>Attribute</th>
                  <th onMouseDown1={(e) => onMouseDown1(e, 1)}>Value</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Site Id</td>
                  <td>{reviewHistoryData?.visitId}</td>
                </tr>
                <tr>
                  <td>Version</td>
                  <td>{reviewHistoryData?.version}</td>
                </tr>
                <tr>
                  <td>Status</td>
                  <td className={`text-capitalize fw-bold ${reviewHistoryData?.status === "verified" ? "text-success" : "text-secondary"}`}>{reviewHistoryData?.status}</td>
                </tr>
                <tr>
                  <td>Reviewed By</td>
                  <td title={reviewHistoryData?.reviewerID}>{reviewHistoryData?.fullName}</td>
                </tr>
                <tr>
                  <td>Review Date</td>
                  <td>
                    <Moment format="DD MMM YYYY">{reviewHistoryData?.createdAt}</Moment>
                  </td>
                </tr>
                <tr>
                  <td>Review Time</td>
                  <td>
                    <Moment format="HH:mm:ss">{reviewHistoryData?.createdAt}</Moment>
                  </td>
                </tr>
                <tr>
                  <td>Remark</td>
                  <td><div style={{
                    whiteSpace: 'pre-wrap',
                    overflowY: 'auto',
                    maxHeight: '150px',
                    padding: '5px',
                  }}>
                    {reviewHistoryData?.review}
                  </div></td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default EventReviewHistory;
