import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";

const RandomizeSideData = ({ siteList, siteId, setSiteId, studyRandomizerSingleRandomize, factorData, handleSelectChange, selectOption, setRandomizationNumber, subjectRandomNumber, randomizationNumber, setSubjectRandomNumber, randomizerList, studyMode, setSubjectFactor, subjectFactor, seedNumber, formData }) => {
  return (
    <>
      <div className="p-2">
        <Form.Group className="mb-2">
          <Form.Label className="mb-1 fw-bold">Site</Form.Label>
          <Form.Select
            onChange={(e) => {
              setSiteId(e.target.value);
              setSubjectRandomNumber(e.target[e.target.selectedIndex].id);
              setSubjectFactor(e.target[e.target.selectedIndex].title);
            }}
            style={{ fontSize: 11 }}>
            <option>Select Site List</option>
            {siteList?.map((site) => (
              <option
                id={JSON.stringify(studyMode === "DBP MODE" ? site?.DBPRandoNumberArray : studyMode === "QC Mode" ? site?.QCRandoNumberArray : studyMode === "Live Mode" && site?.LiveRandoNumberArray)}
                title={JSON.stringify(site?.factor)}
                key={site._id}
                selected={siteId === site.siteIdLabel}
                value={site.siteIdLabel}
              >
                {site.siteIdLabel}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
        {factorData?.map((item, index) => (
          selectOption?.some(data => ((data.name === item.name) && (data.randomize === "yes"))) &&
          <Form.Group key={index} className="mb-2">
            <Form.Label className="mb-1 fw-bold">{item.name}</Form.Label>
            <Form.Select
              style={{ fontSize: 11 }}
              onChange={(e) => handleSelectChange(item.name, e.target.value, index)}
            >
              <option>Select {item.name}</option>
              {item?.options?.map((data, key) => (
                (selectOption?.some(element => ((element.option === data.option) && (element.name === item.name) && (element.randomize === "yes"))) && subjectFactor?.some(item => item === data.option)) ?
                  <option key={key} value={data.option}>
                    {data.option}
                  </option>
                  : ""
              ))}
            </Form.Select>
          </Form.Group>
        ))}
        {subjectRandomNumber?.length > 0 &&
          <Form.Group className="mb-2">
            <Form.Label className="mb-1 fw-bold">Subject Random Number</Form.Label>
            <Form.Select onChange={(e) => setRandomizationNumber(e.target.value)} style={{ fontSize: 11 }}>
              <option>Select Subject Random Number</option>
              {subjectRandomNumber?.map((random, index) => (
                randomizerList.some(item => item.randomizationNumber === random) ? "" :
                  <option key={index} selected={randomizationNumber === random} value={random}>
                    {random}
                  </option>
              ))}
            </Form.Select>
          </Form.Group>
        }
        <div className="text-end mb-3 px-1">
          <button
            disabled={!siteId || Object.keys(formData)?.length === 0 || !randomizationNumber || !seedNumber}
            onClick={studyRandomizerSingleRandomize}
            className="updateProfile"
            style={{ width: 100, fontSize: "13px", marginRight: "5px" }}
          >
            Randomize
          </button>
          <Form.Label style={{ fontSize: 11 }} className="text-danger text-start mt-2"><b>{!seedNumber && "Seed Number is Missing, Please enter before generating randomization number (Select Trial Design -> Select Randomization List -> Enter Seed Number)"}</b></Form.Label>
        </div>
      </div>
    </>
  );
};

export default RandomizeSideData;
