import React, { useEffect, useState } from "react";
import {
  FaCircleCheck,
  FaCircleXmark,
} from "react-icons/fa6";
import Moment from "react-moment";
import { useParams } from "react-router";
import { Table } from "react-bootstrap";
import Pagination from "../../../../../../ETMF/Pagination/Pagination";
import {
  NoDataFoundTable,
  SpinerDataLoader,
} from "../../../../../../NoDataFoundTable/NoDataFoundTable";
import { studyOnboardingService } from "../../../../../../../Services/studyServices/studySubjectsServices";
import SplitPane from "react-split-pane";

const SiteSubjectStatusHistory = ({ onMouseDown, tableRef, subjectUniqueId, StudyMode }) => {
  const { studyId } = useParams();
  const [recentActivity, setRecentActivity] = useState([]);
  const [tableLoader, setTableLoader] = useState(false);

  const getStudySubjectStatusHistoryData = async () => {
    setTableLoader(true);
    let data = await studyOnboardingService.getStudySubjectStatusHistoryData(
      studyId,
      StudyMode,
      subjectUniqueId
    );
    setRecentActivity(data?.data || []);
    setTableLoader(false);
  };

  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(15);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = recentActivity?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(recentActivity?.length / itemsPerPage);

  useEffect(() => {
    getStudySubjectStatusHistoryData();
  }, [subjectUniqueId]);
  return (
    <>
      <SplitPane split="vertical" minSize={20} defaultSize={"100%"}>
        {tableLoader ? (
          <SpinerDataLoader />
        ) : recentActivity?.length === 0 ? (
          <NoDataFoundTable MSG="Activity not found" />
        ) : (
          <>
            <Table
              id="resizable-table"
              striped
              className="custom-table"
              style={{ tableLayout: "auto" }}
              hover
              ref={tableRef}
            >
              <thead className="position-sticky" style={{ top: -1 }}>
                <tr>
                  <th
                    onMouseDown={(e) => onMouseDown(e, 0)}
                    style={{ width: 40 }}
                  >
                    Sr.
                  </th>
                  <th
                    onMouseDown={(e) => onMouseDown(e, 1)}
                  >
                    Status
                  </th>
                  <th
                    onMouseDown={(e) => onMouseDown(e, 2)}
                  >
                    Updated Date
                  </th>
                  <th onMouseDown={(e) => onMouseDown(e, 3)}>Reason</th>
                </tr>
              </thead>
              <tbody>
                {result?.map((item, index) => (
                  <tr className="relative">
                    <td>{index + startIndex + 1}.</td>
                    <td className="text-capitalize">
                      {item.status}
                    </td>
                    <td>{item.updatedDate}</td>
                    <td>{item.reason}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div className="position-sticky" style={{ bottom: 24 }}>
              <Pagination
                itemsPerPage={itemsPerPage}
                setItemsPerPage={setItemsPerPage}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
                totalPage={totalPage}
                totalItems={recentActivity?.length}
              />
            </div>
          </>
        )}
      </SplitPane>
    </>
  );
};

export default SiteSubjectStatusHistory;
