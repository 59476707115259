import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import SplitPane from "react-split-pane";
import { useParams } from "react-router";
import { AiOutlinePlus } from "react-icons/ai";
import AddAppendixData from "./AddAppendixData";
import { Spinner, Table } from "react-bootstrap";
import AppendixFilterData from "./AppendixFilterData";
import UseToggle from "../../../../../Hooks/UseToggle";
import { TbLayoutSidebarRightExpand } from "react-icons/tb";
import AppendixTableProperties from "./AppendixTableProperties";
import Pagination from "../../../../ETMF/Pagination/Pagination";
import { cdrgStudyServices } from "../../../../../Services/CDISC/cdrgStudyServices";

const AppendixInclusionMetadata = ({ onMouseDown, tableRef }) => {
  const { studyId } = useParams();
  const [version, setVersion] = useState("");
  const [loader, setLoader] = useState(false);
  const [iETESTCD, setIETESTCD] = useState("");
  const [category, setCategory] = useState("");
  const [criterion, setCriterion] = useState("");
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [appendixData, setAppendixData] = useState([]);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [appendixDataId, setAppendixDataId] = useState("");
  const [highlightedRow, setHighlightedRow] = useState(null);
  const [isOpenProperty, setIsOpenProperty] = useState(false);
  const { toggle, setToggle, toggle1, setToggle1 } = UseToggle();

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  const getStudyAppendixIncExcData = async () => {
    let data = await cdrgStudyServices.getStudyAppendixIncExcData(studyId);
    setAppendixData(data?.data);
  };

  const updateAppendixData = (item) => {
    setAppendixDataId(item);
    setIsFormOpen(true)
  }
  const deleteStudyAppendixIncExcs = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Delete this Appendix Data?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = await cdrgStudyServices.deleteStudyAppendixIncExcs(id, studyId);
        if (data?.statusCode === 200) {
          getStudyAppendixIncExcData();
        }
      }
    });
  };

  const cleanInputBox = () => {
    setIsFormOpen(!isFormOpen)
    setVersion("");
    setAppendixDataId("")
    setCategory("");
    setIETESTCD("");
    setCriterion("");
  };

  useEffect(() => {
    getStudyAppendixIncExcData();
  }, []);

  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(15);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = appendixData?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(appendixData?.length / itemsPerPage);

  return (
    <>
      <div className={`code-list-container ${isFullScreen ? "full-screen" : ""}`}>
        <div
          className="hstack justify-content-between w-100 border-bottom toolbar"
          style={{ padding: "4.6px 9px" }}
        >
          <div className="hstack gap-2">
            <button
              className="tabButtons fontSize12 border-0"
              title="Upload New Metadata"
              onClick={() => cleanInputBox()}
            >
              {isFormOpen ? <TbLayoutSidebarRightExpand /> : <AiOutlinePlus />}
            </button>
            <div className="vr"></div>
            <i
              title="Table Properties"
              class="fa-solid fa-table-list CP p-1 fontSize12"
              onClick={setToggle}
              style={{ borderRadius: 3 }}
            ></i>
            <div className="vr"></div>
            <i
              title="Filter Table Data"
              class="fa-solid fa-filter CP p-1 fontSize12"
              onClick={setToggle1}
              style={{ borderRadius: 3 }}
            ></i>
          </div>
          <i
            class={`fa-solid ${isFullScreen ? "fa-minimize" : "fa-maximize"}`}
            title={!isFullScreen ? "Full Mode" : "Original Mode"}
            onClick={toggleFullScreen}
          ></i>
        </div>
        <div className="position-relative">
          <SplitPane
            split="horizontal"
            style={{
              height: isFullScreen ? "calc(100vh - 35px)" : "calc(100vh - 187px)",
            }}
            defaultSize={isOpenProperty ? "40%" : "100%"}
          >
            <SplitPane
              split="vertical"
              minSize={20}
              defaultSize={isFormOpen ? "20%" : "100%"}
            >
              {isFormOpen && (
                <AddAppendixData
                  studyId={studyId}
                  version={version}
                  category={category}
                  iETESTCD={iETESTCD}
                  criterion={criterion}
                  setVersion={setVersion}
                  setCategory={setCategory}
                  setIETESTCD={setIETESTCD}
                  setCriterion={setCriterion}
                  cleanInputBox={cleanInputBox}
                  appendixDataId={appendixDataId}
                  setAppendixDataId={setAppendixDataId}
                  getStudyAppendixIncExcData={getStudyAppendixIncExcData}
                />
              )}
              <>
                {loader ? (
                  <div className="hstack justify-content-center h-100">
                    <Spinner />
                  </div>
                ) : result?.length === 0 ? (
                  <div className="hstack justify-content-center h-100">
                    Data not found!
                  </div>
                ) : (

                  <Table
                    id="resizable-table"
                    ref={tableRef}
                    striped
                    className="m-0 custom-table"
                  >
                    <thead className="thead-sticky">
                      <tr>
                        <th
                          onMouseDown={(e) => onMouseDown(e, 0)}
                          style={{ width: 40 }}
                          className="text-center"
                        >
                          Sr.
                        </th>
                        <th onMouseDown={(e) => onMouseDown(e, 1)}>Protocol / Amendment Version</th>
                        <th onMouseDown={(e) => onMouseDown(e, 2)}>Category</th>
                        <th onMouseDown={(e) => onMouseDown(e, 3)}>IETESTCD</th>
                        <th onMouseDown={(e) => onMouseDown(e, 4)}>Full Text of Criterion</th>
                        <th
                          onMouseDown={(e) => onMouseDown(e, 5)}
                          style={{ width: 80 }}
                          className="text-center"
                        >
                          Update
                        </th>
                        <th
                          onMouseDown={(e) => onMouseDown(e, 6)}
                          style={{ width: 80 }}
                          className="text-center"
                        >
                          Delete
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {result?.map((item, index) => (
                        <tr key={index}
                          onClick={() => {
                            setHighlightedRow(index);
                          }}
                          className={`position-relative cursor-pointer ${highlightedRow === index && "activeMenu"
                            }`}
                        >
                          <td className="text-center">{index + startIndex + 1}</td>
                          <td>{item.version}</td>
                          <td className="text-wrap">
                            {item.category}
                          </td>
                          <td>{item.IETESTCD}</td>
                          <td>{item.criterion}</td>
                          <td className="text-center">
                            <button
                              onClick={() => updateAppendixData(item)}
                              title="Update Acronys"
                              className="text-white border-success bg-success border-0"
                              style={{ fontSize: 10, borderRadius: 3 }}
                            >
                              Update
                            </button>
                          </td>
                          <td className="cursor-pointer text-center">
                            <button
                              className="text-white border-danger bg-danger border-0"
                              style={{ fontSize: 10, borderRadius: 3 }}
                              title="Delete Acronys"
                              onClick={() => deleteStudyAppendixIncExcs(item._id)}
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
                <div className="position-sticky bottom-0">
                  <Pagination
                    totalPage={totalPage}
                    pageNumber={pageNumber}
                    itemsPerPage={itemsPerPage}
                    totalItems={appendixData?.length}
                    setPageNumber={setPageNumber}
                    setItemsPerPage={setItemsPerPage}
                  />
                </div>
              </>
            </SplitPane>
          </SplitPane>
        </div>
      </div>
      <AppendixTableProperties
        Show={!toggle}
        Hide={setToggle}
        Title={"Table Properties"}
      />
      <AppendixFilterData
        Show={!toggle1}
        Hide={setToggle1}
        Title={"Filter Appendix Data"}
      />
    </>
  );
};

export default AppendixInclusionMetadata;
