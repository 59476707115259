import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { studyOnboardingService } from "../../../../../../../Services/studyServices/studySitesServices";
import Moment from "react-moment";
import { SpinerDataLoader } from "../../../../../../NoDataFoundTable/NoDataFoundTable";
import SplitPane from "react-split-pane";

const StudySiteAttributeTable = ({ siteUniqueId, tableRef1, onMouseDown1 }) => {
  const [attributeDetails, setAttributeDetails] = useState("");
  const [loader, setLoader] = useState(false);
  const getStudySiteAttributeByID = async (siteUniqueId) => {
    setLoader(true);
    let data = await studyOnboardingService.getStudySiteAttributeByID(
      siteUniqueId
    );
    setAttributeDetails(data?.data);
    setLoader(false);
  };

  const getStatusColor = (status) => {
    switch (status?.toLowerCase()) {
      case "verified":
        return "green";
      case "pending":
        return "gray";
      default:
        return "inherit";
    }
  };

  useEffect(() => {
    getStudySiteAttributeByID(siteUniqueId);
  }, [siteUniqueId]);
  return (
    <>
      <SplitPane
        split="vertical"
        minSize={20}
        defaultSize="100%"
        style={{ height: "84%" }}
      >
        {loader ? (
          <SpinerDataLoader />
        ) : (
          <div className="table-container tableLibrarySection">
            <Table
              id="resizable-table"
              striped
              className="m-0 custom-table"
              ref={tableRef1}
              bordered
            >
              <thead className="thead-sticky">
                <tr>
                  <th onMouseDown1={(e) => onMouseDown1(e, 0)}>Attribute</th>
                  <th onMouseDown1={(e) => onMouseDown1(e, 1)}>Value</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {attributeDetails?.updatedRow
                      ? "Site Updated By"
                      : "Site Created By"}
                  </td>
                  <td>{attributeDetails?.uploadedBy}</td>
                </tr>

                <tr>
                  <td>
                    {attributeDetails?.updatedRow
                      ? "Site Updated Date & Time"
                      : "Site Created Date & Time"}
                  </td>
                  <td>
                    <Moment format="DD MMM YYYY | HH:mm" withTitle>
                      {attributeDetails?.createdAt}
                    </Moment>
                  </td>
                </tr>

                <tr>
                  <td>Site Version</td>
                  <td>{attributeDetails?.version}</td>
                </tr>
                <tr>
                  <td>Quality Checked By</td>
                  <td>{attributeDetails?.reviewedBy}</td>
                </tr>

                <tr>
                  <td>Quality Checked Status</td>
                  <td className="text-capitalize" style={{ color: getStatusColor(attributeDetails?.status) }}>
                    {attributeDetails?.status}
                  </td>
                </tr>

                <tr>
                  <td>Quality Checked Date & Time</td>
                  <td>
                    {attributeDetails?.reviewedBy ? (
                      <Moment format="DD MMM YYYY | HH:mm" withTitle>
                        {attributeDetails?.updatedAt}
                      </Moment>
                    ) : (
                      ""
                    )}
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        )}
      </SplitPane>
    </>
  );
};

export default StudySiteAttributeTable;
