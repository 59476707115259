import React, { useEffect, useState } from "react";
import SplitPane from "react-split-pane";
import { Table } from "react-bootstrap";
import { SpinerDataLoader } from "../../NoDataFoundTable/NoDataFoundTable";
import { randomizerServices } from "../../../Services/IWRS/randomizerServices";
import { useParams } from "react-router";
import Moment from "react-moment";
import Pagination from "../../ETMF/Pagination/Pagination";

const RandomizeTabRequestData = ({ randomizerId, siteList, onMouseDown1, tableRef1 }) => {
  let { studyId } = useParams();
  const [loaderAll, setLoaderAll] = useState("");
  const [loaderIndivisual, setLoaderIndivisual] = useState("");
  const [allRequestList, setAllRequestList] = useState([]);
  const [indivisualRequestList, setIndivisualrequestList] = useState([]);
  const [activeView, setActiveView] = useState("Individual");
  let studyMode = JSON.parse(localStorage.getItem("StudyMode"));

  const getRequestUnblinedHistory = async (randomizerId) => {
    setLoaderIndivisual(true);
    let data = await randomizerServices.getRequestUnblinedHistory(
      studyId,
      randomizerId,
      studyMode
    );
    let loadData = data?.data || []
    let finalData = loadData?.filter((item) => siteList?.map(data => data.siteIdLabel)?.includes(item.siteId));
    setIndivisualrequestList(finalData || []);
    setLoaderIndivisual(false);
  };

  const getAllRequestUnblinedHistory = async (randomizerId) => {
    setLoaderAll(true);
    let data = await randomizerServices.getAllRequestUnblinedHistory(
      studyId,
      randomizerId,
      studyMode
    );
    setAllRequestList(data?.data || []);
    setLoaderAll(false);
  };

  useEffect(() => {
    if (activeView === "Individual") {
      getRequestUnblinedHistory(randomizerId);
    } else if (activeView === "All") {
      getAllRequestUnblinedHistory(randomizerId);
    }
  }, [activeView, randomizerId]);

  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(15);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = allRequestList?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(allRequestList?.length / itemsPerPage);

  return (
    <>
      <SplitPane
        split="vertical"
        minSize={20}
        defaultSize="20%"
        style={{ height: "85%" }}
      >
        <div class="w-100 mt-1">
          <div
            className="mb-1 text-center"
            style={{ fontSize: 14, fontWeight: 500, color: "#103c5e" }}
          >
            Request type
          </div>
          <span
            className={`CP ps-3 small linkClass ${
              activeView === "Individual" ? "trueClass" : "falseClass"
            }`}
            onClick={() => setActiveView("Individual")}
          >
            <i
              className={` ${
                activeView === "Individual" ? "activeclient" : "inactiveclient"
              } mx-2`}
              aria-hidden="true"
            ></i>
            Individual
          </span>
          <span
            className={`CP ps-3 small linkClass ${
              activeView === "All" ? "trueClass" : "falseClass"
            }`}
            onClick={() => setActiveView("All")}
          >
            <i
              className={` ${
                activeView === "All" ? "activeclient" : "inactiveclient"
              } mx-2`}
              aria-hidden="true"
            ></i>
            All
          </span>
        </div>
        <>
          {activeView === "Individual" &&
            (loaderIndivisual ? (
              <SpinerDataLoader />
            ) : (
              <>
                <div className="table-container tableLibrarySection">
                  <Table
                    id="resizable-table"
                    striped
                    hover
                    className="m-0 custom-table"
                    ref={tableRef1}
                  >
                    <thead className="thead-sticky top-0">
                      <tr>
                        <th style={{ width: 45 }} onMouseDown1={(e) => onMouseDown1(e, 0)}>Sr.</th>
                        <th onMouseDown1={(e) => onMouseDown1(e, 1)}>Name</th>
                        <th onMouseDown1={(e) => onMouseDown1(e, 2)}>Site Id</th>
                        <th onMouseDown1={(e) => onMouseDown1(e, 3)}>Block</th>
                        <th onMouseDown1={(e) => onMouseDown1(e, 4)}>Subject Number</th>
                        <th onMouseDown1={(e) => onMouseDown1(e, 5)}>Randomization Number</th>
                        <th onMouseDown1={(e) => onMouseDown1(e, 6)}>Remark</th>
                        <th onMouseDown1={(e) => onMouseDown1(e, 7)}>Requested By</th>
                        <th onMouseDown1={(e) => onMouseDown1(e, 8)}>Requested Date | Time</th>
                      </tr>
                    </thead>
                    <tbody>
                      {indivisualRequestList?.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1 + startIndex}</td>
                          <td>{item.name}</td>
                          <td>{item.siteId}</td>
                          <td>{item.block}</td>
                          <td>{item.subjectNumber}</td>
                          <td>{item.randomizationNumber}</td>
                          <td>{item.requestRemark}</td>
                          <td>{item.requestedBy}</td>
                          <td>
                            <Moment format="DD MMM YYYY | HH:mm:ss">
                              {item.createdAt}
                            </Moment>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <div className="position-sticky bottom-0">
                    <Pagination
                      totalPage={totalPage}
                      pageNumber={pageNumber}
                      itemsPerPage={itemsPerPage}
                      setPageNumber={setPageNumber}
                      setItemsPerPage={setItemsPerPage}
                      totalItems={indivisualRequestList?.length}
                    />
                  </div>
                </div>
              </>
            ))}
          {activeView === "All" &&
            (loaderAll ? (
              <SpinerDataLoader />
            ) : (
              <>
                <div className="table-container tableLibrarySection">
                  <Table
                    id="resizable-table"
                    striped
                    hover
                    className="m-0 custom-table"
                    ref={tableRef1}
                  >
                    <thead className="thead-sticky top-0">
                      <tr>
                        <th style={{ width: 45 }} onMouseDown1={(e) => onMouseDown1(e, 0)}>Sr.</th>
                        <th onMouseDown1={(e) => onMouseDown1(e, 1)}>Name</th>
                        <th onMouseDown1={(e) => onMouseDown1(e, 2)}>Remark</th>
                        <th onMouseDown1={(e) => onMouseDown1(e, 3)}>Requested By</th>
                        <th onMouseDown1={(e) => onMouseDown1(e, 4)}>Requested Date | Time</th>
                      </tr>
                    </thead>
                    <tbody>
                      {result?.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1 + startIndex}</td>
                          <td>{item.name}</td>
                          <td>{item.requestRemark}</td>
                          <td>{item.requestedBy}</td>
                          <td>
                            <Moment format="DD MMM YYYY | HH:mm:ss">
                              {item.createdAt}
                            </Moment>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <div className="position-sticky bottom-0">
                    <Pagination
                      totalPage={totalPage}
                      pageNumber={pageNumber}
                      itemsPerPage={itemsPerPage}
                      setPageNumber={setPageNumber}
                      setItemsPerPage={setItemsPerPage}
                      totalItems={allRequestList?.length}
                    />
                  </div>
                </div>
              </>
            ))}
        </>
      </SplitPane>
    </>
  );
};

export default RandomizeTabRequestData;
