import React, { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap';
import { SpinerDataLoader } from '../NoDataFoundTable/NoDataFoundTable';
import { useParams } from 'react-router';
import Pagination from '../ETMF/Pagination/Pagination';
import Moment from 'react-moment';
import SplitPane from 'react-split-pane';
import { randomizerServices } from '../../Services/IWRS/randomizerServices';

const IWRSNotificationTableData = ({ onMouseDown, tableRef }) => {

    const [loader, setLoader] = useState(false);

    let { studyId } = useParams() 

    const [notifications, setNotifications] = useState([]);

    let studyMode = JSON.parse(localStorage.getItem("StudyMode"));

    const getRandomizerActivityForNotification = async () => {
        let data = await randomizerServices.getRandomizerActivityForNotification(studyId, studyMode)
        setNotifications(data?.data || [])
    }

    useEffect(() => {
        getRandomizerActivityForNotification();
    }, []);

    const [pageNumber, setPageNumber] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(15);
    const startIndex = (pageNumber - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const result = notifications?.slice(startIndex, endIndex);
    const totalPage = Math.ceil(notifications?.length / itemsPerPage);

    return (
        <SplitPane split="vertical" style={{ height: "calc(100vh - 153px)" }} className='position-relative' minSize={20} defaultSize="100%">
            {loader ?
                <SpinerDataLoader />
                :
                <>
                    <Table
                        id="resizable-table"
                        striped
                        hover
                        className="m-0 custom-table"
                        ref={tableRef}
                    >
                        <thead className="thead-sticky">
                            <tr>
                                <th
                                    style={{ width: 40 }}
                                    className="text-center"
                                    onMouseDown={(e) => onMouseDown(e, 0)}
                                >
                                    Sr.
                                </th>
                                <th onMouseDown={(e) => onMouseDown(e, 1)} style={{ width: 200 }}>Time</th>
                                <th onMouseDown={(e) => onMouseDown(e, 2)}>Notification</th>
                            </tr>
                        </thead>
                        <tbody>
                            {result?.map((item, index) => (
                                <tr
                                    key={index}>
                                    <td className="text-center">
                                        {index + startIndex + 1}
                                    </td>
                                    <td>
                                        <Moment format="DD MMM YYYY | HH:mm:ss">
                                            {item?.createdAt}
                                        </Moment>
                                    </td>
                                    <td>
                                        {item?.activities}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    <div className="position-sticky bottom-0">
                        <Pagination
                            totalPage={totalPage}
                            pageNumber={pageNumber}
                            itemsPerPage={itemsPerPage}
                            totalItems={notifications?.length}
                            setPageNumber={setPageNumber}
                            setItemsPerPage={setItemsPerPage}
                        />
                    </div>
                </>
            }
        </SplitPane>
    )
}

export default IWRSNotificationTableData
