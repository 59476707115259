import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useParams } from "react-router";
import { studyOnboardingService } from "../../../../../../Services/studyServices/studySubjectsServices";

const SubjectMetadata = ({ studyDynamicColumn, removeSubjectColumn, tableRef, onMouseDown }) => {
  return (
    <>
      <Table
        id="resizable-table"
        striped
        hover
        className="m-0 custom-table"
        ref={tableRef}>
        <thead>
          <tr>
            <th style={{ width: 45 }} onMouseDown={(e) => onMouseDown(e, 0)}>S No.</th>
            <th onMouseDown={(e) => onMouseDown(e, 1)}>Column</th>
            <th onMouseDown={(e) => onMouseDown(e, 2)}>Value</th>
            <th onMouseDown={(e) => onMouseDown(e, 3)}>Type</th>
            <th onMouseDown={(e) => onMouseDown(e, 4)}>Options</th>
          </tr>
        </thead>
        <tbody>
          {removeSubjectColumn?.length > 0 &&
            <tr>
              <td>1</td>
              <td>Gender</td>
              <td>gender</td>
              <td>text</td>
              <td style={{ whiteSpace: "normal" }}>{removeSubjectColumn?.map((option, i) => option).join(", ")}</td>
            </tr>
          }
          {studyDynamicColumn?.map((item, index) => (
            <tr>
              <td>{removeSubjectColumn?.length > 0 ? (index + 2) : (index + 1)}</td>
              <td>{item.name}</td>
              <td>{item.columnValue}</td>
              <td>{item.types}</td>
              <td style={{ whiteSpace: "normal" }}>{item.options?.map((option, i) => option.option).join(", ")}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default SubjectMetadata;
