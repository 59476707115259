import React, { useEffect } from "react";
import { Form, Table } from "react-bootstrap";

const ECGFormValues = ({
  formDataValue,
  ecgForm = [],
  item,
  index,
  handleECGFormChange,
  ECGFormDataEntry,
  setECGFormDataEntry,
  valueData,
  disabledData,
  tableRef,
  onMouseDown,
}) => {

  let checkMultiForm = formDataValue?.filter(item => item.type === "ecgForm")?.length > 1

  useEffect(() => {
    setECGFormDataEntry(valueData || [])
  }, [valueData]);
  return (
    <div className="overflow-auto">
      <Table
        bordered
        ref={tableRef}
        className="custom-table tableLibrary border-start border-end mt-1"
      >
        <thead>
          <tr style={{ verticalAlign: "bottom" }}>
            <th
              onMouseDown={(e) => onMouseDown(e, 0)}
              className="position-sticky start-0 shadow"
              rowSpan={2}
            >
              <Form.Label className="m-0">
                Examination Test or Examination Name
              </Form.Label>
            </th>
            <th
              className="text-center"
              onMouseDown={(e) => onMouseDown(e, 1)}
              rowSpan={2}
            >
              <Form.Label className="m-0">Result</Form.Label>
            </th>
            <th
              className="text-center"
              onMouseDown={(e) => onMouseDown(e, 2)}
              rowSpan={2}
            >
              <Form.Label className="m-0">Units</Form.Label>
            </th>
            {item?.indicator === "yes" &&
              <>
                <th
                  className="text-center"
                  onMouseDown={(e) => onMouseDown(e, 3)}
                  colSpan={2}
                >
                  <Form.Label className="m-0">Reference Range Indicator</Form.Label>
                </th>
                <th
                  className="text-center"
                  onMouseDown={(e) => onMouseDown(e, 4)}
                  colSpan={2}
                >
                  <Form.Label className="m-0">Clinical Significance</Form.Label>
                </th>
              </>
            }
            <th
              className="text-center"
              onMouseDown={(e) => onMouseDown(e, item?.indicator === "yes" ? 5 : 3)}
              rowSpan={2}

            >
              <Form.Label className="m-0" style={{ width: 100 }}>Remark</Form.Label>
            </th>
            <th onMouseDown={(e) => onMouseDown(e, item?.indicator === "yes" ? 6 : 4)} rowSpan={2}></th>
          </tr>
          {item?.indicator === "yes" &&
            <tr>
              <td className="text-center" style={{ backgroundColor: "#b8cfdf", fontWeight: 500 }}>
                <Form.Label className="m-0">Normal</Form.Label>
              </td>
              <td className="text-center" style={{ backgroundColor: "#b8cfdf", fontWeight: 500 }}>
                <Form.Label className="m-0">Abnormal</Form.Label>
              </td>
              <td className="text-center" style={{ backgroundColor: "#b8cfdf", fontWeight: 500 }}>
                <Form.Label className="m-0">CS</Form.Label>
              </td>
              <td className="text-center" style={{ backgroundColor: "#b8cfdf", fontWeight: 500 }}>
                <Form.Label className="m-0">NCS</Form.Label>
              </td>
            </tr>
          }
        </thead>
        <tbody>
          {ecgForm?.map((row, key) => (
            <tr key={key}>
              <td className="position-sticky start-0 bg-white">
                <Form.Label className="m-0">{row.ecgFormName}</Form.Label>
              </td>
              <td
                className="p-0 text-center"
                style={{ background: (disabledData && !!valueData?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGORRES) && "#e9ecef" }}
              >
                <Form.Control
                  className="border-0 text-center w-100"
                  placeholder="Result"
                  style={{ minWidth: "max-content" }}
                  disabled={(disabledData && !!valueData?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGORRES)}
                  onChange={(e) =>
                    handleECGFormChange(
                      "EGORRES",
                      e.target.value,
                      "EGORRESU",
                      valueData?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGORRES ? (ECGFormDataEntry?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGORRESU || valueData?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGORRESU) : (row?.options > 1 ? (ECGFormDataEntry?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGORRESU || valueData?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGORRESU) : (row?.options?.[0]?.value || "")),
                      "EGORNRIND",
                      ECGFormDataEntry?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGORNRIND || valueData?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGORNRIND,
                      "EGCLSIG",
                      ECGFormDataEntry?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGCLSIG || valueData?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGCLSIG,
                      "EGCOM",
                      ECGFormDataEntry?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGCOM || valueData?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGCOM,
                      checkMultiForm ? Number(`${key + 1}${index + 1}`) : key,
                      item,
                      valueData,
                      (disabledData && !!valueData?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGORRES)
                    )
                  }
                  value={disabledData ? valueData?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGORRES : ECGFormDataEntry?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGORRES}
                  // value={
                  //   ECGFormDataEntry?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGORRES ||
                  //   valueData?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGORRES
                  // }
                  type={row.ecgFormResultDataType || "text"}
                />
              </td>
              <td
                className="p-0"
                style={{
                  background:
                    ((disabledData && !!valueData?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGORRESU) || row?.options?.length <= 1) && "#e9ecef",
                }}
              >
                {row?.options?.length > 1 ?
                  <Form.Select
                    className="border-0"
                    style={{ width: "max-content" }}
                    disabled={disabledData && !!valueData?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGORRESU}
                    onChange={(e) =>
                      handleECGFormChange(
                        "EGORRES",
                        ECGFormDataEntry?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGORRES || valueData?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGORRES,
                        "EGORRESU",
                        e.target.value,
                        "EGORNRIND",
                        ECGFormDataEntry?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGORNRIND || valueData?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGORNRIND,
                        "EGCLSIG",
                        ECGFormDataEntry?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGCLSIG || valueData?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGCLSIG,
                        "EGCOM",
                        ECGFormDataEntry?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGCOM || valueData?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGCOM,
                        checkMultiForm ? Number(`${key + 1}${index + 1}`) : key,
                        item,
                        valueData,
                        disabledData && !!valueData?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGORRESU
                      )
                    }
                  >
                    <option>Select Unit</option>
                    {row?.options?.map((option, i) => (
                      <option
                        selected={
                          (ECGFormDataEntry?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGORRESU ||
                            valueData?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGORRESU) === option.value
                        }
                        key={i}
                        value={option.value}
                      >
                        {option.value}
                      </option>
                    ))}
                  </Form.Select>
                  :
                  <Form.Control
                    disabled
                    style={{ width: "max-content" }}
                    className="border-0 text-center"
                    placeholder={row?.options?.length === 0 ? "No Unit" : "Unit"}
                    onMouseOver={(e) =>
                      handleECGFormChange(
                        "EGORRES",
                        ECGFormDataEntry?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGORRES || valueData?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGORRES,
                        "EGORRESU",
                        row?.options?.[0]?.value || "",
                        "EGORNRIND",
                        ECGFormDataEntry?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGORNRIND || valueData?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGORNRIND,
                        "EGCLSIG",
                        ECGFormDataEntry?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGCLSIG || valueData?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGCLSIG,
                        "EGCOM",
                        ECGFormDataEntry?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGCOM || valueData?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGCOM,
                        checkMultiForm ? Number(`${key + 1}${index + 1}`) : key,
                        item,
                        valueData,
                        disabledData
                      )
                    }
                    value={row?.options?.[0]?.value}
                    type={"text"}
                  />
                }
              </td>
              {item?.indicator === "yes" &&
                <>
                  <td className="text-center">
                    <Form.Check
                      type="radio"
                      name={checkMultiForm ? `ecgformnormalype_${key + 1}${index + 1}` : `ecgformnormalype_${key}`}
                      value="Normal"
                      disabled={disabledData && !!valueData?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGORNRIND}
                      onChange={(e) =>
                        handleECGFormChange(
                          "EGORRES",
                          ECGFormDataEntry?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGORRES || valueData?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGORRES,
                          "EGORRESU",
                          ECGFormDataEntry?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGORRESU || valueData?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGORRESU,
                          "EGORNRIND",
                          e.target.value,
                          "EGCLSIG",
                          e.target.value === "Normal" ? "" : (ECGFormDataEntry?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGCLSIG || valueData?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGCLSIG),
                          "EGCOM",
                          ECGFormDataEntry?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGCOM || valueData?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGCOM,
                          checkMultiForm ? Number(`${key + 1}${index + 1}`) : key,
                          item,
                          valueData,
                          disabledData && !!valueData?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGORNRIND
                        )
                      }
                      checked={
                        (ECGFormDataEntry?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGORNRIND ||
                          valueData?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGORNRIND) === "Normal"
                      }
                    />
                  </td>
                  <td className="text-center">
                    <Form.Check
                      type="radio"
                      name={checkMultiForm ? `ecgformnormalype_${key + 1}${index + 1}` : `ecgformnormalype_${key}`}
                      value="Abnormal"
                      disabled={disabledData && !!valueData?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGORNRIND}
                      onChange={(e) =>
                        handleECGFormChange(
                          "EGORRES",
                          ECGFormDataEntry?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGORRES || valueData?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGORRES,
                          "EGORRESU",
                          ECGFormDataEntry?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGORRESU || valueData?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGORRESU,
                          "EGORNRIND",
                          e.target.value,
                          "EGCLSIG",
                          ECGFormDataEntry?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGCLSIG || valueData?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGCLSIG,
                          "EGCOM",
                          ECGFormDataEntry?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGCOM || valueData?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGCOM,
                          checkMultiForm ? Number(`${key + 1}${index + 1}`) : key,
                          item,
                          valueData,
                          disabledData && !!valueData?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGORNRIND
                        )
                      }
                      checked={
                        (ECGFormDataEntry?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGORNRIND ||
                          valueData?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGORNRIND) === "Abnormal"
                      }
                    />
                  </td>
                  <td className="text-center">
                    <Form.Check
                      type="radio"
                      name={checkMultiForm ? `ecgformcstype_${key + 1}${index + 1}` : `ecgformcstype_${key}`}
                      value={"CS"}
                      disabled={(disabledData && !!valueData?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGCLSIG) || (ECGFormDataEntry?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGORNRIND ||
                        valueData?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGORNRIND) === "Normal"}
                      onChange={(e) =>
                        handleECGFormChange(
                          "EGORRES",
                          ECGFormDataEntry?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGORRES || valueData?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGORRES,
                          "EGORRESU",
                          ECGFormDataEntry?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGORRESU || valueData?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGORRESU,
                          "EGORNRIND",
                          ECGFormDataEntry?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGORNRIND || valueData?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGORNRIND,
                          "EGCLSIG",
                          e.target.value,
                          "EGCOM",
                          ECGFormDataEntry?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGCOM || valueData?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGCOM,
                          checkMultiForm ? Number(`${key + 1}${index + 1}`) : key,
                          item,
                          valueData,
                          (disabledData && !!valueData?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGCLSIG) || (ECGFormDataEntry?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGORNRIND ||
                            valueData?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGORNRIND) === "Normal"
                        )
                      }
                      checked={
                        (ECGFormDataEntry?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGCLSIG ||
                          valueData?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGCLSIG) === "CS"
                      }
                    />
                  </td>
                  <td className="text-center">
                    <Form.Check
                      type="radio"
                      name={checkMultiForm ? `ecgformcstype_${key + 1}${index + 1}` : `ecgformcstype_${key}`}
                      value={"NCS"}
                      disabled={(disabledData && !!valueData?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGCLSIG) || (ECGFormDataEntry?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGORNRIND ||
                        valueData?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGORNRIND) === "Normal"}
                      onChange={(e) =>
                        handleECGFormChange(
                          "EGORRES",
                          ECGFormDataEntry?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGORRES || valueData?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGORRES,
                          "EGORRESU",
                          ECGFormDataEntry?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGORRESU || valueData?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGORRESU,
                          "EGORNRIND",
                          ECGFormDataEntry?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGORNRIND || valueData?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGORNRIND,
                          "EGCLSIG",
                          e.target.value,
                          "EGCOM",
                          ECGFormDataEntry?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGCOM || valueData?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGCOM,
                          checkMultiForm ? Number(`${key + 1}${index + 1}`) : key,
                          item,
                          valueData,
                          (disabledData && !!valueData?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGCLSIG) || (ECGFormDataEntry?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGORNRIND ||
                            valueData?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGORNRIND) === "Normal"
                        )
                      }
                      checked={
                        (ECGFormDataEntry?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGCLSIG ||
                          valueData?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGCLSIG) === "NCS"
                      }
                    />
                  </td>

                </>}
              <td
                className="p-0"
                style={{ background: (disabledData && !!valueData?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGCOM) && "#e9ecef" }}
              >
                <Form.Control
                  disabled={disabledData && !!valueData?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGCOM}
                  className="border-0 w-100 p-1"
                  placeholder="Remark"
                  style={{ resize: "none" }}
                  onChange={(e) =>
                    handleECGFormChange(
                      "EGORRES",
                      ECGFormDataEntry?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGORRES || valueData?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGORRES,
                      "EGORRESU",
                      ECGFormDataEntry?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGORRESU || valueData?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGORRESU,
                      "EGORNRIND",
                      ECGFormDataEntry?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGORNRIND || valueData?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGORNRIND,
                      "EGCLSIG",
                      ECGFormDataEntry?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGCLSIG || valueData?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGCLSIG,
                      "EGCOM",
                      e.target.value,
                      checkMultiForm ? Number(`${key + 1}${index + 1}`) : key,
                      item,
                      valueData,
                      disabledData && !!valueData?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGCOM
                    )
                  }
                  value={
                    ECGFormDataEntry?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGCOM || valueData?.[checkMultiForm ? Number(`${key + 1}${index + 1}`) : key]?.EGCOM
                  }
                  as={"textarea"}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default ECGFormValues;
