import React, { useEffect, useState } from "react";
import SplitPane from "react-split-pane";
import RandomizationTableData from "./RandomizationTableData";
import FactorTableData from "./FactorTableData";
import { useParams } from "react-router";
import { studyOnboardingService } from "../../../../Services/studyServices/studySubjectsServices";

const NewTrialfactorsDesign = ({ onMouseDown, tableRef }) => {
  const [factorData, setFactorData] = useState([])
  const [options, setOptions] = useState([])
  const { studyId } = useParams()
  const [factorName, setFactorName] = useState("")
  const [loader, setLoader] = useState(false);

  const getStudySubjectColumnForFactor = async () => {
    setLoader(true)
    let data = await studyOnboardingService.getStudySubjectColumnForFactor(studyId)
    setFactorData(data?.data || [])
    setLoader(false)
  }

  let [genderList, setGenderList] = useState([])

  const getAllSubjectPreDefineColumnOption = async () => {
    let data = await studyOnboardingService.getAllSubjectPreDefineColumnOption(studyId);
    setGenderList(
      data?.data?.map(item => ({
        option: item.option.toLowerCase()
      })) || []
    );
  };

  useEffect(() => {
    getStudySubjectColumnForFactor();
    getAllSubjectPreDefineColumnOption()
  }, [studyId]);
  return (
    <>
      <div className="position-relative">
        <SplitPane
          split="horizontal"
          style={{ height: "calc(100vh - 155px)" }}
          className="position-relative"
          defaultSize={"100%"}
        >
          <SplitPane split="vertical" minSize={20}
            defaultSize={` ${options?.length > 0 ? "70%" : "100%"}`}
          >
            <RandomizationTableData
              onMouseDown={onMouseDown}
              tableRef={tableRef}
              factorData={factorData}
              setFactorName={setFactorName}
              setOptions={setOptions}
              genderList={genderList}
              loader={loader}
            />
            {options?.length > 0 &&
              <FactorTableData
                onMouseDown={onMouseDown}
                tableRef={tableRef}
                options={options}
                factorName={factorName}
                studyId={studyId}
                getAllSubjectPreDefineColumnOption={getAllSubjectPreDefineColumnOption}
                getStudySubjectColumnForFactor={getStudySubjectColumnForFactor}
              />
            }
          </SplitPane>
        </SplitPane>
      </div>
    </>
  );
};

export default NewTrialfactorsDesign;
