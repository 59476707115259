import React from "react";
import App from "./App";
import "./Styles/Style.scss";
import ReactDOM from "react-dom/client";
import config from "./config/config.json";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { BrowserRouter } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import ContextProviders from "./Contexts/ContextProviders";
import "react-datepicker/dist/react-datepicker-cssmodules.css";

const root = ReactDOM.createRoot(document.getElementById("root"));

const useCustomErrorPage = config.errorHandle === "live";

if (process.env.NODE_ENV === "development" && useCustomErrorPage) {
  const noop = () => { };

  if (window.__REACT_DEVTOOLS_GLOBAL_HOOK__) {
    window.__REACT_DEVTOOLS_GLOBAL_HOOK__.inject = noop;
  }

  window.onerror = (message, source, lineno, colno, error) => {
    console.error(message, source, lineno, colno, error);
    document.body.innerHTML = `
      <div style="display: flex; flex-direction: column; justify-content: center;
        align-items: center; height: 100vh; text-align: center;
        font-family: Arial, sans-serif; background-color: #f8f9fa;
        color: #333;">
        <h1 style="font-size: 2.5em; margin-bottom: 20px; color: #103c5e; font-weight: "bold">We are Sorry...</h1>
        <p style="font-size: 1.2em; margin-bottom: 20px;">The page your'are trying to access has restricted access.</br>
Please refer to your system administrator</p>
        <details style="white-space: pre-wrap; text-align: left; margin-bottom: 20px; ">
          <summary style="cursor: pointer;">Click to view details</summary>
          <p>${error?.stack || "No stack trace available"}</p>
        </details>
        <button style="padding: 5px 71px; font-size: 1em; cursor: pointer;
          background-color: #103c5e; color: white; border: none;
          border-radius: 5px;" onclick="location.reload()">Go Back</button>
      </div>
    `;
    return true;
  };

  window.onunhandledrejection = (event) => {
    console.error(event.reason);
    document.body.innerHTML = `
      <div style="display: flex; flex-direction: column; justify-content: center;
        align-items: center; height: 100vh; text-align: center;
        font-family: Arial, sans-serif; background-color: #f8f9fa;
        color: #333;">
        <h1 style="font-size: 2.5em; margin-bottom: 20px;">Unhandled Promise Rejection!</h1>
        <details style="white-space: pre-wrap; text-align: left; margin-top: 10px;">
          <summary style="cursor: pointer;">Click to view details</summary>
          <p>${event.reason?.stack || event.reason || "No additional information available"}</p>
        </details>
        <button style="padding: 5px 71px; font-size: 1em;
          cursor: pointer; background-color: #103c5e; color: white;
          border: none; border-radius: 5px;"
        onclick="location.reload()">Go Back</button>
      </div>
    `;
    return true;
  };
}

root.render(
  <>
    <ToastContainer />
    <ContextProviders>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ContextProviders>
  </>
);
