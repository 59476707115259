import React, { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { studyOnboardingService } from "../../../../../../../Services/studyServices/studySitesServices";
import { useParams } from "react-router";
import {
  NoDataFoundTable,
  SpinerDataLoader,
} from "../../../../../../NoDataFoundTable/NoDataFoundTable";
import SplitPane from "react-split-pane";

const SiteSubjectList = ({
  subjectUniqueId,
  handleAddNewTab,
  selectedStudySiteId,
  studySubjectState,
  siteIdData,
}) => {
  const { studyId } = useParams();
  const StudyMode = JSON.parse(localStorage.getItem("StudyMode"));
  const [studySubjectDynamicColumn, setStudySubjectDynamicColumn] = useState(
    []
  );
  const [studySubjectDynamicRow, setStudySubjectDynamicRow] = useState([]);
  const [loader, setLoader] = useState(false);
  const [studySubjectDynamicColumnID, setStudySubjectDynamicColumnID] =
    useState("");
  const getStudySubjectRowData = async (subjectUniqueId) => {
    setLoader(true);
    let currentPage = 1;
    let data = await studyOnboardingService.getStudySubjectRowData(
      studyId,
      StudyMode,
      studySubjectDynamicColumnID,
      subjectUniqueId,
      currentPage,
      100
    );

    let initialData = Object.values(data.data === undefined ? "" : data?.data);
    let finalData = initialData?.sort()?.reverse();
    setStudySubjectDynamicRow(finalData);
    setLoader(false);
  };

  const getStudySubjectColumnData = async () => {
    setLoader(true);
    let data = await studyOnboardingService.getStudySubjectColumnData(studyId);
    let initialData = data?.data === undefined ? "" : data?.data;
    if (initialData.length > 0) {
      let finalData = initialData?.sort()?.reverse();
      setStudySubjectDynamicColumn(Object.values(finalData?.[0].column));
      setStudySubjectDynamicColumnID(finalData?.[0]._id);
    } else {
      setStudySubjectDynamicColumn("");
      setStudySubjectDynamicColumnID("");
    }
    setLoader(false);
  };

  const viewStudySubjectIdOfVisit = (item) => {
    let idObject = {
      studySubjectIdOfVisitsState: item?.studyUniqueId,
      selectedStudySubjectId: item?.subjectIdLabel,
      siteIdData: siteIdData,
      subjectStudyUniqueId: item?.studyUniqueId,
      studySubjectState: studySubjectState,
    };
    let idSubjectObject = {
      studySubjectState: studySubjectState,
      selectedStudySiteId: selectedStudySiteId,
      siteIdData: siteIdData,
    };
    let subjectBreadcrumb = [
      {
        tabName: `Site: ${selectedStudySiteId}`,
        currentTabName: `Study Site`,
        uniqueId: `studySite`,
      },
      { tabName: `Subject List` },
    ];
    let breadcrumb = [
      {
        tabName: `Site: ${selectedStudySiteId}`,
        currentTabName: `Study Site`,
        uniqueId: `studySite`,
      },
      {
        tabName: `Subject: ${item?.subjectIdLabel}`,
        currentTabName: `${selectedStudySiteId} Subject`,
        uniqueId: `studySubject_${siteIdData}`,
        pageId: idSubjectObject,
        breadcrumb: subjectBreadcrumb,
      },
      { tabName: `Subject Event List` },
    ];
    handleAddNewTab(
      `${item?.subjectIdLabel} Subject Event`,
      "fa-solid fa-database",
      `subjectEventForm_${item?._id}`,
      idObject,
      breadcrumb
    );
  };

  const viewStudySubjectSpecialForm = (item) => {
    let idObject = {
      studyUniqueId: item?.studyUniqueId,
      selectedStudySubjectId: item?.subjectId,
      siteIdData: siteIdData,
      studySubjectState: studySubjectState,
    };

    let idSubjectObject = {
      studySubjectState: studySubjectState,
      selectedStudySiteId: selectedStudySiteId,
      siteIdData: siteIdData,
    };
    let subjectBreadcrumb = [
      {
        tabName: `Site: ${selectedStudySiteId}`,
        currentTabName: `Study Site`,
        uniqueId: `studySite`,
      },
      { tabName: `Subject List` },
    ];
    let breadcrumb = [
      {
        tabName: `Site: ${selectedStudySiteId}`,
        currentTabName: `Study Site`,
        uniqueId: `studySite`,
      },
      {
        tabName: `Subject: ${item?.subjectIdLabel}`,
        currentTabName: `${selectedStudySiteId} Subject`,
        uniqueId: `studySubject_${siteIdData}`,
        pageId: idSubjectObject,
        breadcrumb: subjectBreadcrumb,
      },
      { tabName: `Special Form List` },
    ];
    handleAddNewTab(
      `${item?.subjectIdLabel} Subject Special Form`,
      "fa-solid fa-database",
      `subjectSpecialForm_${item?._id}`,
      idObject,
      breadcrumb
    );
  };

  let studyPermissionData = JSON.parse(
    localStorage.getItem("studyPermissionData")
  );

  useEffect(() => {
    getStudySubjectColumnData();
    if (studySubjectDynamicColumnID) {
      getStudySubjectRowData(subjectUniqueId);
    }
  }, [subjectUniqueId, studySubjectDynamicColumnID]);

  return (
    <>
      <SplitPane
        split="vertical"
        minSize={20}
        defaultSize="100%"
        style={{ height: "92%" }}
      >
        {loader ? (
          <SpinerDataLoader />
        ) : studySubjectDynamicColumn?.length === 0 ? (
          <NoDataFoundTable MSG="Subject not found" />
        ) : (
          <Table className="m-0 custom-table" hover striped>
            <thead className="position-sticky top-0">
              <tr>
                <th style={{ width: 45 }}>S No.</th>
                <th>Subject Id</th>
                <th>Subject Gender</th>
                {studySubjectDynamicColumn?.map((item) => (
                  <th>{item.name}</th>
                ))}
                <th>Status</th>
                {/* <th style={{ width: 115 }} className="text-center">
                  Special Forms
                </th>
                <th style={{ width: 115 }} className="text-center">
                  Subject Events
                </th> */}
                {(StudyMode === "SDV Live Mode" || StudyMode === "Read Only Live Mode" || StudyMode === "SDV QC Mode" || StudyMode === "Live Mode" || StudyMode === "QC Mode") &&
                  <th style={{ width: 80 }} className="text-center">
                    Signature
                  </th>
                }
                <th style={{ width: 100 }} className="text-center">
                  View Report
                </th>
              </tr>
            </thead>
            <tbody>
              {studySubjectDynamicRow?.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.subjectIdLabel}</td>
                  <td className="text-capitalize">{item?.subjectGender}</td>
                  {Object.values(item.row).map((dataItem, dataIndex) => (
                    <td key={dataIndex}>{dataItem.valueData || "_"}</td>
                  ))}
                  <td
                    className={item.status ? "text-success" : "text-secondary"}
                  >
                    {item.status ? "Completed" : "Ongoing"}
                  </td>
                  {/* <td>
                    <Button
                      onClick={(e) => viewStudySubjectSpecialForm(item)}
                      style={{
                        fontSize: 10,
                        borderRadius: 3,
                        padding: "2px 8px",
                      }}
                      className={`text-white border-success bg-success border`}
                    >
                      View Special Form
                    </Button>
                  </td>
                  <td>
                    <Button
                      onClick={(e) => viewStudySubjectIdOfVisit(item)}
                      style={{
                        fontSize: 10,
                        borderRadius: 3,
                        padding: "2px 8px",
                      }}
                      className={`text-white border-success bg-success border`}
                    >
                      View Subject Event
                    </Button>
                  </td> */}
                  {(StudyMode === "SDV Live Mode" || StudyMode === "Read Only Live Mode" || StudyMode === "SDV QC Mode" || StudyMode === "Live Mode" || StudyMode === "QC Mode") &&
                    <td className="text-center">
                      <button
                        onClick={() => {
                          handleAddNewTab(
                            `${item?.subjectIdLabel} Investigator eSign`,
                            "fa-solid fa-database",
                            `investigatorSignature`,
                            {
                              siteId: selectedStudySiteId,
                              subjectId: item?.subjectIdLabel,
                              siteUniqueId: item?.siteUniqueId,
                              studyUniqueId: item?.studyUniqueId,
                            }
                          );
                        }}
                        disabled={!studyPermissionData?.investigatorLiveSignature}
                        class="text-white border-primary bg-primary border"
                        style={{
                          fontSize: 10,
                          borderRadius: 3,
                          padding: "2px 8px",
                        }}
                      >
                        eSign
                      </button>
                    </td>
                  }
                  <td className="text-center">
                    <button
                      onClick={() => {
                        handleAddNewTab(
                          `${item?.subjectIdLabel} Report`,
                          "fa-solid fa-file",
                          `subjectReportManegement_${item?._id}`,
                          { studyUniqueId: item?.studyUniqueId }
                        );
                      }}
                      class="text-white border-primary bg-primary border"
                      style={{
                        fontSize: 10,
                        borderRadius: 3,
                        padding: "2px 8px",
                      }}
                    >
                      View Report
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </SplitPane>
    </>
  );
};

export default SiteSubjectList;
