import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useParams } from "react-router";
import SplitPane from "react-split-pane";
import AddIssueSummary from "./AddIssueSummary";
import IssueFilterData from "./IssueFilterData";
import { Spinner, Table } from "react-bootstrap";
import UseToggle from "../../../../../Hooks/UseToggle";
import IssueTableProperties from "./IssueTableProperties";
import Pagination from "../../../../ETMF/Pagination/Pagination";
import { cdrgStudyServices } from "../../../../../Services/CDISC/cdrgStudyServices";

const IssueSummary = ({ onMouseDown, tableRef }) => {
  const { studyId } = useParams();
  const [error, setError] = useState("");
  const [loader, setLoader] = useState(false);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [issueSummary, setIssueSummary] = useState([]);
  const [explaination, setExplaination] = useState("");
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [issueSummaryid, setIssueSummaryid] = useState("");
  const [highlightedRow, setHighlightedRow] = useState(null);
  const [isOpenProperty, setIsOpenProperty] = useState(false);
  const { toggle, setToggle, toggle1, setToggle1 } = UseToggle();

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  const getStudyIssueSummeryData = async () => {
    let data = await cdrgStudyServices.getStudyIssueSummeryData(studyId);
    setIssueSummary(data?.data);
  };

  const updateData = (item) => {
    setIssueSummaryid(item);
    setIsFormOpen(true)
  }

  const deleteStudyIssueSummerys = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Delete this Issue Summary?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = await cdrgStudyServices.deleteStudyIssueSummerys(id, studyId);
        if (data?.statusCode === 200) {
          getStudyIssueSummeryData();
        }
      }
    });
  };

  const cleanInputBox = () => {
    setError("");
    setExplaination("");
    setIssueSummaryid("");
  };

  useEffect(() => {
    getStudyIssueSummeryData();
  }, []);

  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(15);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = issueSummary?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(issueSummary?.length / itemsPerPage);

  return (
    <>
      <div className={`code-list-container ${isFullScreen ? "full-screen" : ""}`}>
        <div
          className="hstack justify-content-between w-100 border-bottom toolbar"
          style={{ padding: "4.6px 9px" }}
        >
          <div className="hstack gap-2">
            <i
              title={`${isFormOpen ? "Close Form" : "Add New Metadata"}`}
              class={`fa-solid ${isFormOpen ? "fa-square-caret-left" : "fa-square-plus"
                } CP p-1 fontSize12`}
              onClick={() => setIsFormOpen(!isFormOpen)}
              style={{ borderRadius: 3 }}
            ></i>
            <div className="vr"></div>
            <i
              title="Table Properties"
              class="fa-solid fa-table-list CP p-1 fontSize12"
              onClick={setToggle}
              style={{ borderRadius: 3 }}
            ></i>
            <div className="vr"></div>
            <i
              title="Filter Table Data"
              class="fa-solid fa-filter CP p-1 fontSize12"
              onClick={setToggle1}
              style={{ borderRadius: 3 }}
            ></i>
          </div>
          <i
            class={`fa-solid ${isFullScreen ? "fa-minimize" : "fa-maximize"}`}
            title={!isFullScreen ? "Full Mode" : "Original Mode"}
            onClick={toggleFullScreen}
          ></i>
        </div>
        <div className="position-relative">
          <SplitPane
            split="horizontal"
            style={{
              height: isFullScreen ? "calc(100vh - 35px)" : "calc(100vh - 187px)",
            }}
            defaultSize={isOpenProperty ? "40%" : "100%"}
          >
            <SplitPane
              split="vertical"
              minSize={20}
              defaultSize={isFormOpen ? "20%" : "100%"}
            >
              {isFormOpen && (
                <AddIssueSummary
                  error={error}
                  studyId={studyId}
                  setError={setError}
                  explaination={explaination}
                  cleanInputBox={cleanInputBox}
                  issueSummaryid={issueSummaryid}
                  setExplaination={setExplaination}
                  setIssueSummaryid={setIssueSummaryid}
                  getStudyIssueSummeryData={getStudyIssueSummeryData}
                />
              )}
              <>
                {loader ? (
                  <div className="hstack justify-content-center h-100">
                    <Spinner />
                  </div>
                ) : result?.length === 0 ? (
                  <div className="hstack justify-content-center h-100">
                    Data not found!
                  </div>
                ) : (
                  <Table
                    id="resizable-table"
                    ref={tableRef}
                    striped
                    className="m-0 custom-table"
                  >
                    <thead className="thead-sticky">
                      <tr>
                        <th
                          onMouseDown={(e) => onMouseDown(e, 0)}
                          style={{ width: 40 }}
                          className="text-center"
                        >
                          Sr.
                        </th>
                        <th onMouseDown={(e) => onMouseDown(e, 1)}>Error</th>
                        <th onMouseDown={(e) => onMouseDown(e, 2)}>Expalnation</th>
                        <th
                          onMouseDown={(e) => onMouseDown(e, 3)}
                          style={{ width: 80 }}
                          className="text-center"
                        >
                          Update
                        </th>
                        <th
                          onMouseDown={(e) => onMouseDown(e, 4)}
                          style={{ width: 80 }}
                          className="text-center"
                        >
                          Delete
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {result?.map((item, index) => (
                        <tr key={index}
                          onClick={() => {
                            setHighlightedRow(index);
                          }}
                          className={`position-relative cursor-pointer ${highlightedRow === index && "activeMenu"
                            }`}
                        >
                          <td className="text-center">{index + startIndex + 1}</td>
                          <td>{item.error}</td>
                          <td className="text-wrap">
                            {item.explaination}
                          </td>
                          <td className="cursor-pointer text-center">
                            <button
                              onClick={() => updateData(item)}
                              title="Update Issue"
                              className="text-white border-success bg-success border-0"
                              style={{ fontSize: 10, borderRadius: 3 }}
                            >
                              Update
                            </button>
                          </td>
                          <td className="cursor-pointer text-center" >
                            <button
                              className="text-white border-danger bg-danger border-0"
                              style={{ fontSize: 10, borderRadius: 3 }}
                              title="Delete Issue"
                              onClick={() => deleteStudyIssueSummerys(item._id)}
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
                <div className="position-sticky bottom-0">
                  <Pagination
                    totalPage={totalPage}
                    pageNumber={pageNumber}
                    itemsPerPage={itemsPerPage}
                    totalItems={issueSummary?.length}
                    setPageNumber={setPageNumber}
                    setItemsPerPage={setItemsPerPage}
                  />
                </div>
              </>
            </SplitPane>
          </SplitPane>
        </div>
      </div>
      <IssueTableProperties
        Show={!toggle}
        Hide={setToggle}
        Title={"Table Properties"}
      />
      <IssueFilterData
        Show={!toggle1}
        Hide={setToggle1}
        Title={"Filter Issue"}
      />
    </>
  );
};

export default IssueSummary;
