/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import { clientLevelFormServices } from "../../../../../Services/formServices/clientLevelFormServices";
import Loading from "../../../../Loading/Loading";
import "../../../../../Styles/CrfView.scss";
import {
  IdDetails,
  NoDataFoundTable,
  SpinerDataLoader,
} from "../../../../NoDataFoundTable/NoDataFoundTable";
import UseToggle from "../../../../../Hooks/UseToggle";
import { ButtonGroup, Dropdown, Form, Offcanvas } from "react-bootstrap";
import { useParams } from "react-router";
import { ReviewHistoryTable } from "./FieldDetailsTables/ReviewHistoryTable";
import { HistoryTable } from "./FieldDetailsTables/HistoryTable";
import { QueryTable } from "./FieldDetailsTables/QueryTable";
import { UseGetPermission } from "../../../../../Hooks/UseGetPermission";
import { globalFormServices } from "../../../../../Services/formServices/globalFormServices";
import { StudyVisitFormServices } from "../../../../../Services/formServices/StudyVisitFormServices";
import { UseCrfViewContext } from "../../../../../Contexts/UseCrfViewContext";
import CrfViewLeft from "./CrfViewLeft";
import CrfViewRightTop from "./CrfViewRightTop";
import { regularFormDataEntryServices } from "../../../../../Services/studyServices/regularFormDataEntryServices";
import { UseSignature } from "../../StudyIdData/UseAppendInput";
import { handleResponse } from "../../../../../Helpers/Responses";
import UpdateJsonData from "../../StudyIdData/StudyIdModal/updateJsonData";
import SplitPane from "react-split-pane";
import FreezingLockingManagement from "./FieldDetailsTables/FreezingLockingManagement";

export const UseCrfForm = () => {
  const [items, setItems] = useState();
  const [selectedForm, setSelectedForm] = useState(null);
  const [loader, setLoader] = useState(true);
  const { studyId } = useParams();
  const { getStudyLevelFormData } = clientLevelFormServices;
  const filteredForm = useMemo(
    () => items?.filter((item) => item?.formUniqueId === "adjiduuid3284652"),
    [items]
  );

  const fetchForms = async () => {
    const res = await getStudyLevelFormData(studyId, 1, 100);
    setItems(res?.data);
    setLoader(false);
  };

  const fetchStudyVisitsForms = async () => {
    const res = await StudyVisitFormServices.getStudyVisitsFormData(
      studyId,
      1,
      100
    );
    setItems(res?.data);
    setLoader(false);
  };

  useEffect(() => {
    fetchForms();
    fetchStudyVisitsForms();
  }, [getStudyLevelFormData, studyId]);

  return {
    loader,
    setLoader,
    filteredForm,
    selectedForm,
    setSelectedForm,
    fetchForms,
    fetchStudyVisitsForms,
  };
};

export const UseCrfView = (
  studyDynamicForm,
  formUniqueId,
  formType,
  visitFormType,
  dataEntryFormMode,
  studyUniqueId
) => {
  const { pushGlobalForm: validateForm, getListData: getValidations } =
    globalFormServices;
  const { validatorPermission } = UseGetPermission();
  const { toggle: tableShow, setToggle: setTableShow } = UseToggle();
  const [selectedFormList, setSelectedFormList] = useState([]);
  const [selectedFormId, setSelectedFormId] = useState([]);
  const [validatedState, setValidatedState] = useState(null);

  const {
    setSelectedForm,
    selectedForm,
    reviewHistoryEndpoint,
    reviewFormFieldEndpoint,
    getGlobalQueryStatusEndPoint,
  } = UseCrfViewContext();

  useEffect(() => {
    setSelectedForm("");
  }, [formUniqueId]);

  useMemo(() => {
    const newvalues = studyDynamicForm?.filter(
      (item) => item?.formUniqueId === formUniqueId
    );
    setSelectedFormList(newvalues);
  }, [formUniqueId, studyDynamicForm]);

  useMemo(async () => {
    const res = await getValidations(
      `getFormValidationByID/${formUniqueId}/${selectedForm?.uid}`
    );
    setValidatedState(res);
  }, [formUniqueId, getValidations, selectedForm?.uid]);

  const historyTableProps = {
    studyUniqueId,
    visitFormType,
    formUniqueId,
    selectedFormId,
    dataEntryFormMode,
    getGlobalQueryStatusEndPoint,
    ReviewHistoryEndpoint: reviewHistoryEndpoint,
    ReviewFormFieldEndpoint: reviewFormFieldEndpoint,
  };

  const ValidateForm = async () => {
    const data = {
      formUniqueId,
      formFieldId: selectedForm?.uid,
      validation: true,
      formType: formType,
    };

    await validateForm(data, "formValidation");
  };

  const [dataEntryInput, setDataEntryInput] = useState("");
  const [inputData, setInputData] = useState([]);
  const [validationError, setValidationError] = useState([]);
  const [optionValueData, setOptionValueData] = useState([]);
  const [connectUniqueIdData, setConnectUniqueIdData] = useState("");
  const [dataLoader, setDataLoader] = useState(false);
  let StudyMode = JSON.parse(localStorage.getItem("StudyMode"));
  let { studyId } = useParams();

  const dataEntry = async () => {
    setDataLoader(true);
    const data = {
      connectUniqueIdData,
      formUniqueId,
      conditionalData: optionValueData,
      form: inputData,
      studyUniqueId: studyUniqueId,
      dataEntryMode: dataEntryFormMode,
      study_Id: studyId,
      studyMode: StudyMode,
    };

    let endPoint =
      formType === "initiateVisitsForm"
        ? "dataEntryInitiateVisits"
        : formType === "specialFormType"
          ? "dataEntrySpecialForm"
          : "dataentry";
    let finalData = await validateForm(data, endPoint);
    setInputData([]);
    storeDataEntry("");
    setDataLoader(false);
    setValidationError(finalData?.result);
  };

  const [addEntry, setAddEntry] = useState([]);
  const [confirm, setConfirm] = useState(true);
  const storeDataEntry = (value) => {
    if (value) {
      const labelSelected = selectedForm.label;
      const uuidSelected = selectedForm.uid;
      const typeSelected = selectedForm.type;
      const uniqueIdSelected = selectedForm.uniqueId;
      setAddEntry([
        ...addEntry,
        {
          uniqueId: uniqueIdSelected,
          label: labelSelected,
          uid: uuidSelected,
          type: typeSelected,
          freezeLockStatus: "freezed",
          value: value,
        },
      ]);
    }
  };

  const finalDataEntryArray = (confirm) => {
    setDataLoader(true);
    setConfirm(confirm);
    if (addEntry.length > 0) {
      const lastObjects = {};
      addEntry.forEach((item) => {
        const uniqueId = item.uniqueId;
        lastObjects[uniqueId] = item;
      });
      const resultArray = Object.values(lastObjects);
      setInputData(resultArray);
    }
    setDataLoader(false);
  };

  const crfViewLeftProps = {
    selectedFormList,
    selectedForm,
    setSelectedForm,
    setSelectedFormId,
    selectedFormId,
    formUniqueId,
  };

  const crfViewRightTopProps = {
    visitFormType,
    selectedForm,
    ValidateForm,
    validatorPermission,
    validatedState,
    dataEntryInput,
    setDataEntryInput,
  };

  return {
    selectedFormList,
    crfViewLeftProps,
    selectedForm,
    crfViewRightTopProps,
    setTableShow,
    tableShow,
    historyTableProps,
    formUniqueId,
    formType,
    dataEntryFormMode,
    studyUniqueId,
    visitFormType,
    selectedFormId,
    validationError,
    dataEntry,
    dataLoader,
    inputData,
    addEntry,
    confirm,
    dataEntryInput,
    setDataEntryInput,
    storeDataEntry,
    finalDataEntryArray,
    setOptionValueData,
    optionValueData,
    setSelectedForm,
    setConnectUniqueIdData,
    connectUniqueIdData,
  };
};

const CrfView = ({
  studyVisitsFrom,
  loader,
  tableRef,
  onMouseDown,
  formUniqueId,
  formType,
  removeNevigation,
  visitFormType,
  dataEntryFormMode,
  studyUniqueId,
  breadcrumbData,
  handleAddNewTab,
}) => {
  const { studyDynamicForm } = UseCrfViewContext();

  const {
    selectedFormList,
    crfViewLeftProps,
    selectedForm,
    crfViewRightTopProps,
    setDataEntryInput,
    historyTableProps,
    selectedFormId,
    validationError,
    dataEntry,
    dataLoader,
    inputData,
    addEntry,
    confirm,
    storeDataEntry,
    finalDataEntryArray,
    setOptionValueData,
    optionValueData,
    setSelectedForm,
    setConnectUniqueIdData,
    connectUniqueIdData,
  } = UseCrfView(
    studyDynamicForm,
    formUniqueId,
    formType,
    visitFormType,
    dataEntryFormMode,
    studyUniqueId
  );

  const {
    queryGenerateEndpoint,
    getTicketsEndPoint,
    getQueryListEndPoint,
    formFieldHistoryEndpoint,
    dataEntryFormByIdEndPoint,
    dataEntryLockFreezeFormByIdEndPoint,
    dataEnteriesValueShow,
    queryReplyEndpoint,
    queryCommentEndpoint,
    updateTicketStatusEndPoint,
  } = UseCrfViewContext();

  const studyPermissionData = JSON.parse(
    localStorage.getItem("studyPermissionData")
  );

  const [freezeLock, setFreezeLock] = useState("");
  const [fieldLockFreeze, setFieldLockFreeze] = useState("");
  const [hasUnfreezed, setHasUnfreezed] = useState(false);
  const [formFieldUniqueId, setFormFieldUniqueId] = useState("");
  const [comment, setComment] = useState("");
  const [status, setStatus] = useState("");
  const [DataInputLoader, setDataInputLoader] = useState("");
  const [databaseLockFreezeHistory, setDatabaseLockFreezeHistory] = useState(
    []
  );
  const [loaderFreezeData, setLoaderFreezeData] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const freezeLockdata = (data) => {
    setFreezeLock(data?.[0]?.freezeLockStatus);
    setFormFieldUniqueId(data?.[0]?.uniqueId);
  };
  let StudyMode = JSON.parse(localStorage.getItem("StudyMode"));

  const getFormFieldHistory = async () => {
    setDataInputLoader(true);
    let res;
    if (connectUniqueIdData) {
      res = await clientLevelFormServices.getDataEntryFreezeLockForLogFrom(
        "getVisitsDataEntryLockFreezeFormByLogFormField",
        formUniqueId,
        studyUniqueId,
        dataEntryFormMode,
        StudyMode,
        selectedFormId,
        connectUniqueIdData
      );
    } else {
      res = await clientLevelFormServices.getDataEntryFreezeLock(
        "getVisitsDataEntryLockFreezeFormByFormField",
        formUniqueId,
        studyUniqueId,
        dataEntryFormMode,
        StudyMode,
        selectedFormId
      );
    }
    setFieldLockFreeze(res?.data);
    setHasUnfreezed(res?.hasUnfreezed);
    freezeLockdata(res?.data, formUniqueId);
    setDataInputLoader(false);
  };

  useEffect(() => {
    getFormFieldHistory();
  }, [formUniqueId, selectedFormId]);

  const databaseFreezeLockForDataEntry = async () => {
    setDataInputLoader(true);
    let datas = {};
    datas.uniqueId = formFieldUniqueId;
    datas.formUniqueId = formUniqueId;
    datas.connectUniqueId = connectUniqueIdData;
    datas.comment = comment;
    datas.status = status;
    datas.studyMode = StudyMode;
    datas.dataEntryMode = dataEntryFormMode;
    datas.studyUniqueId = studyUniqueId;
    datas.studyId = studyId;
    let result =
      await clientLevelFormServices.databaseUnFreezeForDataEntryForRegular(
        datas
      );
    setDataInputLoader(false);
    if (result.statusCode === 200) {
      hideModalData();
      getdatabaseUnFreezeForDataEntry(formFieldUniqueId);
      getFormFieldHistory();
    }
  };

  const getdatabaseUnFreezeForDataEntry = async (formFieldUniqueId) => {
    setLoaderFreezeData(true);
    let data;
    if (connectUniqueIdData) {
      data =
        await clientLevelFormServices.getdatabaseUnFreezeForDataEntryForLog(
          formUniqueId,
          studyUniqueId,
          StudyMode,
          dataEntryFormMode,
          formFieldUniqueId,
          connectUniqueIdData
        );
    } else {
      data = await clientLevelFormServices.getdatabaseUnFreezeForDataEntry(
        formUniqueId,
        studyUniqueId,
        StudyMode,
        dataEntryFormMode,
        formFieldUniqueId
      );
    }
    setDatabaseLockFreezeHistory(data?.data);
    setLoaderFreezeData(false);
  };
  let { studyId } = useParams();
  const [conditionalData, setConditionalData] = useState([]);
  const getEntriedConditionalOptionId = async () => {
    setDataInputLoader(true);
    let data = await clientLevelFormServices.getEntriedConditionalOptionId(
      studyId,
      formUniqueId,
      studyUniqueId,
      dataEntryFormMode,
      StudyMode
    );
    let result = data?.data === undefined ? [] : data?.data;
    setConditionalData(result);
    setDataInputLoader(false);
  };

  const updateDatabaseFreezLock = (e, status) => {
    setStatus(status);
    handleShow();
  };

  const hideModalData = () => {
    setComment("");
    handleClose();
  };

  const [inputFormDataEntry, setInputFormDataEntry] = useState(null);
  const [numberInput, setNumberInput] = useState("");
  const [textInput, setTextInput] = useState("");
  const [textareaInput, setTextareaInput] = useState("");
  const [scoreInput, setScoreInput] = useState("");
  const [scaleInput, setScaleInput] = useState("");
  const [uploadInput, setUploadInput] = useState("");
  const [radioId, setRadioId] = useState("");
  const [radioOption, setRadioOption] = useState([]);
  const [dropdownId, setDropdownId] = useState("");
  const [dropdownOption, setDropdownOption] = useState([]);
  const [checkboxId, setCheckboxId] = useState("");
  const [checkboxValueData, setCheckboxValueData] = useState("");
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [hour, setHour] = useState("");
  const [minute, setMinute] = useState("");
  const [second, setSecond] = useState("");
  const [format, setFormat] = useState("");
  const [dateCalendar, setDateCalendar] = useState("");
  const [timeCalendar, setTimeCalendar] = useState("");
  const [dayCheck, setDayCheck] = useState("");
  const [monthCheck, setMonthCheck] = useState("");
  const [yearCheck, setYearCheck] = useState("");
  const [hourCheck, setHourCheck] = useState("");
  const [minuteCheck, setMinuteCheck] = useState("");
  const [secondCheck, setSecondCheck] = useState("");

  const cleanAllDataEntryFormField = () => {
    setNumberInput("");
    setTextInput("");
    setTextareaInput("");
    setScoreInput("")
    setCheckboxValueData("");
    setCheckboxId("");
    setScaleInput("");
    setUploadInput("");
    setRadioId("");
    setRadioOption([]);
    setDropdownId("");
    setDropdownOption([]);
    setDay("");
    setMonth("");
    setYear("");
    setHour("");
    setMinute("");
    setSecond("");
    setFormat("");
    setTimeCalendar("");
    setDateCalendar("");
    setDayCheck("");
    setMonthCheck("");
    setYearCheck("");
    setHourCheck("");
    setMinuteCheck("");
    setSecondCheck("");
    setDataEntryInput([]);
  };

  const [ticketQuery, setTicketQuery] = useState([]);
  const [reviewedData, setReviewedData] = useState([]);
  const [logFormData, setLogFormData] = useState([]);
  const [activeTabs, setActiveTabs] = useState("Freezing & Locking Management");
  const [isFullScreen, setIsFullScreen] = useState(false);
  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };
  const getdataEntryFormStatus = async () => {
    setDataInputLoader(true);
    let data = await clientLevelFormServices.getdataEntryFormStatus(
      dataEntryFormMode,
      studyUniqueId,
      StudyMode,
      formUniqueId
    );
    setTicketQuery(data?.ticketQuery);
    setReviewedData(data?.reviewedData);
    setDataInputLoader(false);
  };

  const getLogFormListForRegular = async () => {
    setDataInputLoader(true);
    let data = await regularFormDataEntryServices.getLogFormListForRegular(
      studyId,
      studyUniqueId,
      formUniqueId,
      dataEntryFormMode,
      StudyMode
    );
    setLogFormData(data?.data || []);
    setDataInputLoader(false);
  };

  const { setIdReviewRowTable, signFormProps } = UseSignature(
    "reviewStudyVisitsForm",
    connectUniqueIdData
  );

  const formAlreadyVerified = () => {
    let response = {
      statusCode: 400,
      customMessage: "Form Already Verified",
    };
    handleResponse(response);
  };

  const [formDataValue, setFormDataValue] = useState([])
  const [conditionData, setConditionData] = useState([]);
  let mergedOptionValueArray = Array.from(new Set([...conditionalData, ...optionValueData]));
  let newFormData = selectedFormList[0]?.form
  let formData = newFormData?.filter(item => item.type !== "heading")
  const getSingleConditionalLogic = async () => {
    const data = await clientLevelFormServices.getSingleConditionalLogic(
      "getSingleConditionalLogic", formUniqueId, formType
    );
    const conditionalLogicForm = formType !== "studyform" ? data?.data1 || [] : [];
    setConditionData(conditionalLogicForm);
    const conditionalLogicFormId = formType !== "studyform" ? data?.data2 || [] : [];
    const conditionalLogicFormIdData = conditionalLogicFormId?.map(item => item.conditionalData);
    if (conditionalLogicForm?.length > 0 && mergedOptionValueArray?.length > 0) {
      const conditionalData = conditionalLogicForm.filter(item =>
        mergedOptionValueArray.includes(item.optionId)
      );
      const optionUniqueId = formData.map(data => data.uniqueId);
      const formOptionUniqueId = optionUniqueId.filter(id =>
        !conditionalLogicFormIdData.includes(id)
      );
      const finalConditionalId = [...new Set(conditionalData.flatMap(data => data.conditionalId))];
      const finalConditionalData = conditionalLogicFormIdData.filter(id =>
        finalConditionalId.includes(id)
      );
      const filteredData = conditionalLogicFormId.filter(item =>
        finalConditionalId.includes(item.conditionalData)
      );
      const finalDataValue = filteredData
        .filter(item => mergedOptionValueArray.includes(item.optionId))
        .map(item => item.conditionalData);

      const mergedArray = [...finalConditionalData, ...formOptionUniqueId];
      const countOccurrences = (array, id) => array.filter(item => item === id).length;
      const filteredData2 = mergedArray.filter(id => {
        if (finalDataValue.includes(id)) {
          return countOccurrences(finalDataValue, id) === countOccurrences(mergedArray, id);
        }
        return true;
      });
      const finalFormDataValue = formData.filter(item =>
        filteredData2.includes(item.uniqueId)
      );

      setFormDataValue(finalFormDataValue);
    } else {
      const finalFormDataValue = formData.filter(item =>
        !conditionalLogicFormIdData.includes(item.uniqueId)
      );
      setFormDataValue(finalFormDataValue);
    }
  };

  useEffect(() => {
    const allConditionalData = conditionData?.flatMap(item =>
      item?.conditionalId?.map(id => ({
        uniqueId: id,
        optionUniqueId: item?.optionUniqueId,
        optionId: item?.optionId,
      })) || []
    ) || [];
    const filteredData = allConditionalData.filter(item =>
      optionValueData?.includes(item.optionId)
    );
    const allOptionDataSet = new Set(filteredData?.map(item => item.optionUniqueId));
    const finalResult = Array.from(allOptionDataSet).reduce((acc, element) => {
      const checkParentData = allConditionalData.find(item => item.uniqueId === element);
      if (checkParentData) {
        const checkChildData = filteredData?.filter(item => item.optionUniqueId === element);
        const checkChildOptionData = checkChildData?.map(item => item.optionId);
        acc.push({
          parentId: checkParentData.optionId,
          childId: checkChildOptionData,
        });
      }
      return acc;
    }, []);
    setOptionValueData(filterOptionData(finalResult, optionValueData))
  }, [formDataValue]);

  function filterOptionData(criteria, optionData) {
    criteria?.forEach(({ parentId, childId }) => {
      if (!optionData.includes(parentId)) {
        optionData = optionData?.filter(item => !childId.includes(item));
      }
    });
    return optionData;
  }

  useEffect(() => {
    if (formUniqueId) {
      getSingleConditionalLogic();
    }
  }, [newFormData]);

  useEffect(() => {
    if (formUniqueId) {
      getSingleConditionalLogic(formUniqueId);
    }
  }, [optionValueData]);

  useEffect(() => {
    getdataEntryFormStatus();
    getEntriedConditionalOptionId();
    getLogFormListForRegular();
  }, [dataEntryFormMode, StudyMode, formUniqueId]);

  return (
    <>
      <UpdateJsonData {...signFormProps()} />
      {breadcrumbData?.length > 0 && (
        <div className="border-bottom" style={{ height: 20 }}>
          <ul id="breadcrumb">
            {breadcrumbData?.map((item, index) => (
              <li key={index}>
                {breadcrumbData?.length === index + 1 ? (
                  <a>{item?.tabName}</a>
                ) : (
                  <a
                    onClick={() =>
                      handleAddNewTab(
                        item?.currentTabName,
                        "fa-solid fa-database",
                        item?.uniqueId,
                        item?.pageId,
                        item?.breadcrumb
                      )
                    }
                  >
                    {item?.tabName}
                  </a>
                )}
              </li>
            ))}
          </ul>
        </div>
      )}
      <div className="crfBuilder">
        <SplitPane
          split="vertical"
          style={{
            height:
              visitFormType === "log" ||
                formType === "specialFormType" ||
                formType === "visitForm" ||
                formType === "initiateVisitsForm"
                ? "calc(100vh - 176px)"
                : "calc(100vh - 157px)",
          }}
          className="position-relative"
          defaultSize={"20%"}
        >
          <SplitPane split="horizontal" minSize={20} defaultSize={`100%`}>
            {loader ? (
              <Loading />
            ) : selectedFormList?.[0]?.form?.length > 0 ? (
              <div className="d-flex w-100">
                {visitFormType === "log" && (
                  <div
                    className="border-end"
                    style={{
                      height: "calc(100vh - 187px)",
                      overflowX: "hidden",
                      width: 60,
                    }}
                  >
                    <div className={"nombering__point backgrundData"}>
                      {logFormData?.map((item, index) => (
                        <Dropdown as={ButtonGroup} className="w-100">
                          <botton
                            key={index}
                            onClick={() => {
                              setConnectUniqueIdData(item.connectUniqueId);
                              setSelectedForm("");
                            }}
                            className={`px-0 btn mb-2 ${connectUniqueIdData === item.connectUniqueId
                                ? "title__bar_value_active"
                                : "title__bar_value1"
                              }`}
                          >
                            <i
                              style={{ fontSize: 10, marginLeft: 5 }}
                              className={`fa-solid fa-circle ${item.status === "verified"
                                  ? "green"
                                  : item.status === "complete"
                                    ? "orange"
                                    : item.status === "query"
                                      ? "red"
                                      : "gray"
                                }`}
                            ></i>
                            {index + 1}
                          </botton>
                          {(StudyMode === "SDV Live Mode" ||
                            StudyMode === "Read Only Live Mode" ||
                            StudyMode === "SDV QC Mode" ||
                            (!(StudyMode === "DBP MODE") &&
                              (studyPermissionData?.siteIdForSDVLive?.length >
                                0 ||
                                studyPermissionData?.siteIdForSDVQC?.length >
                                0)) ? (
                            <>
                              <Dropdown.Toggle
                                split
                                className={`btn mb-2 title__bar_value`}
                                style={{ width: "10%" }}
                                id="dropdown-split-basic"
                              />
                              <Dropdown.Menu>
                                {StudyMode === "SDV Live Mode" ||
                                  StudyMode === "Read Only Live Mode" ||
                                  StudyMode === "SDV QC Mode" ||
                                  (!(StudyMode === "DBP MODE") &&
                                    (studyPermissionData?.siteIdForSDVLive
                                      ?.length > 0 ||
                                      studyPermissionData?.siteIdForSDVQC
                                        ?.length > 0)) ? (
                                  <Dropdown.Item
                                    onClick={() => {
                                      item.status === "verified"
                                        ? formAlreadyVerified()
                                        : setIdReviewRowTable(
                                          item.formUniqueId,
                                          studyId,
                                          dataEntryFormMode,
                                          item.studyUniqueId
                                        );
                                      setConnectUniqueIdData(
                                        item.connectUniqueId
                                      );
                                    }}
                                  >
                                    Signature
                                  </Dropdown.Item>
                                ) : (
                                  ""
                                )}
                              </Dropdown.Menu>
                            </>
                          ) : (
                            ""
                          ))}
                        </Dropdown>
                      ))}
                      {(StudyMode === "SDV Live Mode" ||
                        StudyMode === "Read Only Live Mode" ||
                        StudyMode === "SDV QC Mode" ? (
                        ""
                      ) : (
                        <div className="text-center">
                          <i
                            onClick={() => setConnectUniqueIdData("open")}
                            title="Add New Log Form Entry"
                            className="fa-solid fa-circle-plus gray"
                          ></i>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                <CrfViewLeft
                  {...crfViewLeftProps}
                  setDataEntryInput={setDataEntryInput}
                  ticketQuery={ticketQuery}
                  reviewedData={reviewedData}
                  optionValueData={optionValueData}
                  inputData={inputData}
                  dataEntry={dataEntry}
                  dataLoader={dataLoader}
                  finalDataEntryArray={finalDataEntryArray}
                  confirm={confirm}
                  addEntry={addEntry}
                  visitFormType={visitFormType}
                  cleanAllDataEntryFormField={cleanAllDataEntryFormField}
                  setInputFormDataEntry={setInputFormDataEntry}
                  StudyMode={StudyMode}
                  formUniqueId={formUniqueId}
                  studyUniqueId={studyUniqueId}
                  dataEntryMode={dataEntryFormMode}
                  conditionalData={conditionalData}
                  connectUniqueIdData={connectUniqueIdData}
                  formType={formType}
                  getSingleConditionalLogic={getSingleConditionalLogic}
                  formDataValue={formDataValue}
                  dayCheck={dayCheck}
                  monthCheck={monthCheck}
                  yearCheck={yearCheck}
                  hourCheck={hourCheck}
                  minuteCheck={minuteCheck}
                  secondCheck={secondCheck}
                  hasUnfreezed={hasUnfreezed}
                />
              </div>
            ) : (
              <NoDataFoundTable MSG="No Data Found" />
            )}
          </SplitPane>
          {selectedForm ? (
            <SplitPane
              split="horizontal"
              style={{ height: "calc(100vh - 187px)" }}
              className="position-relative"
              defaultSize={formType === "studyform" ? "100%" : "40%"}
            >
              <SplitPane split="vertical" minSize={20} defaultSize={"100%"}>
                {DataInputLoader ? (
                  <SpinerDataLoader />
                ) : (
                  <CrfViewRightTop
                    setInputFormDataEntry={setInputFormDataEntry}
                    studyVisitsFrom={studyVisitsFrom}
                    {...crfViewRightTopProps}
                    formUniqueId={formUniqueId}
                    studyUniqueId={studyUniqueId}
                    setSelectedForm={setSelectedForm}
                    dataEntryMode={dataEntryFormMode}
                    selectedFormId={selectedFormId}
                    fieldLockFreeze={fieldLockFreeze}
                    visitFormType={visitFormType}
                    connectUniqueIdData={connectUniqueIdData}
                    studyId={studyId}
                    dataEntryFormByIdEndPoint={dataEntryFormByIdEndPoint}
                    dataEntryLockFreezeFormByIdEndPoint={
                      dataEntryLockFreezeFormByIdEndPoint
                    }
                    dataEnteriesValueShow={dataEnteriesValueShow}
                    freezeLockdata={freezeLockdata}
                    setOptionValueData={setOptionValueData}
                    optionValueData={optionValueData}
                    storeDataEntry={storeDataEntry}
                    setNumberInput={setNumberInput}
                    numberInput={numberInput}
                    setTextInput={setTextInput}
                    textInput={textInput}
                    setDropdownId={setDropdownId}
                    setDropdownOption={setDropdownOption}
                    setCheckboxId={setCheckboxId}
                    setCheckboxValueData={setCheckboxValueData}
                    setRadioId={setRadioId}
                    setRadioOption={setRadioOption}
                    setTextareaInput={setTextareaInput}
                    textareaInput={textareaInput}
                    setScoreInput={setScoreInput}
                    scoreInput={scoreInput}
                    setDay={setDay}
                    day={day}
                    setMonth={setMonth}
                    month={month}
                    setYear={setYear}
                    year={year}
                    setHour={setHour}
                    hour={hour}
                    setMinute={setMinute}
                    minute={minute}
                    setSecond={setSecond}
                    second={second}
                    setFormat={setFormat}
                    format={format}
                    setTimeCalendar={setTimeCalendar}
                    timeCalendar={timeCalendar}
                    setDateCalendar={setDateCalendar}
                    dateCalendar={dateCalendar}
                    inputFormDataEntry={inputFormDataEntry}
                    formType={formType}
                    setScaleInput={setScaleInput}
                    scaleInput={scaleInput}
                    setUploadInput={setUploadInput}
                    uploadInput={uploadInput}
                    dayCheck={dayCheck}
                    setDayCheck={setDayCheck}
                    monthCheck={monthCheck}
                    setMonthCheck={setMonthCheck}
                    yearCheck={yearCheck}
                    setYearCheck={setYearCheck}
                    hourCheck={hourCheck}
                    setHourCheck={setHourCheck}
                    minuteCheck={minuteCheck}
                    setMinuteCheck={setMinuteCheck}
                    secondCheck={secondCheck}
                    setSecondCheck={setSecondCheck}
                    tableRef={tableRef}
                    onMouseDown={onMouseDown}
                  />
                )}
              </SplitPane>
              {formType === "studyform" ? (
                ""
              ) : (
                <>
                  <div className={`pt-1 bg-light border-bottom `}>
                    <div className="hstack justify-content-between">
                      <div className="hstack me-1 gap-1">
                        {[
                          "Freezing & Locking Management",
                          "review Management",
                          "Field History",
                          "Query Management",
                        ].map((tab) =>
                          !(
                            (StudyMode === "SDV Live Mode" ||
                              StudyMode === "Read Only Live Mode" ||
                              StudyMode === "SDV QC Mode" ||
                              StudyMode === "QC Mode" ||
                              StudyMode === "Live Mode") &&
                            (studyPermissionData?.dataCollectioninDVMode ||
                              studyPermissionData?.dataCollectionDBPMode ||
                              studyPermissionData?.siteIdForLive?.length > 0 ||
                              studyPermissionData?.siteIdForQC?.length > 0 ||
                              studyPermissionData?.siteIdForSDVLive?.length >
                              0 ||
                              studyPermissionData?.siteIdForSDVQC?.length >
                              0) &&
                            !(selectedForm?.review === "no")
                          ) && tab === "review Management" ? (
                            ""
                          ) : (
                            <button
                              key={tab}
                              className={`buttonForTabs ${activeTabs === tab ? "activee" : ""
                                }`}
                              onClick={() => setActiveTabs(tab)}
                              title={tab.charAt(0).toUpperCase() + tab.slice(1)}
                            >
                              {tab.charAt(0).toUpperCase() + tab.slice(1)}
                            </button>
                          )
                        )}
                      </div>
                      <div className="toolbar">
                        <i
                          className={`fa-solid me-2 ${!isFullScreen ? "fa-maximize" : "fa-minimize"
                            }`}
                          title={!isFullScreen ? "Full Mode" : "Original Mode"}
                          onClick={toggleFullScreen}
                        ></i>
                      </div>
                    </div>
                    {activeTabs === "review Management" ? (
                      <ReviewHistoryTable
                        {...historyTableProps}
                        visitFormType={visitFormType}
                        connectUniqueIdData={connectUniqueIdData}
                        formType={formType}
                      />
                    ) : activeTabs === "Freezing & Locking Management" ? (
                      <FreezingLockingManagement
                        studyPermissionData={studyPermissionData}
                        StudyMode={StudyMode}
                        freezeLock={freezeLock}
                        updateDatabaseFreezLock={updateDatabaseFreezLock}
                        visitFormType={visitFormType}
                        databaseLockFreezeHistory={databaseLockFreezeHistory}
                        loaderFreezeData={loaderFreezeData}
                        getdatabaseUnFreezeForDataEntry={
                          getdatabaseUnFreezeForDataEntry
                        }
                        selectedFormId={selectedFormId}
                      />
                    ) : activeTabs === "Query Management" ? (
                      <QueryTable
                        formType={formType}
                        getTicketsEndPoint={getTicketsEndPoint}
                        queryGeneratorEndPoint={queryGenerateEndpoint}
                        getQueryListEndPoint={getQueryListEndPoint}
                        formUniqueId={formUniqueId}
                        selectedFormId={selectedFormId}
                        selectedForm={selectedForm}
                        studyUniqueId={studyUniqueId}
                        dataEntryMode={dataEntryFormMode}
                        connectUniqueIdData={connectUniqueIdData}
                        queryReplyEndpoint={queryReplyEndpoint}
                        queryCommentEndpoint={queryCommentEndpoint}
                        updateTicketStatusEndPoint={updateTicketStatusEndPoint}
                        visitFormType={visitFormType}
                        getLogFormListForRegular={getLogFormListForRegular}
                        getdataEntryFormStatus={getdataEntryFormStatus}
                        tableRef={tableRef}
                        onMouseDown={onMouseDown}
                      />
                    ) : activeTabs === "Field History" ? (
                      <HistoryTable
                        FormFieldHistoryEndpoint={formFieldHistoryEndpoint}
                        formType={formType}
                        connectUniqueIdData={connectUniqueIdData}
                        formUniqueId={formUniqueId}
                        selectedFormId={selectedFormId}
                        dataEntryMode={dataEntryFormMode}
                        selectedForm={selectedForm}
                        studyUniqueId={studyUniqueId}
                        visitFormType={visitFormType}
                        tableRef={tableRef}
                        onMouseDown={onMouseDown}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </>
              )}
            </SplitPane>
          ) : (
            <IdDetails CrfView />
          )}
        </SplitPane>
      </div>

      <Offcanvas
        className={show ? "" : "d-none"}
        show={show}
        onHide={hideModalData}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Remark</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-0">
          <div
            className="p-3 overflow-auto"
            style={{ height: "calc(100vh - 89px)" }}
          >
            <Form.Control
              as="textarea"
              value={comment}
              className="h-25"
              onChange={(e) => setComment(e.target.value)}
              placeholder="Enter Remark Here..."
            />
          </div>
          <div className="p-2 bg-light shadow-sm hstack justify-content-end gap-2">
            <button
              onClick={hideModalData}
              className="border border-danger btn btn-outline-danger fontSize12   p-1 px-2"
            >
              Clear
            </button>
            <button
              disabled={!comment}
              onClick={(e) => databaseFreezeLockForDataEntry(e)}
              className="border fontSize12   p-1 px-2 updateProfile"
            >
              Unfreeze
            </button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default CrfView;
