import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import { Table } from "react-bootstrap";
import SplitPane from "react-split-pane";
import { useParams } from "react-router";
import UseToggle from "../../../../Hooks/UseToggle";
import Pagination from "../../../ETMF/Pagination/Pagination";
import RequestmgmtOffcanvasData from "./RequestmgmtOffcanvasData";
import AllRequestmgmtOffcanvasData from "./AllRequestmgmtOffcanvasData";
import RequestManagementFilterData from "./RequestManagementFilterData";
import { SpinerDataLoader } from "../../../NoDataFoundTable/NoDataFoundTable";
import { randomizerServices } from "../../../../Services/IWRS/randomizerServices";
import { studyAccessManagement } from "../../../../Services/studyAccessManagement";

const RequestManagement = ({ tableRef, onMouseDown }) => {
  let { studyId } = useParams()
  const [loader, setLoader] = useState(false);
  const [password, setPassword] = useState("");
  const [updateType, setUpdateType] = useState("");
  const [loaderAll, setLoaderAll] = useState(false);
  const [approvalType, setApprovalType] = useState("");
  const [updatedDetails, setUpdatedDetails] = useState("");
  const [allRequestList, setAllRequestList] = useState([]);
  const [approvalRemark, setApprovalRemark] = useState("");
  const [activeView, setActiveView] = useState("Individual");
  const [loaderIndivisual, setLoaderIndivisual] = useState(false);
  const [indivisualRequestList, setIndivisualrequestList] = useState([]);
  const { toggle, setToggle, toggle1, setToggle1, toggle2, setToggle2 } = UseToggle();
  const iwrsPermissionData = JSON.parse(
    localStorage.getItem("iwrsPermissionData")
  );
  let LiveSite = iwrsPermissionData?.siteIdForLive;
  let QCSite = iwrsPermissionData?.siteIdForQC;
  const handleAddNewLearning = () => {
    setUpdateType("add");
    setToggle();
  };
  let studyMode = JSON.parse(localStorage.getItem("StudyMode"));
  const getRequestUnblinedForApproval = async () => {
    setLoaderIndivisual(true)
    let siteData = await studyAccessManagement.studyRandomizerSiteListData(studyId, studyMode)
    let finalSiteData = siteData?.data || []
    let siteList
    if (studyMode === "Live Mode") {
      siteList = finalSiteData?.filter((item) => LiveSite?.includes(item.siteId)) || [];
    } else if (studyMode === "QC Mode") {
      siteList = finalSiteData?.filter((item) => QCSite?.includes(item.siteId)) || [];
    }
    let data = await randomizerServices.getRequestUnblinedForApproval(studyId, studyMode)
    let loadData = data?.data || []
    let finalData = loadData?.filter((item) => siteList?.map(data => data.siteIdLabel)?.includes(item.siteId));
    setIndivisualrequestList(finalData)
    setLoaderIndivisual(false)
  }

  const getAllRequestUnblinedForApproval = async () => {
    setLoaderAll(true)
    let data = await randomizerServices.getAllRequestUnblinedForApproval(studyId, studyMode)
    setAllRequestList(data?.data || [])
    setLoaderAll(false)
  }

  const approveRequestedUnblinedTreatment = async () => {
    setLoader(true)
    let userData = {}
    userData.studyMode = studyMode
    userData.studyId = studyId
    userData.requestType = updatedDetails?.requestType
    userData.uniqueId = updatedDetails?.uniqueId
    if (activeView === "Individual") {
      userData.randomizedId = updatedDetails?.randomizedId
      userData.subjectNumber = updatedDetails?.subjectNumber
      userData.siteId = updatedDetails?.siteId
      userData.block = updatedDetails?.block
    } else if (activeView === "All") {
      userData.randomizedId = updatedDetails?.randomizedId
    }
    userData.approvalType = approvalType
    userData.password = password
    userData.approvalRemark = approvalRemark
    await randomizerServices.approveRequestedUnblinedTreatment(userData)
    if (activeView === "Individual") {
      getRequestUnblinedForApproval()
    } else if (activeView === "All") {
      getAllRequestUnblinedForApproval()
    }
    setApprovalRemark("")
    setPassword("")
    setApprovalType("")
    setUpdatedDetails("")
    setLoader(false)
    if (activeView === "Individual") {
      setToggle1()
    } else if (activeView === "All") {
      setToggle2()
    }
  }

  const updatedData = (item, type) => {
    setUpdatedDetails(item)
    setApprovalType(type)
    if (activeView === "Individual") {
      setToggle1()
    } else if (activeView === "All") {
      setToggle2()
    }
  }

  useEffect(() => {
    if (activeView === "Individual") {
      getRequestUnblinedForApproval()
    } else if (activeView === "All") {
      getAllRequestUnblinedForApproval()
    }
  }, [activeView])

  const getStatusColor = (status) => {
    switch (status?.toLowerCase()) {
      case "completed":
        return "green";
      case "verified":
        return "green";
      case "pending":
        return "red";
      default:
        return "inherit";
    }
  };
  let approvalOneIWRS = iwrsPermissionData?.approvalOneIWRSQC || iwrsPermissionData?.approvalOneIWRSLive || studyMode === "DBP MODE"
  let approvalTwoIWRS = iwrsPermissionData?.approvalTwoIWRSQC || iwrsPermissionData?.approvalTwoIWRSLive || studyMode === "DBP MODE"
  let approvalThreeIWRS = iwrsPermissionData?.approvalThreeIWRSQC || iwrsPermissionData?.approvalThreeIWRSLive || studyMode === "DBP MODE"
  let approvalFourIWRS = iwrsPermissionData?.approvalFourIWRSQC || iwrsPermissionData?.approvalFourIWRSLive || studyMode === "DBP MODE"
  let approvalFiveIWRS = iwrsPermissionData?.approvalFiveIWRSQC || iwrsPermissionData?.approvalFiveIWRSLive || studyMode === "DBP MODE"

  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(15);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = indivisualRequestList?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(indivisualRequestList?.length / itemsPerPage);

  return (
    <>
      <SplitPane split="vertical" style={{ height: "calc(100vh - 153px)" }} className='position-relative' minSize={20} defaultSize="20%">
        <div class="w-100 mt-1" >
          <div className="mb-1 text-center" style={{ fontSize: 14, fontWeight: 500, color: "#103c5e" }}>
            Request type
          </div>
          <span
            className={`CP ps-3 small linkClass ${activeView === "Individual" ? "trueClass" : "falseClass"}`}
            onClick={() => setActiveView("Individual")}
          >
            <i className={` ${activeView === "Individual" ? "activeclient" : "inactiveclient"} mx-2`} aria-hidden="true"></i>
            Individual
          </span>
          <span
            className={`CP ps-3 small linkClass ${activeView === "All" ? "trueClass" : "falseClass"}`}
            onClick={() => setActiveView("All")}
          >
            <i className={` ${activeView === "All" ? "activeclient" : "inactiveclient"} mx-2`} aria-hidden="true"></i>
            All
          </span>
        </div>
        {activeView === "Individual" && (
          loaderIndivisual ? (
            <SpinerDataLoader />
          ) : (
            <div>
              <div className="p-1 hstack justify-content-between border-bottom">
                <div className="hstack gap-2 toolbar">
                  {updateType ? (
                    <i
                      onClick={() => setUpdateType("")}
                      title="Filter"
                      className="fa-solid fa-filter CP"

                    ></i>
                  ) : (
                    <i
                      onClick={handleAddNewLearning}
                      title="Add New Randomization"
                      className="fa-solid fa-filter CP"
                    />
                  )}
                </div>
              </div>
              <div className="m-0 p-0" style={{ fontSize: "13px", color: "#333" }}>
                <Table
                  id="resizable-table"
                  striped
                  hover
                  className="m-0 custom-table"
                  ref={tableRef}
                >
                  <thead className="position-sticky">
                    <tr>
                      <th onMouseDown={(e) => onMouseDown(e, 0)}>Name</th>
                      <th onMouseDown={(e) => onMouseDown(e, 1)}>Site Id</th>
                      <th onMouseDown={(e) => onMouseDown(e, 2)}>Block</th>
                      <th onMouseDown={(e) => onMouseDown(e, 3)}>Subject Number</th>
                      <th onMouseDown={(e) => onMouseDown(e, 4)}>Randomization Number</th>
                      <th onMouseDown={(e) => onMouseDown(e, 5)}>Status</th>
                      <th onMouseDown={(e) => onMouseDown(e, 6)}>Remak</th>
                      <th onMouseDown={(e) => onMouseDown(e, 7)}>Requested By</th>
                      <th onMouseDown={(e) => onMouseDown(e, 8)}>Requested Date & Time</th>
                      {result?.some(item => item.totalApproval.length > 0) &&
                        <th style={{ width: 160 }} onMouseDown={(e) => onMouseDown(e, 9)}>Approved</th>
                      }
                    </tr>
                  </thead>
                  <tbody>
                    {result?.map((item, index) => (
                      <tr key={index} >
                        <td>{item.name}</td>
                        <td>{item.siteId}</td>
                        <td>{item.block}</td>
                        <td>{item.subjectNumber}</td>
                        <td>{item.randomizationNumber}</td>
                        <td
                          className="text-capitalize"
                          style={{
                            color: getStatusColor(item?.totalApproval?.some(item => item.status === "pending") ? "pending" : item.status),
                            fontWeight: "500",
                          }}
                        >
                          {item?.totalApproval?.some(item => item.status === "pending") ? "pending" : "approved"}
                        </td>
                        <td>{item.requestRemark}</td>
                        <td>{item.requestedBy}</td>
                        <td><Moment format="DD MMM YYYY | HH:mm" withTitle>{item.createdAt}</Moment></td>
                        <td className="text-center">
                          <div style={{ display: "grid", gridTemplateColumns: "repeat(2, auto)", gap: "5px" }}>
                            {item?.totalApproval?.map((button, key) => (
                              ((approvalOneIWRS && key === 0) || (approvalTwoIWRS && key === 1) || (approvalThreeIWRS && key === 2) || (approvalFourIWRS && key === 3) || (approvalFiveIWRS && key === 4)) ?
                                <>
                                  {
                                    button?.status === "verified" ?
                                      <button
                                        key={key}
                                        onClick={(e) => updatedData(item, button?.type)}
                                        disabled
                                        className="text-white border-success bg-success border"
                                        style={{
                                          fontSize: 10,
                                          borderRadius: 3,
                                          padding: "2px 8px",
                                        }}
                                      >
                                        {button?.name}
                                      </button>
                                      : (item?.totalApproval?.filter(data => data?.status === "pending")?.[0]?.name) === button?.name ?
                                        <button
                                          key={key}
                                          onClick={(e) => updatedData(item, button?.type)}
                                          className="text-white border-success bg-success border"
                                          style={{
                                            fontSize: 10,
                                            borderRadius: 3,
                                            padding: "2px 8px",
                                          }}
                                        >
                                          {button?.name}
                                        </button>
                                        :
                                        <button
                                          key={key}
                                          className="text-white border-secondary bg-secondary border"
                                          style={{
                                            fontSize: 10,
                                            borderRadius: 3,
                                            padding: "2px 8px",
                                            cursor: "no-drop"
                                          }}
                                        >
                                          {button?.name}
                                        </button>
                                  }
                                </>
                                : ""
                            ))}
                          </div>
                        </td>

                      </tr>
                    ))}
                  </tbody>
                </Table>
                <div className="position-sticky bottom-0">
                  <Pagination
                    totalPage={totalPage}
                    pageNumber={pageNumber}
                    itemsPerPage={itemsPerPage}
                    totalItems={indivisualRequestList?.length}
                    setPageNumber={setPageNumber}
                    setItemsPerPage={setItemsPerPage}
                  />
                </div>
              </div>
            </div>
          ))}
        {activeView === "All" && (
          loaderAll ? (
            <SpinerDataLoader />
          ) : (
            <div>
              <div className="p-1 hstack justify-content-between border-bottom">
                <div className="hstack gap-2 toolbar">
                  {updateType ? (
                    <i
                      onClick={() => setUpdateType("")}
                      title="Table Full Mode"
                      className="fa-solid fa-filter CP"

                    ></i>
                  ) : (
                    <i
                      onClick={handleAddNewLearning}
                      title="Add New Randomization"
                      className="fa-solid fa-filter CP"
                    />
                  )}
                </div>
              </div>
              <div className="m-0 p-0" style={{ fontSize: "13px", color: "#333" }}>
                <Table
                  id="resizable-table"
                  striped
                  hover
                  className="m-0 custom-table"
                  ref={tableRef}>
                  <thead>
                    <tr>
                      <th onMouseDown={(e) => onMouseDown(e, 0)}>Name</th>
                      <th onMouseDown={(e) => onMouseDown(e, 1)}>Status</th>
                      <th onMouseDown={(e) => onMouseDown(e, 2)}>Remark</th>
                      <th onMouseDown={(e) => onMouseDown(e, 3)}>Requested By</th>
                      <th onMouseDown={(e) => onMouseDown(e, 4)}>Request Date | Time</th>
                      <th style={{ width: 160 }} onMouseDown={(e) => onMouseDown(e, 5)}>Approved</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allRequestList?.map((item, index) => (
                      <tr key={index}>
                        <td>{item.name}</td>
                        <td
                          className="text-capitalize"
                          style={{
                            color: getStatusColor(item?.totalApproval?.some(item => item.status === "pending") ? "pending" : item.status),
                            fontWeight: "500",
                          }}
                        >
                          {item?.totalApproval?.some(item => item.status === "pending") ? "pending" : item.status}
                        </td>
                        <td>{item.requestRemark}</td>
                        <td>{item.requestedBy}</td>
                        <td><Moment format="DD MMM YYYY | HH:mm" withTitle>{item.createdAt}</Moment></td>
                        <td className="text-center">
                          <div style={{ display: "grid", gridTemplateColumns: "repeat(2, auto)", gap: "5px" }}>
                            {item?.totalApproval?.map((button, key) => (
                              ((approvalOneIWRS && key === 0) || (approvalTwoIWRS && key === 1) || (approvalThreeIWRS && key === 2) || (approvalFourIWRS && key === 3) || (approvalFiveIWRS && key === 4)) ?
                                <>
                                  {
                                    button?.status === "verified" ?
                                      <button
                                        key={key}
                                        onClick={(e) => updatedData(item, button?.type)}
                                        disabled
                                        className="text-white border-success bg-success border"
                                        style={{
                                          fontSize: 10,
                                          borderRadius: 3,
                                          padding: "2px 8px",
                                        }}
                                      >
                                        {button?.name}
                                      </button>
                                      : (item?.totalApproval?.filter(data => data?.status === "pending")?.[0]?.name) === button?.name ?
                                        <button
                                          key={key}
                                          onClick={(e) => updatedData(item, button?.type)}
                                          className="text-white border-success bg-success border"
                                          style={{
                                            fontSize: 10,
                                            borderRadius: 3,
                                            padding: "2px 8px",
                                          }}
                                        >
                                          {button?.name}
                                        </button>
                                        :
                                        <button
                                          key={key}
                                          className="text-white border-secondary bg-secondary border"
                                          style={{
                                            fontSize: 10,
                                            borderRadius: 3,
                                            padding: "2px 8px",
                                            cursor: "no-drop"
                                          }}
                                        >
                                          {button?.name}
                                        </button>
                                  }
                                </>
                                : ""
                            ))}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          ))}
      </SplitPane>
      <RequestManagementFilterData
        Show={!toggle}
        Hide={setToggle}
      />
      <RequestmgmtOffcanvasData
        onMouseDown={onMouseDown}
        tableRef={tableRef}
        Show={!toggle1}
        Hide={setToggle1}
        loader={loader}
        approvalRemark={approvalRemark}
        setApprovalRemark={setApprovalRemark}
        password={password}
        setPassword={setPassword}
        getStatusColor={getStatusColor}
        updatedDetails={updatedDetails}
        approveRequestedUnblinedTreatment={approveRequestedUnblinedTreatment}
      />
      <AllRequestmgmtOffcanvasData
        onMouseDown={onMouseDown}
        tableRef={tableRef}
        Show={!toggle2}
        Hide={setToggle2}
        loader={loader}
        approvalRemark={approvalRemark}
        setApprovalRemark={setApprovalRemark}
        password={password}
        setPassword={setPassword}
        getStatusColor={getStatusColor}
        updatedDetails={updatedDetails}
        approveRequestedUnblinedTreatment={approveRequestedUnblinedTreatment}
      />
    </>
  );
};

export default RequestManagement;
