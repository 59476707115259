import React, { useEffect } from "react";
import { Form, Table } from "react-bootstrap";

const VitalSignsFormValues = ({
  vitalSign = [],
  item,
  handleVitalSignChange,
  vitalSignDataEntry,
  setVitalSignDataEntry,
  valueData,
  disabledData,
  tableRef,
  onMouseDown,
}) => {
  useEffect(() => {
    setVitalSignDataEntry(valueData || [])
  }, [valueData]);
  return (
    <div className="overflow-auto">
      <Table
        bordered
        ref={tableRef}
        className="custom-table tableLibrary border-start border-end mt-1"
      >
        <thead>
          <tr style={{ verticalAlign: "bottom" }}>
            <th
              onMouseDown={(e) => onMouseDown(e, 0)}
              className="position-sticky start-0 shadow"
              rowSpan={2}
            >
              <Form.Label className="m-0">
                Examination Test or Examination Name
              </Form.Label>
            </th>
            {item?.testCategory === "yes" && (
              <th rowSpan={2} onMouseDown={(e) => onMouseDown(e, 1)}>
                <Form.Label className="m-0">
                  Category for Vital Signs Test
                </Form.Label>
              </th>
            )}
            {item?.testSubCategory === "yes" && (
              <th onMouseDown={(e) => onMouseDown(e, 2)} rowSpan={2}>
                <Form.Label className="m-0">
                  Subcategory for Vital Signs Test
                </Form.Label>
              </th>
            )}
            <th
              className="text-center"
              onMouseDown={(e) => onMouseDown(e, item?.testCategory === "yes" ? 2 : item?.testSubCategory === "yes" ? 3 : 1)}
              rowSpan={2}
            >
              <Form.Label className="m-0">Result</Form.Label>
            </th>
            <th
              onMouseDown={(e) => onMouseDown(e, item?.testCategory === "yes" ? 3 : item?.testSubCategory === "yes" ? 4 : 2)}
              rowSpan={2}
              className="text-center"
            >
              <Form.Label className="m-0">Units</Form.Label>
            </th>
            <th
              onMouseDown={(e) => onMouseDown(e, item?.testCategory === "yes" ? 4 : item?.testSubCategory === "yes" ? 5 : 3)}
              colSpan={2}
              className="text-center"
            >
              <Form.Label className="m-0">Reference Range Indicator</Form.Label>
            </th>
            <th
              onMouseDown={(e) => onMouseDown(e, item?.testCategory === "yes" ? 5 : item?.testSubCategory === "yes" ? 6 : 4)}
              colSpan={2}
              className="text-center"
            >
              <Form.Label className="m-0">Clinical Significance</Form.Label>
            </th>
            <th
              onMouseDown={(e) => onMouseDown(e, item?.testCategory === "yes" ? 6 : item?.testSubCategory === "yes" ? 7 : 5)}
              rowSpan={2}
            >
              <Form.Label className="m-0">
                Remark &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </Form.Label>
            </th>
            <th rowSpan={2} onMouseDown={(e) => onMouseDown(e, item?.testCategory === "yes" ? 7 : item?.testSubCategory === "yes" ? 8 : 6)}></th>
          </tr>
          <tr>
            <td className="text-center" style={{ backgroundColor: "#b8cfdf", fontWeight: 500 }}>
              <Form.Label className="m-0">Normal</Form.Label>
            </td>
            <td className="text-center" style={{ backgroundColor: "#b8cfdf", fontWeight: 500 }}>
              <Form.Label className="m-0">Abnormal</Form.Label>
            </td>
            <td className="text-center" style={{ backgroundColor: "#b8cfdf", fontWeight: 500 }}>
              <Form.Label className="m-0">CS</Form.Label>
            </td>
            <td className="text-center" style={{ backgroundColor: "#b8cfdf", fontWeight: 500 }}>
              <Form.Label className="m-0">NCS</Form.Label>
            </td>
          </tr>
        </thead>
        <tbody style={{ whiteSpace: "nowrap" }}>
          {vitalSign?.map((row, key) => (
            <tr key={key}>
              <td className="position-sticky start-0 bg-white">
                <Form.Label className="m-0">{row.vitalSignName}</Form.Label>
              </td>
              {item?.testCategory === "yes" && (
                <td>
                  <Form.Label className="m-0">
                    {row.vitalSignCategoryName}
                  </Form.Label>
                </td>
              )}
              {item?.testSubCategory === "yes" && (
                <td>
                  <Form.Label className="m-0">
                    {row.vitalSignSubCategoryName}
                  </Form.Label>
                </td>
              )}
              <td
                className="p-0"
                style={{ background: (disabledData && !!valueData?.[key]?.VSORRES) && "#e9ecef" }}
              >
                <Form.Control
                  className="border-0 text-center w-100"
                  placeholder="Result"
                  style={{ minWidth: "max-content" }}
                  disabled={disabledData && !!valueData?.[key]?.VSORRES}
                  onChange={(e) =>
                    handleVitalSignChange(
                      "VSORRES",
                      e.target.value,
                      "VSORRESU",
                      valueData?.[key]?.VSORRES ? (vitalSignDataEntry?.[key]?.VSORRESU || valueData?.[key]?.VSORRESU) : (row?.options > 1 ? (vitalSignDataEntry?.[key]?.VSORRESU || valueData?.[key]?.VSORRESU) : (row?.options?.[0]?.value || "")),
                      "VSORNRIND",
                      vitalSignDataEntry?.[key]?.VSORNRIND || valueData?.[key]?.VSORNRIND,
                      "VSCLSIG",
                      vitalSignDataEntry?.[key]?.VSCLSIG || valueData?.[key]?.VSCLSIG,
                      "VSCOM",
                      vitalSignDataEntry?.[key]?.VSCOM || valueData?.[key]?.VSCOM,
                      key,
                      item,
                      valueData,
                      (disabledData && !!valueData?.[key]?.VSORRE)
                    )
                  }
                  value={disabledData ? valueData?.[key]?.VSORRES : vitalSignDataEntry?.[key]?.VSORRES}
                  // value={
                  //   vitalSignDataEntry?.[key]?.VSORRES ||
                  //   valueData?.[key]?.VSORRES
                  // }
                  type={row.vitalSignResultDataType || "text"}
                />
              </td>
              <td
                className="p-0 text-center"
                style={{ background: ((disabledData && !!valueData?.[key]?.VSORRESU) || (row?.options?.length <= 1)) && "#e9ecef" }}
              >
                {row?.options?.length > 1 ?
                  <Form.Select
                    className="border-0"
                    style={{ width: "max-content" }}
                    disabled={(disabledData && !!valueData?.[key]?.VSORRESU) || (row?.options?.length === 0)}
                    onChange={(e) =>
                      handleVitalSignChange(
                        "VSORRES",
                        vitalSignDataEntry?.[key]?.VSORRES || valueData?.[key]?.VSORRES,
                        "VSORRESU",
                        e.target.value,
                        "VSORNRIND",
                        vitalSignDataEntry?.[key]?.VSORNRIND || valueData?.[key]?.VSORNRIND,
                        "VSCLSIG",
                        vitalSignDataEntry?.[key]?.VSCLSIG || valueData?.[key]?.VSCLSIG,
                        "VSCOM",
                        vitalSignDataEntry?.[key]?.VSCOM || valueData?.[key]?.VSCOM,
                        key,
                        item,
                        valueData,
                        (disabledData && !!valueData?.[key]?.VSORRESU) || (row?.options?.length === 0)
                      )
                    }
                  >
                    <option>Select Unit</option>
                    {row?.options?.map((option, i) => (
                      <option
                        selected={
                          (vitalSignDataEntry?.[key]?.VSORRESU ||
                            valueData?.[key]?.VSORRESU) === option.value
                        }
                        key={i}
                        value={option.value}
                      >
                        {option.value}
                      </option>
                    ))}
                  </Form.Select>
                  :
                  <Form.Control
                    disabled
                    style={{ width: "max-content" }}
                    className="border-0 text-center"
                    placeholder={row?.options?.length === 0 ? "No Unit" : "Unit"}
                    onMouseOver={(e) =>
                      handleVitalSignChange(
                        "VSORRES",
                        vitalSignDataEntry?.[key]?.VSORRES || valueData?.[key]?.VSORRES,
                        "VSORRESU",
                        row?.options?.[0]?.value,
                        "VSORNRIND",
                        vitalSignDataEntry?.[key]?.VSORNRIND || valueData?.[key]?.VSORNRIND,
                        "VSCLSIG",
                        vitalSignDataEntry?.[key]?.VSCLSIG || valueData?.[key]?.VSCLSIG,
                        "VSCOM",
                        vitalSignDataEntry?.[key]?.VSCOM || valueData?.[key]?.VSCOM,
                        key,
                        item,
                        valueData,
                        (disabledData && !!valueData?.[key]?.VSORRESU) || (row?.options?.length === 0)
                      )
                    }
                    value={row?.options?.[0]?.value}
                    type={"text"}
                  />
                }
              </td>
              <td className="text-center">
                <Form.Check
                  type="radio"
                  name={`vitalsignnormalype_${key}`}
                  value="Normal"
                  disabled={disabledData && !!valueData?.[key]?.VSORNRIND}
                  onChange={(e) =>
                    handleVitalSignChange(
                      "VSORRES",
                      vitalSignDataEntry?.[key]?.VSORRES || valueData?.[key]?.VSORRES,
                      "VSORRESU",
                      vitalSignDataEntry?.[key]?.VSORRESU || valueData?.[key]?.VSORRESU,
                      "VSORNRIND",
                      e.target.value,
                      "VSCLSIG",
                      e.target.value === "Normal" ? "" : (vitalSignDataEntry?.[key]?.VSCLSIG || valueData?.[key]?.VSCLSIG),
                      "VSCOM",
                      vitalSignDataEntry?.[key]?.VSCOM || valueData?.[key]?.VSCOM,
                      key,
                      item,
                      valueData,
                      (disabledData && !!valueData?.[key]?.VSORNRIND)
                    )
                  }
                  checked={
                    (vitalSignDataEntry?.[key]?.VSORNRIND ||
                      valueData?.[key]?.VSORNRIND) === "Normal"
                  }
                />
              </td>
              <td className="text-center">
                <Form.Check
                  type="radio"
                  name={`vitalsignnormalype_${key}`}
                  value="Abnormal"
                  disabled={disabledData && !!valueData?.[key]?.VSORNRIND}
                  onChange={(e) =>
                    handleVitalSignChange(
                      "VSORRES",
                      vitalSignDataEntry?.[key]?.VSORRES || valueData?.[key]?.VSORRES,
                      "VSORRESU",
                      vitalSignDataEntry?.[key]?.VSORRESU || valueData?.[key]?.VSORRESU,
                      "VSORNRIND",
                      e.target.value,
                      "VSCLSIG",
                      vitalSignDataEntry?.[key]?.VSCLSIG || valueData?.[key]?.VSCLSIG,
                      "VSCOM",
                      vitalSignDataEntry?.[key]?.VSCOM || valueData?.[key]?.VSCOM,
                      key,
                      item,
                      valueData,
                      (disabledData && !!valueData?.[key]?.VSORNRIND)
                    )
                  }
                  checked={
                    (vitalSignDataEntry?.[key]?.VSORNRIND ||
                      valueData?.[key]?.VSORNRIND) === "Abnormal"
                  }
                />
              </td>
              <td className="text-center">
                <Form.Check
                  type="radio"
                  name={`vitalsigncstype_${key}`}
                  value={vitalSignDataEntry?.[key]?.VSORNRIND === "Normal" ? "" : "CS"}
                  disabled={(disabledData && !!valueData?.[key]?.VSCLSIG) || (vitalSignDataEntry?.[key]?.VSORNRIND ||
                    valueData?.[key]?.VSORNRIND) === "Normal"}
                  onChange={(e) =>
                    handleVitalSignChange(
                      "VSORRES",
                      vitalSignDataEntry?.[key]?.VSORRES || valueData?.[key]?.VSORRES,
                      "VSORRESU",
                      vitalSignDataEntry?.[key]?.VSORRESU || valueData?.[key]?.VSORRESU,
                      "VSORNRIND",
                      vitalSignDataEntry?.[key]?.VSORNRIND || valueData?.[key]?.VSORNRIND,
                      "VSCLSIG",
                      e.target.value,
                      "VSCOM",
                      vitalSignDataEntry?.[key]?.VSCOM || valueData?.[key]?.VSCOM,
                      key,
                      item,
                      valueData,
                      (disabledData && !!valueData?.[key]?.VSCLSIG) || (vitalSignDataEntry?.[key]?.VSORNRIND ||
                        valueData?.[key]?.VSORNRIND) === "Normal"
                    )
                  }
                  checked={
                    (vitalSignDataEntry?.[key]?.VSCLSIG === undefined ? valueData?.[key]?.VSCLSIG : vitalSignDataEntry?.[key]?.VSCLSIG) === "CS"
                  }
                />
              </td>
              <td className="text-center">
                <Form.Check
                  type="radio"
                  name={`vitalsigncstype_${key}`}
                  value={vitalSignDataEntry?.[key]?.VSORNRIND === "Normal" ? "" : "NCS"}
                  disabled={(disabledData && !!valueData?.[key]?.VSCLSIG) || (vitalSignDataEntry?.[key]?.VSORNRIND ||
                    valueData?.[key]?.VSORNRIND) === "Normal"}
                  onChange={(e) =>
                    handleVitalSignChange(
                      "VSORRES",
                      vitalSignDataEntry?.[key]?.VSORRES || vitalSign?.[key]?.VSORRES,
                      "VSORRESU",
                      vitalSignDataEntry?.[key]?.VSORRESU || vitalSign?.[key]?.VSORRESU,
                      "VSORNRIND",
                      vitalSignDataEntry?.[key]?.VSORNRIND || vitalSign?.[key]?.VSORNRIND,
                      "VSCLSIG",
                      e.target.value,
                      "VSCOM",
                      vitalSignDataEntry?.[key]?.VSCOM || vitalSign?.[key]?.VSCOM,
                      key,
                      item,
                      valueData,
                      (disabledData && !!valueData?.[key]?.VSCLSIG) || (vitalSignDataEntry?.[key]?.VSORNRIND ||
                        valueData?.[key]?.VSORNRIND) === "Normal"
                    )
                  }
                  checked={
                    (vitalSignDataEntry?.[key]?.VSCLSIG === undefined ? valueData?.[key]?.VSCLSIG : vitalSignDataEntry?.[key]?.VSCLSIG) === "NCS"
                  }
                />
              </td>
              <td
                className="p-0"
                style={{ background: (disabledData && !!valueData?.[key]?.VSCOM) && "#e9ecef" }}
              >
                <Form.Control
                  disabled={disabledData && !!valueData?.[key]?.VSCOM}
                  className="border-0 w-100 p-1"
                  placeholder="Remark"
                  style={{ resize: "none" }}
                  onChange={(e) =>
                    handleVitalSignChange(
                      "VSORRES",
                      vitalSignDataEntry?.[key]?.VSORRES || valueData?.[key]?.VSORRES,
                      "VSORRESU",
                      vitalSignDataEntry?.[key]?.VSORRESU || valueData?.[key]?.VSORRESU,
                      "VSORNRIND",
                      vitalSignDataEntry?.[key]?.VSORNRIND || valueData?.[key]?.VSORNRIND,
                      "VSCLSIG",
                      vitalSignDataEntry?.[key]?.VSCLSIG || valueData?.[key]?.VSCLSIG,
                      "VSCOM",
                      e.target.value,
                      key,
                      item,
                      valueData,
                      disabledData && !!valueData?.[key]?.VSCOM
                    )
                  }
                  value={
                    vitalSignDataEntry?.[key]?.VSCOM ||
                    valueData?.[key]?.VSCOM
                  }
                  as={"textarea"}
                />
              </td>
              <td></td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default VitalSignsFormValues;
